import { Form, Select } from 'antd';

import 'components/searchPanel/searchForm/inputs/index.css';

import { FC, useEffect } from 'react';

import searchActions from 'store/actions/searchActions';
import { useAppDispatch, useAppSelector } from 'store/selectors/useAppSelector';

const SectionsSelect: FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((s) => s.search.sections);
  const loading = useAppSelector((s) => s.search.sectionsLoading);
  const resourceForSearchPanel = useAppSelector(
    (s) => s.search.resourceForSearchPanel
  );

  useEffect(() => {
    if (data.length) return;
    dispatch(searchActions.sections.load());
  }, []);

  return (
    <Form.Item name='sections' className='search-form-field'>
      <Select
        loading={loading}
        maxTagCount={1}
        mode='multiple'
        allowClear
        placeholder={
          resourceForSearchPanel ? 'Раздел публикации' : 'Раздел патента'
        }
      >
        {data?.map((v) => (
          <Select.Option key={v.id} value={v.id}>
            {v.nameRu}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SectionsSelect;
