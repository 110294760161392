import { Col, Divider, Row } from 'antd';
import { FC } from 'react';

import CompanyComparisonInfo from 'components/expertsPageComponents/comparison/companyComparisonInfo';
import { IComparisonOfCompaniesData } from 'utils/interfaces/comparisonOfCompanies.interface';

const BasicInfo: FC<{ companies: IComparisonOfCompaniesData }> = ({
  companies,
}) => {
  return (
    <Row justify='center'>
      <Col span={11}>
        <CompanyComparisonInfo
          company={companies?.c1}
          otherCompany={companies?.c2}
        />
      </Col>
      <Col span={2}>
        <Divider className='full-height-divider' type='vertical' />
      </Col>
      <Col span={11}>
        <CompanyComparisonInfo
          company={companies?.c2}
          otherCompany={companies?.c1}
        />
      </Col>
    </Row>
  );
};

export default BasicInfo;
