import {
  ControlOutlined,
  ExperimentOutlined,
  FundOutlined,
  GlobalOutlined,
  RiseOutlined,
  SearchOutlined,
  SettingOutlined,
  StarOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { theme } from 'antd';

import Styles from 'components/icons/styles';

export const SearchMenuIcon = () => (
  <SearchOutlined style={Styles.menuIconStyle} />
);

export const LandscapeMenuIcon = () => (
  <GlobalOutlined style={Styles.menuIconStyle} />
);

export const AnalyticMenuIcon = () => (
  <FundOutlined style={Styles.menuIconStyle} />
);

export const SettingMenuIcon = () => (
  <SettingOutlined style={Styles.menuIconStyle} />
);

export const ExperimentMenuIcon = () => (
  <ExperimentOutlined style={Styles.menuIconStyle} />
);

export const StarMenuIcon = () => <StarOutlined style={Styles.menuIconStyle} />;

export const RiseMenuIcon = () => <RiseOutlined style={Styles.menuIconStyle} />;

export const ExpertsMenuIcon = () => (
  <TeamOutlined style={Styles.menuIconStyle} />
);

export const AdminMenuIcon = () => (
  <ControlOutlined style={Styles.menuIconStyle} />
);

export const MoonIcon = () => (
  <svg
    height='21'
    version='1.0'
    viewBox='50 50 1792 1792'
    width='21'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M1390 1303q-54 9-110 9-182 0-337-90t-245-245-90-337q0-192 104-357-201 60-328.5 229t-127.5 384q0 130 51 248.5t136.5 204 204 136.5 248.5 51q144 0 273.5-61.5t220.5-171.5zm203-85q-94 203-283.5 324.5t-413.5 121.5q-156 0-298-61t-245-164-164-245-61-298q0-153 57.5-292.5t156-241.5 235.5-164.5 290-68.5q44-2 61 39 18 41-15 72-86 78-131.5 181.5t-45.5 218.5q0 148 73 273t198 198 273 73q118 0 228-51 41-18 72 13 14 14 17.5 34t-4.5 38z' />
  </svg>
);

export const BookmarkOutlined = () => {
  const { token } = theme.useToken();
  return (
    <svg
      fill='none'
      height='20px'
      viewBox='0 0 25 25'
      width='20px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <path
          clipRule='evenodd'
          d='M18.507 19.853V6.034C18.5116 5.49905 18.3034 4.98422 17.9283 4.60277C17.5532 4.22131 17.042 4.00449 16.507 4H8.50705C7.9721 4.00449 7.46085 4.22131 7.08577 4.60277C6.7107 4.98422 6.50252 5.49905 6.50705 6.034V19.853C6.45951 20.252 6.65541 20.6407 7.00441 20.8399C7.35342 21.039 7.78773 21.0099 8.10705 20.766L11.907 17.485C12.2496 17.1758 12.7705 17.1758 13.113 17.485L16.9071 20.767C17.2265 21.0111 17.6611 21.0402 18.0102 20.8407C18.3593 20.6413 18.5551 20.2522 18.507 19.853Z'
          fillRule='evenodd'
          stroke={token.colorText}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        ></path>
      </g>
    </svg>
  );
};
