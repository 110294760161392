import { APP } from 'utils/constants/actionTypes';

const initialState = {
  notification: null,
};

const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case APP.SET_NOTIFICATION:
      return { ...state, notification: action.data };
    default:
      return state;
  }
};

export default appReducer;
