import {
  all,
  call,
  cancelled,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import externalNewsApi from 'services/externalNewsApi';
import patentsApi from 'services/patentsApi';
import publicationsApi from 'services/publicationsApi';
import appActions from 'store/actions/appActions';
import trendsAnalysisActions from 'store/actions/trendsAnalysisActions';
import { TRENDS_ANALYSIS } from 'utils/constants/actionTypes';
import { GetCancelTokenSource } from 'utils/helpers/api';

/**
 * Сброс всех данных
 * @description Worker
 * @param {*} action
 */
function* resetAll() {
  yield put(trendsAnalysisActions.companies.set());
  yield put(trendsAnalysisActions.countries.set());
  yield put(trendsAnalysisActions.patentsDirections.set());
  yield put(trendsAnalysisActions.publicationsDirections.set());
  yield put(trendsAnalysisActions.patents.set());
  yield put(trendsAnalysisActions.publications.set());
  yield put(trendsAnalysisActions.externalNews.set());
  yield put(trendsAnalysisActions.externalNewsByTopic.set());
  yield put(trendsAnalysisActions.externalNewsTopics.set());
}

/**
 * Загрузка данных
 * @description Worker
 * @param {*} action
 */
function* load(data) {
  const cancelSource = GetCancelTokenSource();
  const { actionType, filters, method } = data.payload || data;
  try {
    yield put(trendsAnalysisActions[actionType].startLoad());

    const response = yield call(method, filters, cancelSource.token);

    yield put(trendsAnalysisActions[actionType].set(response.data));
  } catch (e) {
    yield put(appActions.errorNotificationByApiExc(e));
  } finally {
    yield put(trendsAnalysisActions[actionType].finishLoad());

    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

/**
 * Загрузка всех данных
 * @description Worker
 * @param {*} action
 */
function* loadAll({ payload }) {
  yield put(trendsAnalysisActions.startLoadAll());
  yield all([
    call(load, {
      actionType: 'companies',
      filters: { ...payload.params },
      method: patentsApi.getChartByAssignees,
    }),
    call(load, {
      actionType: 'countries',
      filters: { ...payload.params },
      method: patentsApi.getChartByCountries,
    }),
    call(load, {
      actionType: 'patentsDirections',
      filters: { ...payload.params },
      method: patentsApi.getChartByClasses,
    }),
    call(load, {
      actionType: 'publicationsDirections',
      filters: { ...payload.params },
      method: publicationsApi.getChartByClasses,
    }),
    call(load, {
      actionType: 'patents',
      filters: { ...payload.params, ...payload.initialPagination },
      method: patentsApi.getFiltered,
    }),
    call(load, {
      actionType: 'publications',
      filters: { ...payload.params, ...payload.initialPagination },
      method: publicationsApi.getFiltered,
    }),
    call(load, {
      actionType: 'externalNews',
      filters: { ...payload.params, current: 1, pageSize: 4 },
      method: externalNewsApi.getFiltered,
    }),
    call(load, {
      actionType: 'externalNewsTopics',
      filters: { ...payload.params },
      method: externalNewsApi.getFilteredTopics,
    }),
  ]);
  yield put(trendsAnalysisActions.finishLoadAll());
}

/**
 * Мониторинг действий связанных с загрузкой данных анализа трендов
 * @description Watcher
 */
function* trendsAnalysisSaga() {
  yield takeEvery(TRENDS_ANALYSIS.LOAD, load);
  yield takeLatest(TRENDS_ANALYSIS.LOAD_ALL, loadAll);
  yield takeLatest(TRENDS_ANALYSIS.RESET_ALL, resetAll);
}

export default trendsAnalysisSaga;
