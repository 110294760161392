import { all, call, cancelled, put, takeLatest } from 'redux-saga/effects';

import analyticsActions from 'store/actions/analyticsActions';
import appActions from 'store/actions/appActions';
import popTrendsActions from 'store/actions/popTrendsActions';
import { POP_TRENDS } from 'utils/constants/actionTypes';
import { GetCancelTokenSource } from 'utils/helpers/api';
import { getPatentsPublicationsApi } from 'utils/helpers/commonHelperTS';

/**
 * Сброс всех данных
 * @description Worker
 * @param {*} action
 */
function* resetAll() {
  yield put(analyticsActions.patents.set());
  yield put(analyticsActions.publications.set());
  yield put(analyticsActions.dates.set());
  yield put(analyticsActions.directions.set());
  yield put(popTrendsActions.companiesByYear.set());
  yield put(popTrendsActions.ipcCloudTags.set());
}

/**
 * Загрузка всех данных
 * @description Worker
 * @param {*} action
 */
function* loadAll(action) {
  const { payload } = action;
  const resource = +payload.resource;
  const resourceName = resource ? 'Publications' : 'Patents';
  const api = getPatentsPublicationsApi(resource);

  yield all([
    call(
      loadAnalytics,
      resourceName.toLowerCase(),
      api[`getFiltered${resourceName}PopTrends`],
      payload
    ),
    call(
      loadAnalytics,
      'dates',
      api[`GetChart${resourceName}ByDtPopTrends`],
      payload
    ),
    call(
      loadAnalytics,
      'directions',
      api[`GetChart${resourceName}ByClassesPopTrends`],
      payload
    ),
    call(loadPopTrends, 'ipcCloudTags', api.getIpcCloudTagsPopTrends, payload),
    call(
      loadPopTrends,
      'companiesByYear',
      api.getTrendsCompaniesByYearPopTrends,
      payload
    ),
  ]);
}

/**
 * Загрузка данных
 * @description Worker
 * @param {String} actionType - Тип действия
 * @param {*} method  - Метод сервиса API
 * @param {*} data - Данные передаваемые в метод сервиса API
 */
function* loadAnalytics(actionType, method, data) {
  const cancelSource = GetCancelTokenSource();

  try {
    yield put(analyticsActions[actionType].startLoad());

    const response = yield call(method, data, cancelSource.token);

    yield put(analyticsActions[actionType].set(response.data));
  } catch (e) {
    yield put(appActions.errorNotificationByApiExc(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }

    yield put(analyticsActions[actionType].finishLoad());
  }
}

/**
 * Загрузка данных
 * @description Worker
 * @param {String} actionType - Тип действия
 * @param {*} method  - Метод сервиса API
 * @param {*} data - Данные передаваемые в метод сервиса API
 */
function* loadPopTrends(actionType, method, data) {
  const cancelSource = GetCancelTokenSource();

  try {
    yield put(popTrendsActions[actionType].startLoad());

    const response = yield call(method, data, cancelSource.token);

    yield put(popTrendsActions[actionType].set(response.data));
  } catch (e) {
    yield put(appActions.errorNotificationByApiExc(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }

    yield put(popTrendsActions[actionType].finishLoad());
  }
}

/**
 * Мониторинг действий связанных с загрузкой данных аналитики
 * @description Watcher
 */
function* popTrendsSaga() {
  yield takeLatest(POP_TRENDS.LOAD_ALL, loadAll);
  yield takeLatest(POP_TRENDS.RESET_ALL, resetAll);
}

export default popTrendsSaga;
