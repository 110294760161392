import { Badge, Space } from 'antd';
import { FC } from 'react';

interface ITitle {
  count?: number;
  text: string;
}

const ExpertRelationsTitle: FC<ITitle> = ({ count, text }) => (
  <Space>
    {text}{' '}
    <Badge
      count={count}
      overflowCount={1000}
      style={{ backgroundColor: '#0050b3', color: '#fff' }}
      showZero
    />
  </Space>
);

export default ExpertRelationsTitle;
