import { AxiosError } from 'axios';

import { APP } from 'utils/constants/actionTypes';
import { ApiErrorHandler } from 'utils/helpers/api';
import {
  getError,
  getInfo,
  getSuccess,
  getWarning,
} from 'utils/helpers/notificationHelper';
import { IErrorResponse } from 'utils/interfaces/errorResponse.interface';

const appActions = {
  errorNotification: (
    message: null | string = null,
    title: null | string = null
  ) => ({
    data: getError(message, title),
    type: APP.ERROR_NOTIFICATION,
  }),

  errorNotificationByApiExc: (
    exception: AxiosError<IErrorResponse> | { response: { status: number } },
    title: null | string = null
  ) => ({
    data: getError(ApiErrorHandler(exception), title),
    type: APP.ERROR_NOTIFICATION,
  }),

  infoNotification: (
    message: null | string = null,
    title: null | string = null
  ) => ({
    data: getInfo(message, title),
    type: APP.INFO_NOTIFICATION,
  }),

  setNotification: (notification: null | string = null) => ({
    data: notification,
    type: APP.SET_NOTIFICATION,
  }),

  successNotification: (
    message: null | string = null,
    title: null | string = null
  ) => ({
    data: getSuccess(message, title),
    type: APP.SUCCESS_NOTIFICATION,
  }),

  warningNotification: (
    message: null | string = null,
    title: null | string = null
  ) => ({
    data: getWarning(message, title),
    type: APP.WARNING_NOTIFICATION,
  }),
};

export default appActions;
