import { PRIORITY_DIRECTIONS } from 'utils/constants/actionTypes';

const priorityDirectionsActions = {
  finishLoad: () => ({
    payload: false,
    type: PRIORITY_DIRECTIONS.LOADING,
  }),
  load: () => ({
    type: PRIORITY_DIRECTIONS.LOAD_ALL,
  }),
  set: (data = []) => ({
    payload: data,
    type: PRIORITY_DIRECTIONS.SET_DATA,
  }),
  startLoad: () => ({
    payload: true,
    type: PRIORITY_DIRECTIONS.LOADING,
  }),
};

export default priorityDirectionsActions;
