import { call, put, takeLatest } from 'redux-saga/effects';

import ipcApi from 'services/ipcApi';
import appActions from 'store/actions/appActions';
import ipcActions from 'store/actions/ipcActions';
import { IPC } from 'utils/constants/actionTypes';

/**
 * Загрузка классов
 * @description Worker
 */
function* loadClasses() {
  try {
    yield put(ipcActions.classes.startLoad());

    const response = yield call(ipcApi.getIpcClasses);

    yield put(ipcActions.classes.set(response.data));
  } catch (e) {
    yield put(appActions.errorNotificationByApiExc(e));
  } finally {
    yield put(ipcActions.classes.finishLoad());
  }
}

/**
 * Мониторинг действий связанных с данными индексов МПК
 * @description Watcher
 */
function* ipcSaga() {
  yield takeLatest(IPC.LOAD_CLASSES, loadClasses);
}

export default ipcSaga;
