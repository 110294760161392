import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import RusLocale from 'antd/es/locale/ru_RU';
import 'dayjs/locale/ru';
import { Provider } from 'react-redux';

import { ErrorBoundary } from 'components/errorBoundary';
import validateMessages from 'components/forms/validateMessages';
import GlobalErrorPage from 'pages/GlobalErrorPage';
import BaseRouter from 'routers/baseRouter';
import store from 'store';
import 'styles/styles.scss';
import { darkTheme, lightTheme } from 'utils/constants/themeConfig';
import { useDarkTheme } from 'utils/hooks';

import * as am4core from '@amcharts/amcharts4/core';

const App = () => {
  const { isDarkTheme } = useDarkTheme();
  return (
    <ErrorBoundary fallback={<GlobalErrorPage />}>
      <Provider store={store}>
        <ConfigProvider
          form={{ validateMessages }}
          locale={RusLocale}
          theme={isDarkTheme ? darkTheme : lightTheme}
        >
          <div style={{ backgroundColor: isDarkTheme ? '#000' : '#fff' }}>
            <BaseRouter />
          </div>
        </ConfigProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;

am4core.options.autoDispose = true;
