import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Space, Typography } from 'antd';
import { FC } from 'react';

import SimpleTable from 'components/tables/simpleTable';
import {
  useDeleteFavoriteExpertMutation,
  useGetFavoriteExpertsQuery,
} from 'services/authorsRTKQApi';
import { getColumn } from 'utils/helpers/tableHelper';
import { useLogError } from 'utils/hooks';
import { IExpertFavorite } from 'utils/interfaces/expert.interface';

const ListOfFavoriteExperts: FC = () => {
  const logError = useLogError();
  const { data = [], isLoading } = useGetFavoriteExpertsQuery();
  const [deleteExpert, { isLoading: isDeleteLoading }] =
    useDeleteFavoriteExpertMutation();

  const del = async (id: number) => {
    try {
      await deleteExpert(id).unwrap();
    } catch (e) {
      logError(e);
    }
  };

  const columns = [
    { ...getColumn('ФИО', 'name') },
    {
      ...getColumn('Компетенции', 'competences'),
      render: (competences: string[]) => competences.join(', '),
    },
    {
      ...getColumn('Компании', 'companies'),
      render: (companies: string[]) => companies.join(', '),
    },
    {
      ...getColumn('Действия', 'operation', '90px', 'text', false, false),
      render: (_: any, record: IExpertFavorite) => (
        <Space align='center' direction='vertical' style={{ display: 'flex' }}>
          <Popconfirm
            okButtonProps={{ loading: isDeleteLoading }}
            onConfirm={() => record.id && del(record.id)}
            title='Удалить?'
          >
            <Typography.Link title='Удалить' type='danger'>
              <DeleteOutlined />
            </Typography.Link>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <SimpleTable
        loading={isLoading}
        showTotal={false}
        config={{
          columns,
          data,
          height: 300,
          key: (v: IExpertFavorite) => v.name,
          pagination: false,
        }}
      />
      <br />
      <Typography.Paragraph>
        Чтобы добавить эксперта в список “Избранных экспертов”, необходимо
        произвести поиск и в карточке эксперта нажать соответствующую кнопку.
      </Typography.Paragraph>
    </div>
  );
};

export default ListOfFavoriteExperts;
