import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { API, API_NO_CACHE } from 'utils/helpers/api';
import { getQueryByParams } from 'utils/helpers/commonHelper';

import type { IComparisonOfCompanies } from 'utils/interfaces/comparisonOfCompanies.interface';
import type { IPaginationResponse } from 'utils/interfaces/pagination.interface';
import type { IPatent } from 'utils/interfaces/patent.interface';

const SERVICE_NAME = 'patents';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

/**
 * Получение компаний, являющихся правообладателями патентов
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getAllPatentsAssignees = async (cancelToken: any = null) =>
  await API.get<string[]>(`${getUrlPart()}/all-assignees`, {
    cancelToken,
  });

/**
 * Получение авторов патентов
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getAllPatentsAuthors = async (cancelToken: any = null) =>
  await API.get<string[]>(`${getUrlPart()}/all-authors`, {
    cancelToken,
  });

/**
 * Получение классов, подклассов и групп индексов МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcClasses = (filters: any, cancelToken: any = null) =>
  API_NO_CACHE.get(`${getUrlPart()}/classes${getQueryByParams(filters)}`, {
    cancelToken,
  });

/**
 * Получение отфильтрованных патентов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getFiltered = (filters: any, cancelToken: any = null) =>
  API_NO_CACHE.get<IPaginationResponse<IPatent>>(
    `${getUrlPart()}${getQueryByParams(filters)}`,
    {
      cancelToken,
    }
  );

/**
 * Получение отфильтрованных патентов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getFilteredPatentsPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/pop-trends${getQueryByParams(filters)}`,
    {
      cancelToken,
    }
  );

/**
 * Получение деталей патента по его Id
 * @param {Number} id - идентификатор патента
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getById = async (id: number, cancelToken: any = null) =>
  await API.get(`${getUrlPart()}/${id}`, { cancelToken });

/**
 * Получение облака тегов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getCloudTags = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/cloud-tags${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по дате
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPatentsByDt = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/dates${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по дате
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPatentsByDtPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/dates/pop-trends${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по классу индекса МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByClasses = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/classes${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по классу индекса МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPatentsByClassesPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/classes/pop-trends${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по правообладателю
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByAssignees = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/assignees${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по автору
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPatentsByAuthors = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/authors${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по стране
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByCountries = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/countries${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика патентов по стране по году
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByCountryYears = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/landscape${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества патентов по разделам
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartBySections = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/patents-sections${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества патентов по индексам МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcCloudTags = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/ipc-cloud-tags${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества патентов по подгруппам индекса МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcCloudTagsPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/ipc-cloud-tags/pop-trends${getQueryByParams(
      filters
    )}`,
    { cancelToken }
  );

/**
 * Получение графика количества патентов по компаниям в периодике годов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getTrendsCompaniesByYear = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/companies-by-year${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества патентов по компаниям в периодике годов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getTrendsCompaniesByYearPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/companies-by-year/pop-trends${getQueryByParams(
      filters
    )}`,
    { cancelToken }
  );

/**
 * Получение график количества патентов авторов по годам
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartAuthorsByYear = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/authors-by-year${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение количества патентов определенной компании
 * @param {Object} filters - фильтры поиска
 * @description Используется для выполнения HTTP-запроса к API
 */
const getPatentsAmount = (filters: any) =>
  API_NO_CACHE.get<IPaginationResponse<number>>(
    `${getUrlPart()}${getQueryByParams(filters)}`
  );

const searchCompanies = async ({
  c1,
  c2,
}: IComparisonOfCompanies): Promise<
  Record<string, { patentsAmount: number }>
> => {
  const arr = await Promise.allSettled([
    getPatentsAmount({ Assignees: c1, Current: 1, PageSize: 1 }),
    getPatentsAmount({ Assignees: c2, Current: 1, PageSize: 1 }),
  ]);
  const patentsAmount1 =
    arr[0].status === 'fulfilled' ? arr[0].value.data.total : 0;
  const patentsAmount2 =
    arr[1].status === 'fulfilled' ? arr[1].value.data.total : 0;

  return {
    c1: { patentsAmount: patentsAmount1! },
    c2: { patentsAmount: patentsAmount2! },
  };
};

/**
 * Загрузка патентов
 */
const upload = (formData: FormData) =>
  API.post<any>(getUrlPart(), formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export default {
  getAllPatentsAssignees,
  getAllPatentsAuthors,
  getById,
  getChartAuthorsByYear,
  getChartByCountryYears,
  getChartBySections,
  getChartByAssignees,
  GetChartPatentsByAuthors,
  getChartByClasses,
  GetChartPatentsByClassesPopTrends,
  getChartByCountries,
  GetChartPatentsByDt,
  GetChartPatentsByDtPopTrends,
  getCloudTags,
  getFiltered,
  getFilteredPatentsPopTrends,
  getIpcClasses,
  getIpcCloudTags,
  getIpcCloudTagsPopTrends,
  getTrendsCompaniesByYear,
  getTrendsCompaniesByYearPopTrends,
  searchCompanies,
  upload,
};
