import { StarTwoTone } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import SimpleTable from 'components/tables/simpleTable';
import { useGetFavoriteExpertsQuery } from 'services/authorsRTKQApi';
import { getColumn } from 'utils/helpers/tableHelper';
import {
  IComparisonOfCompaniesData,
  IComparisonOfCompaniesTop,
} from 'utils/interfaces/comparisonOfCompanies.interface';
import { IExpert, IExpertCompetence } from 'utils/interfaces/expert.interface';

interface ITop30Authors {
  companies: IComparisonOfCompaniesData;
}

const Top30Authors: FC<ITop30Authors> = ({ companies }) => {
  const [top1, top2] = useMemo(() => {
    return [getSum(companies.c1.top), getSum(companies.c2.top)];
  }, [companies]);

  return (
    <Row>
      <Col span={11}>
        <Top30Table data={top1} />
      </Col>
      <Col span={2} />
      <Col span={11}>
        <Top30Table data={top2} />
      </Col>
    </Row>
  );
};

export default Top30Authors;

const getSum = (data: IComparisonOfCompaniesTop[]) => {
  return data.map((t) => ({ ...t, sum: t.competences.length }));
};

interface ITop30Experts extends Partial<IExpert> {
  sum: number;
}

interface ITop30Table {
  data: ITop30Experts[];
}

const Top30Table: FC<ITop30Table> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: favoriteExperts = [] } = useGetFavoriteExpertsQuery();

  const onSelect = useCallback((row: IExpert) => {
    if (!row?.name) return;
    navigate(`/experts/${row.name}`, {
      state: {
        prevPath: location.pathname,
      },
    });
  }, []);

  const COLUMNS = [
    {
      ...getColumn('ФИО', 'name'),
      render: (text: string) => {
        const isFavorite = favoriteExperts.find(
          (e) => e.name.toUpperCase() === text
        );

        return (
          <Space>
            {!!isFavorite && <StarTwoTone twoToneColor='#fadb14' />}
            <a>{text}</a>
          </Space>
        );
      },
    },
    {
      ...getColumn('Область экспертизы', 'competences'),
      render: (competences: IExpertCompetence[]) =>
        competences.map((c) => `${c.value} (${c.cnt})`).join(', '),
    },
    { ...getColumn('Кол-во уникальных компетенций', 'sum', '100px', 'num') },
  ];

  return (
    <>
      <SimpleTable
        onSelect={onSelect}
        showTotal={false}
        config={{
          columns: COLUMNS,
          data,
          height: 300,
          key: (v: any) => v.name,
          pagination: false,
        }}
      />
    </>
  );
};
