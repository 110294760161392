import { Button, Col, Form, Row, Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import { NonCaseSensitiveAutocomplete } from 'components/searchPanel/searchForm/inputs';
import searchActions from 'store/actions/searchActions';
import { useAppDispatch, useAppSelector } from 'store/selectors/useAppSelector';
import { IComparisonOfCompanies } from 'utils/interfaces/comparisonOfCompanies.interface';

const rules = [{ required: true }];

interface ISearchCompaniesForm {
  initialValues: IComparisonOfCompanies;
  loading: boolean;
  onSubmit(v: IComparisonOfCompanies): void;
}

const SearchCompaniesForm: FC<ISearchCompaniesForm> = ({
  initialValues,
  loading,
  onSubmit,
}) => {
  const [formRef] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useAppDispatch();
  const assigneesLoading = useAppSelector((s) => s.search.assigneesLoading);
  const assignees = useAppSelector((s) => s.search.assignees);
  const assigneesOptions = useMemo(() => {
    return assignees.map((value: string) => ({ value }));
  }, [assignees]);

  const onChange = (_: any, { c1, c2 }: IComparisonOfCompanies) => {
    setIsDisabled(() => !(c1 && c2 && c1 !== c2));
  };

  const onFinish = (values: IComparisonOfCompanies) => {
    if (!onSubmit || isDisabled) {
      return;
    }

    onSubmit(values);
  };

  useEffect(() => {
    if (initialValues.c1 && initialValues.c2) {
      formRef.setFieldsValue(initialValues);
      formRef.validateFields().then((values) => {
        setIsDisabled(false);
        onSubmit(values);
      });
    }

    if (!assignees.length) {
      dispatch(searchActions.patentAssignees.load());
    }
  }, []);

  return (
    <Form form={formRef} onFinish={onFinish} onValuesChange={onChange}>
      <Row justify='center'>
        <Col span={8}>
          <NonCaseSensitiveAutocomplete
            name='c1'
            loading={assigneesLoading}
            options={assigneesOptions}
            placeholder='Введите первую компанию...'
            rules={rules}
            allowClear
            autoFocus
          />
        </Col>
        <Col span={8}>
          <Space align='center' direction='vertical' style={{ width: '100%' }}>
            <Form.Item>
              <Button
                disabled={isDisabled}
                htmlType='submit'
                loading={loading || assigneesLoading}
                type='primary'
              >
                Сравнить
              </Button>
            </Form.Item>
          </Space>
        </Col>
        <Col span={8}>
          <NonCaseSensitiveAutocomplete
            name='c2'
            loading={assigneesLoading}
            options={assigneesOptions}
            placeholder='Введите вторую компанию...'
            rules={rules}
            allowClear
          />
        </Col>
      </Row>
    </Form>
  );
};

export default SearchCompaniesForm;
