import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';

const Loading = ({ height = '100%' }) => (
  <Row align='middle' justify='center' style={{ minHeight: height }}>
    <Col>
      <Spin indicator={LoadIcon} />
    </Col>
  </Row>
);

const LoadIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

export default Loading;

// Утсановка данного спинера глобально (Например, для спиннера в таблицах)
Spin.setDefaultIndicator(LoadIcon);
