import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { IPageInfo } from 'utils/interfaces/pageInfo.interface';

/**
 * Получение информации о текущей странице
 * @returns Информация о странице
 * @description Hook
 */
const useCurrentPageInfo = () => {
  const location = useLocation();

  return useMemo<IPageInfo>(
    () => {
      const pathnameLength = location.pathname.length;

      return (
        Object.values(PAGES_INFO).find((v) => {
          const pathLength = v.path.length;

          if (pathLength === pathnameLength)
            return v.path === location.pathname;

          return v.path !== '/' && location.pathname.includes(v.path);
        }) || { path: '', title: '', view: false }
      );
    },
    // eslint-disable-next-line
    [location.pathname]
  );
};

export default useCurrentPageInfo;
