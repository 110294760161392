import { Form, Select } from 'antd';
import { memo, useEffect } from 'react';

import 'components/searchPanel/searchForm/inputs/index.css';
import searchActions from 'store/actions/searchActions';
import { useAppDispatch, useAppSelector } from 'store/selectors/useAppSelector';

interface IProps {
  maxCount: number;
  name: string;
  placeholder: string;
}

const AuthorsSelect = ({ maxCount, name, placeholder }: IProps) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((s) => s.search.authors);
  const loading = useAppSelector((s) => s.search.authorsLoading);
  const resourceForSearchPanel = useAppSelector(
    (s) => s.search.resourceForSearchPanel
  );

  useEffect(() => {
    switch (resourceForSearchPanel) {
      case 0:
        dispatch(searchActions.patentAuthors.load());
        break;
      case 1:
        dispatch(searchActions.publicationAuthors.load());
        break;
    }
  }, [resourceForSearchPanel]);

  return (
    <Form.Item
      name={name}
      className='search-form-field'
      rules={[
        {
          max: maxCount,
          type: 'array',
        },
      ]}
    >
      <Select
        loading={loading}
        maxTagCount={1}
        mode='multiple'
        placeholder={placeholder}
        allowClear
      >
        {data?.map((v) => (
          <Select.Option key={v} value={v}>
            {v}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default memo(AuthorsSelect);
