import { Col, Row } from 'antd';
import { FC } from 'react';

import ExpertRelationsTitle from 'components/expertPageComponents/expertRelationsTitle';
import ListOfCompanies from 'components/expertsPageComponents/expertPage/listOfCompanies';
import ListOfCompetitions from 'components/expertsPageComponents/expertPage/listOfCompetitions';
import StatisticTag from 'components/expertsPageComponents/expertPage/statisticTag';
import { IExpertData } from 'utils/interfaces/expert.interface';

interface IProps {
  expertData: IExpertData;
}

const ExpertRelations: FC<IProps> = ({ expertData }) => {
  const { coauthors, companies, expert } = expertData;

  return (
    <Row gutter={[0, 14]} style={{ marginBottom: '32px' }}>
      <Col span={24}>
        {expert?.competences && (
          <ListOfCompetitions
            value={expert.competences}
            title={
              <ExpertRelationsTitle
                count={expert?.competences.length}
                text='Компетенции'
              />
            }
          />
        )}
      </Col>
      <Col span={24}>
        <StatisticTag
          value={expert?.keywords}
          title={
            <ExpertRelationsTitle
              count={expert?.keywords.length}
              text='Ключевые слова'
            />
          }
        />
      </Col>
      <Col span={24}>
        <ListOfCompetitions
          value={coauthors}
          title={
            <ExpertRelationsTitle count={coauthors?.length} text='Соавторы' />
          }
        />
      </Col>
      <Col span={24}>
        <ListOfCompanies
          value={companies}
          title={
            <ExpertRelationsTitle
              count={companies?.length}
              text='История упоминания'
            />
          }
        />
      </Col>
    </Row>
  );
};

export default ExpertRelations;
