import { Badge, Collapse, Space, Timeline } from 'antd';
import dayjs from 'dayjs';
import { FC, ReactNode, useMemo } from 'react';

import { IExpertCompany } from 'utils/interfaces/expert.interface';
import 'components/expertsPageComponents/expertPage/index.css';

const { Panel } = Collapse;

interface IExpertListOfCompanies {
  title: ReactNode;
  value?: IExpertCompany[];
}

const ExpertListOfCompanies: FC<IExpertListOfCompanies> = ({
  title,
  value = [],
}) => {
  const companiesByDate = useMemo(() => {
    const history: any = {};

    value.forEach((v) => {
      const date = dayjs(v.date).format('DD.MM.YYYY');
      const company = { name: v.company, value: v.value };

      if (history[date]) {
        history[date].push(company);
      } else {
        history[date] = [company];
      }
    });

    return history;
  }, [value]);

  return (
    <Collapse collapsible='header' accordion>
      <Panel header={title} key='1'>
        <Timeline className='timeline-height' mode='left'>
          {Object.keys(companiesByDate).map((date) => (
            <Timeline.Item key={date} label={date}>
              <Space direction='vertical'>
                {companiesByDate[date].map((c: any) => (
                  <div key={`${date}-${c.name}-${c.value}`}>
                    {c.name}&nbsp;
                    <Badge
                      count={c.value}
                      overflowCount={100}
                      style={{ backgroundColor: '#0050b3' }}
                    />
                  </div>
                ))}
              </Space>
            </Timeline.Item>
          ))}
        </Timeline>
      </Panel>
    </Collapse>
  );
};

export default ExpertListOfCompanies;
