import { Form, Input } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { FC, useMemo } from 'react';

import { KEYWORDS_PATTERN } from 'utils/constants/keywordsOperators';

const initialRules = [
  {
    message:
      'Ключевые слова должны быть в формате keyword1*keyword2 -exclusion1|keyword3*keyword4 -exclusion2',
    pattern: KEYWORDS_PATTERN,
  },
];

interface IKeywordsInput {
  name: string;
  placeholder: string;
  rules?: Rule[];
}

const KeywordsInput: FC<IKeywordsInput> = ({ name, placeholder, rules }) => {
  const fullRules = useMemo(
    () => (rules ? [...initialRules, ...rules] : initialRules),
    [rules]
  );

  return (
    <Form.Item name={name} className='search-form-field' rules={fullRules}>
      <Input placeholder={placeholder} size='large' allowClear autoFocus />
    </Form.Item>
  );
};

export default KeywordsInput;
