import { FilterOutlined } from '@ant-design/icons';
import { FloatButton, Layout, theme } from 'antd';
import { FC, ReactNode, useState } from 'react';

import styles from 'components/layouts/layoutWithTopMenu/index.module.scss';

interface Props {
  sider?: ReactNode;
}

const LayoutSider: FC<Props> = ({ sider }) => {
  const { token } = theme.useToken();

  const [active, setActive] = useState(true);

  const handleCollapse = (collapsed: boolean) => {
    setActive(!collapsed);
  };

  if (!sider) return null;
  return (
    <div
      style={{
        backgroundColor: token.colorBgLayout,
      }}
    >
      <Layout.Sider
        className={styles.sider}
        collapsedWidth={0}
        onCollapse={handleCollapse}
        width={315}
        collapsible
        style={{
          backgroundColor: token.colorBgElevated,
          paddingRight: !active ? '' : '4px',
        }}
        trigger={
          <FloatButton
            icon={<FilterOutlined style={{ color: active ? '#1668DC' : '' }} />}
            shape='circle'
            style={{
              boxShadow: 'none',
              left: 0,
              top: 0,
            }}
          />
        }
      >
        {sider}
      </Layout.Sider>
    </div>
  );
};

export default LayoutSider;
