import { lazy, Suspense } from 'react';
import { useLocation } from 'react-router';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from 'components/errorBoundary';
import Loading from 'components/loading';
import ExpertPage from 'pages/ExpertPage';
import { PAGES_INFO } from 'utils/constants/pagesConfig';

const TrendsAnalysisPage = lazy(() => import('pages/TrendsAnalysisPage'));

const SearchPage = lazy(() => import('pages/SearchPage'));
const LandscapesPage = lazy(() => import('pages/LandscapesPage'));
const AnalyticsPage = lazy(() => import('pages/AnalyticsPage'));
const ExpertsPage = lazy(() => import('pages/ExpertsPage'));
const ComparisonOfCompaniesPage = lazy(
  () => import('pages/ComparisonOfCompaniesPage')
);
const ChemicalCompositionPage = lazy(
  () => import('pages/ChemicalCompositionPage')
);
const AdminPage = lazy(() => import('pages/AdminPage'));
//const ManagementPage = null; //lazy(() => import("pages/ErrorPage"));
const ErrorPage = lazy(() => import('pages/ErrorPage'));

const getRoute = (path: string, element: JSX.Element) => ({ element, path });

export const routes = [
  getRoute(PAGES_INFO.searchPage.path, <SearchPage />),
  getRoute(PAGES_INFO.analyticsPage.path, <AnalyticsPage />),
  getRoute(PAGES_INFO.landscapesPage.path, <LandscapesPage />),
  getRoute(PAGES_INFO.expertsPage.path, <ExpertsPage />),
  getRoute(PAGES_INFO.expertPage.path, <ExpertPage />),
  getRoute(
    PAGES_INFO.comparisonOfCompaniesPage.path,
    <ComparisonOfCompaniesPage />
  ),
  getRoute(
    PAGES_INFO.chemicalCompositionPage.path,
    <ChemicalCompositionPage />
  ),
  getRoute(PAGES_INFO.adminPage.path, <AdminPage />),
  getRoute(PAGES_INFO.trendsAnalysisPage.path, <TrendsAnalysisPage />),
  getRoute(PAGES_INFO.forbiddenPage.path, <ErrorPage />),
  getRoute(PAGES_INFO.notFoundPage.path, <ErrorPage />),
  getRoute(PAGES_INFO.internalClientErrPage.path, <ErrorPage />),
  {
    element: <Navigate to={PAGES_INFO.notFoundPage.path} />,
    path: '*',
  },
];

export const BaseRoutes = ({
  routes,
}: {
  routes: { element: JSX.Element; path: string }[];
}) => {
  const location = useLocation();
  return (
    <ErrorBoundary
      fallback={<Navigate to={PAGES_INFO.internalClientErrPage.path} />}
      key={location.pathname}
    >
      <Routes>
        <Route element={<Navigate to={PAGES_INFO.searchPage.path} />} index />
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Routes>
    </ErrorBoundary>
  );
};

const BaseRouter = () => {
  return (
    <HashRouter>
      <Suspense fallback={<Loading height={'100vh'} />}>
        <BaseRoutes routes={routes} />
      </Suspense>
    </HashRouter>
  );
};

export default BaseRouter;
