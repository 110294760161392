import { Collapse, List, Typography } from 'antd';
import { FC, ReactNode } from 'react';

import { IExpertCompetence } from 'utils/interfaces/expert.interface';

const { Panel } = Collapse;

interface IExpertListOfCompetitions {
  title: ReactNode;
  value: IExpertCompetence[];
}

const ExpertListOfCompetitions: FC<IExpertListOfCompetitions> = ({
  title,
  value = [],
}) => (
  <Collapse collapsible='header' accordion>
    <Panel header={title} key='1'>
      <List
        dataSource={value}
        bordered
        renderItem={(item) => (
          <List.Item>
            <Typography.Text keyboard>{'[' + item.cnt + ']'}</Typography.Text>{' '}
            {item.value}
          </List.Item>
        )}
      />
    </Panel>
  </Collapse>
);

export default ExpertListOfCompetitions;
