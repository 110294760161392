import { Form, Radio } from 'antd';

const SearchTypeRadio = () => (
  <Form.Item name='searchType'>
    <Radio.Group className='search-form-field__radio-group' optionType='button'>
      <Radio value={2}>Все поля</Radio>
      <Radio value={0}>Заголовок</Radio>
      <Radio value={1}>Содержимое</Radio>
    </Radio.Group>
  </Form.Item>
);

export default SearchTypeRadio;
