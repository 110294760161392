import { Link } from 'components/links';
import { isEmpty } from 'utils/helpers/commonHelper';

const GoogleLink = ({ patentNumber }: { patentNumber: string }) => {
  if (isEmpty(patentNumber)) return <></>;

  return (
    <Link
      path={`https://patents.google.com/patent/${patentNumber}/en`}
      target='_blank'
    >
      {patentNumber}
    </Link>
  );
};

export default GoogleLink;
