import { Form, Input } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { FC, useMemo } from 'react';

const initialRules = [{ max: 320 }];

interface IAuthorsInput {
  name: string;
  rules?: Rule[];
}

const AuthorName: FC<IAuthorsInput> = ({ name, rules }) => {
  const fullRules = useMemo(
    () => (rules ? [...initialRules, ...rules] : initialRules),
    [rules]
  );

  return (
    <Form.Item name={name} className='search-form-field' rules={fullRules}>
      <Input placeholder='ФИО' allowClear />
    </Form.Item>
  );
};
export default AuthorName;
