import { Button, Empty, Space, Tabs, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import GoogleLink from 'components/googleLink';
import MarkKeywordsInText from 'components/markKeywordsInText';
import 'components/searchPageComponents/index.css';
import { useAppSelector } from 'store/selectors/useAppSelector';
import { codeToCountryList } from 'utils/constants/countries';
import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { getResourceFromUrl } from 'utils/helpers/commonHelper';
import { IPatent } from 'utils/interfaces/patent.interface';

interface IPatentDescription {
  data: IPatent;
  expertResource?: null | number;
  hasScroll?: boolean;
  keywords?: null | string[];
  viewLinks?: boolean;
}

const PatentDescription = ({
  data,
  expertResource = null,
  hasScroll,
  keywords = null,
  viewLinks = false,
}: IPatentDescription) => {
  const cloudTag = useAppSelector((s) => s.search.cloudTag);
  const resource = expertResource ?? getResourceFromUrl();
  const patent = useMemo(
    () => ({
      abstract: keywords ? (
        <MarkKeywordsInText
          cloudTag={cloudTag}
          keywords={keywords}
          text={data?.details?.abstract}
        />
      ) : (
        data?.details?.abstract
      ),
      assignees: <TagsList name='name' data={data?.assignees} />,
      authors: <TagsList name='name' data={data?.authors} />,
      claims: keywords ? (
        <MarkKeywordsInText
          cloudTag={cloudTag}
          keywords={keywords}
          text={data?.details?.claims}
        />
      ) : (
        data?.details?.claims || '-'
      ),
      country: codeToCountryList[data?.countryCode]?.name || '-',
      description: keywords ? (
        <MarkKeywordsInText
          cloudTag={cloudTag}
          keywords={keywords}
          text={data?.details?.description}
        />
      ) : (
        data?.details?.description
      ),
      dt: data?.date ? dayjs(data.date)?.format('DD.MM.YYYY') : '-',
      goalsResult: data?.details?.goalsResult || '-',
      ipc: <TagsList name='ipc' data={data?.ipcList} />,
      keywords: (
        <TagsList
          name='keyword'
          data={data?.keywords}
          tooltip={(v: any) => `Повторений: ${v.count}`}
        />
      ),
      publicNumber: data?.publicNumber || '-',
      title: keywords ? (
        <MarkKeywordsInText
          cloudTag={cloudTag}
          keywords={keywords}
          text={data?.title}
        />
      ) : (
        data?.title || '-'
      ),
    }),
    [data]
  );
  const items = [
    {
      children: (
        <PaneItemsWrapper hasScroll={hasScroll}>
          <BlockTitle title={resource ? 'Номер публикации' : 'Номер патента'} />
          {resource ? (
            <Typography.Paragraph>{patent.publicNumber}</Typography.Paragraph>
          ) : (
            <GoogleLink patentNumber={patent.publicNumber} />
          )}
          <BlockTitle title='Название' />
          <Typography.Paragraph>{patent.title}</Typography.Paragraph>
          <BlockTitle style={{ overflowWrap: 'anywhere' }} title='Аннотация' />
          <Typography.Paragraph>{patent.abstract}</Typography.Paragraph>
          <BlockTitle title='Дата' />
          <Typography.Paragraph>{patent.dt}</Typography.Paragraph>
          <BlockTitle title='Страна' />
          <Typography.Paragraph>{patent.country}</Typography.Paragraph>
          <BlockTitle title='Правообладатели' />
          <Typography.Paragraph>{patent.assignees}</Typography.Paragraph>
          <BlockTitle title='Авторы' />
          <Typography.Paragraph>{patent.authors}</Typography.Paragraph>
          {viewLinks && <Link2Analytics />}
        </PaneItemsWrapper>
      ),
      key: 'patent-description-common',
      label: 'Общее',
    },
    {
      children: (
        <PaneItemsWrapper hasScroll={hasScroll}>
          <Typography.Paragraph style={{ overflowWrap: 'anywhere' }}>
            {patent.description}
          </Typography.Paragraph>
          {viewLinks && <Link2Analytics />}
        </PaneItemsWrapper>
      ),
      key: 'patent-description-definition',
      label: 'Описание',
    },
    {
      children: (
        <PaneItemsWrapper hasScroll={hasScroll}>
          <BlockTitle title={resource ? 'Журнал' : 'Формула изобретения'} />
          <Typography.Paragraph style={{ overflowWrap: 'anywhere' }}>
            {patent.claims}
          </Typography.Paragraph>
          <BlockTitle title='За счет чего достигнута цель' />
          <Typography.Paragraph style={{ overflowWrap: 'anywhere' }}>
            {patent.goalsResult}
          </Typography.Paragraph>
          {viewLinks && <Link2Analytics />}
        </PaneItemsWrapper>
      ),
      key: 'patent-description-result',
      label: 'Результат',
    },
    {
      children: (
        <>
          <Typography.Paragraph>{patent.keywords}</Typography.Paragraph>
          {viewLinks && <Link2Analytics />}
        </>
      ),
      key: 'patent-description-keywords',
      label: 'Ключевые слова',
    },
    {
      children: (
        <>
          <Typography.Paragraph>{patent.ipc}</Typography.Paragraph>
          {viewLinks && <Link2Analytics />}
        </>
      ),
      key: 'patent-description-ipc',
      label: 'Индекс МПК',
    },
  ];

  return <Tabs defaultActiveKey='patent-description-common' items={items} />;
};

const Link2Analytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = () =>
    navigate({
      pathname: PAGES_INFO.analyticsPage.path,
      search: location.search,
    });

  return (
    <center>
      <Button
        className='analytic-link-btn'
        onClick={onClick}
        size='large'
        type='dashed'
      >
        Переход к анализу
      </Button>
    </center>
  );
};

interface ITagsList {
  data: any;
  name: string;
  tooltip?: any;
}

const TagsList: ({ data, name, tooltip }: ITagsList) => JSX.Element = ({
  data,
  name,
  tooltip,
}) => {
  if (!data?.length) return GetEmpty();

  return data.map((v: any) => (
    <Tooltip key={'tooltip-' + v[name]} title={tooltip && tooltip(v)}>
      <Tag className='tags-list-item' color='cyan' key={'tag-' + v[name]}>
        {v[name]}
      </Tag>
    </Tooltip>
  ));
};

const GetEmpty = () => (
  <Empty className='non-selectable' image={Empty.PRESENTED_IMAGE_SIMPLE} />
);

interface IPaneItemsWrapper {
  children: ReactNode;
  hasScroll?: boolean;
}

const PaneItemsWrapper = ({ children, hasScroll }: IPaneItemsWrapper) => (
  <Space
    className={hasScroll ? 'space-scroll' : ''}
    direction='vertical'
    style={{ width: '100%' }}
  >
    {children}
  </Space>
);

interface IBlockTitle {
  style?: any;
  title: string;
}

const BlockTitle = ({ style, title }: IBlockTitle) => (
  <Typography.Text
    className='text-upper non-selectable'
    style={style}
    type='secondary'
  >
    {title}
  </Typography.Text>
);

export default PatentDescription;
