import dayjs from 'dayjs';
import { FC } from 'react';

import SimpleTable from 'components/tables/simpleTable';
import { getColumn } from 'utils/helpers/tableHelper';
import { IExpertPatent } from 'utils/interfaces/expert.interface';

interface IExpertStatisticTable {
  data: IExpertPatent[];
  loading?: boolean;
  onSelect(v: IExpertPatent): void;
}

const ExpertStatisticTable: FC<IExpertStatisticTable> = ({
  data,
  loading,
  onSelect,
}) => {
  return (
    <SimpleTable
      loading={loading}
      onSelect={onSelect}
      showTotal={false}
      config={{
        columns: COLUMNS,
        data,
        height: 300,
        key: 'id',
        pagination: false,
      }}
    />
  );
};

export default ExpertStatisticTable;

const COLUMNS = [
  {
    ...getColumn('Номер', 'id', '100px', 'num', true, true),
  },
  {
    ...getColumn('Дата', 'date', '150px', 'dt', true, false),
    render: (date: Date) =>
      date ? <>{dayjs(date)?.format('DD.MM.YYYY')}</> : null,
  },
  {
    ...getColumn('Заголовок', 'title', '', 'text', true, true),
  },
];
