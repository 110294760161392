import { LeftOutlined } from '@ant-design/icons';
import { Button, Descriptions, Row, Statistic, Typography } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AddExpertToFavoriteButton from 'components/expertPageComponents/addExpertToFavoriteButton';
import ExpertPopconfirm from 'components/expertPageComponents/expertPopconfirm';
import ExpertPopover from 'components/expertPageComponents/expertsPopover';
import { codeToCountryList } from 'utils/constants/countries';
import { IExpertContact, IExpertData } from 'utils/interfaces/expert.interface';

interface IProps {
  expertContact: IExpertContact[];
  expertData: IExpertData;
}

const ExpertDescription: FC<IProps> = ({ expertContact, expertData }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { expert } = expertData;

  const navigateBack = () => {
    if (location.state?.prevPath) {
      navigate(-1);
    } else {
      navigate('/experts');
    }
  };

  return (
    <>
      <Row align='middle'>
        <Button onClick={navigateBack} type='text'>
          <LeftOutlined />
        </Button>
        <Statistic style={{ margin: '5px 10px' }} value={expert?.name} />
        {expert && <AddExpertToFavoriteButton expert={expert} />}
      </Row>
      <Row align='bottom'>
        <Typography.Text type='secondary'>
          {expert?.competences[0].value}
        </Typography.Text>
        <Typography.Text>
          {expertContact.length === 0 && <ExpertPopconfirm />}
          {expertContact.length > 0 && (
            <ExpertPopover expertContact={expertContact} />
          )}{' '}
        </Typography.Text>
      </Row>
      <Descriptions
        column={1}
        size='middle'
        bordered
        style={{
          fontSize: '40px',
          margin: '24px 0',
        }}
      >
        <Descriptions.Item label='Компании'>
          <Typography.Paragraph ellipsis={{ expandable: true, rows: 2 }}>
            {expert?.companies.join('; ')}
          </Typography.Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label='Страны' span={2}>
          <Typography.Paragraph ellipsis={{ expandable: true, rows: 2 }}>
            {expert?.countries
              .map((code) => codeToCountryList[code].name)
              .join('; ')}
          </Typography.Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label='Научная активность'>
          {(Number(expert?.patentsCount) || 0) +
            (Number(expert?.publicationsCount) || 0)}
        </Descriptions.Item>
        <Descriptions.Item label='Патенты'>
          {expert?.patentsCount}
        </Descriptions.Item>
        <Descriptions.Item label='Публикации'>
          {expert?.publicationsCount}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ExpertDescription;
