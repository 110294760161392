import { useEffect, useState } from 'react';

/**
 * Получение измененного значения без многократных повторений
 * @param value Значение
 * @param delay Интервал выполнения
 * @description Hook
 */
const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay || 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;

// Использование

// export const Component = () => {
// 	const [value, setValue] = useState<string>('')
// 	const debouncedValue = useDebounce<string>(value, 500)
//
// 	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
// 		setValue(event.target.value)
// 	}
//
// 	// Fetch API (optional)
// 	useEffect(() => {
// 		// Do fetch here...
// 		// Triggers when "debouncedValue" changes
// 	}, [debouncedValue])
//
// 	return (
// 		<div>
// 			<p>Value real-time: {value}</p>
// 			<p>Debounced value: {debouncedValue}</p>
// 			<input type="text" value={value} onChange={handleChange} />
// 		</div>
// 	)
// }
