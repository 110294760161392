import { IPC } from 'utils/constants/actionTypes';

const ipcActions = {
  classes: {
    finishLoad: () => ({
      payload: false,
      type: IPC.CLASSES_LOADING,
    }),
    load: () => ({
      type: IPC.LOAD_CLASSES,
    }),
    set: (data = []) => ({
      payload: data,
      type: IPC.CLASSES_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: IPC.CLASSES_LOADING,
    }),
  },
};

export default ipcActions;
