import { Switch } from 'antd';

import styles from 'components/switches/index.module.scss';
import { useDarkTheme } from 'utils/hooks';

const ThemeSwitch = () => {
  const { disable, enable, isDarkTheme } = useDarkTheme();

  const darkModeToggle = (checked: boolean) => {
    checked ? enable() : disable();
  };
  return (
    <Switch
      checked={isDarkTheme}
      className={styles.switch}
      onClick={darkModeToggle}
    />
  );
};

export default ThemeSwitch;
