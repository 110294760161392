import { CancelToken } from 'axios';

import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { API_NO_CACHE } from 'utils/helpers/api';

const SERVICE_NAME = 'priority-directions';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

/**
 * Получение списка приоритетных направлений
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getPriorityDirections = async (cancelToken?: CancelToken) =>
  await API_NO_CACHE.get(`${getUrlPart()}`, { cancelToken });

export default {
  getPriorityDirections,
};
