import { QuestionOutlined } from '@ant-design/icons';
import { FloatButton, Modal, Tooltip } from 'antd';

import HelpModalContent from 'components/floatButtons/helpFloatButton/helpModalContent';
import 'components/floatButtons/helpFloatButton/styles.scss';
import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { useBoolean } from 'utils/hooks';

import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';

const HelpButton = () => {
  const [isModalOpen, updateIsModalOpen] = useBoolean(false);
  const location = useLocation();

  const isAdminPage = matchPath(PAGES_INFO.adminPage.path, location.pathname);

  const isComparisonOfCompaniesPage = matchPath(
    PAGES_INFO.comparisonOfCompaniesPage.path,
    location.pathname
  );

  const isExpertPage = matchPath(PAGES_INFO.expertPage.path, location.pathname);

  return (
    <>
      {!isAdminPage && (
        <>
          <Tooltip placement='left' title='Центр помощи'>
            <FloatButton
              icon={<QuestionOutlined />}
              onClick={updateIsModalOpen.on}
              shape='circle'
              style={{
                boxShadow: 'none',
                position: 'absolute',
                left:
                  isComparisonOfCompaniesPage || isExpertPage ? undefined : 10,
                top:
                  isComparisonOfCompaniesPage || isExpertPage ? undefined : 100,
              }}
            />
          </Tooltip>
          <Modal
            closable={false}
            destroyOnClose={true}
            footer={null}
            onCancel={updateIsModalOpen.off}
            open={isModalOpen}
            style={{ marginTop: '-70px', maxWidth: '1250px' }}
            width='90%'
          >
            <HelpModalContent />
          </Modal>
        </>
      )}
    </>
  );
};

export default HelpButton;
