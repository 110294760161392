import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { API } from 'utils/helpers/api';
import { IIpcClass } from 'utils/interfaces/ipcClass.interface';

const SERVICE_NAME = 'ipc/';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

/**
 * Получение секций индексов МПК
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcSections = () => API.get(`${getUrlPart()}sections`);

/**
 * Получение классов индексов МПК
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcClasses = () => API.get<IIpcClass[]>(`${getUrlPart()}classes`);

export default {
  getIpcClasses,
  getIpcSections,
};
