import { Card, Col, Row, Statistic, theme, Typography } from 'antd';
import { FC } from 'react';

interface Props {
  competence: string;
  patentsAmount: number;
  publicationsAmount: number;
}
const CompanyActivity: FC<Props> = ({
  competence,
  patentsAmount,
  publicationsAmount,
}) => {
  const { token } = theme.useToken();

  return (
    <Row gutter={[16, 16]} wrap>
      <Col md={24} xl={10} xs={24}>
        <Card size='small' style={{ minHeight: '140px' }}>
          <div
            className='ant-statistic-title'
            style={{ color: token.colorTextDescription }}
          >
            Область деятельности:
          </div>
          <Typography.Paragraph
            className='ant-statistic-company-content'
            ellipsis={{ expandable: true, rows: 2 }}
            title={competence}
          >
            {competence}
          </Typography.Paragraph>
        </Card>
      </Col>
      <Col md={12} xl={7} xs={24}>
        <Card size='small' style={{ minHeight: '140px' }}>
          <Statistic title='Кол-во патентов:' value={patentsAmount} />
        </Card>
      </Col>
      <Col md={12} xl={7} xs={24}>
        <Card size='small' style={{ minHeight: '140px' }}>
          <Statistic title='Кол-во публикаций:' value={publicationsAmount} />
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyActivity;
