import { createRoot } from 'react-dom/client';

import App from 'App';
import security from 'services/security';
import { configure } from 'utils/helpers/api';

const root = createRoot(document.getElementById('root') as HTMLElement);
const renderApp = () => root.render(<App />);

security.callLogin(renderApp);
configure();
