import { AxiosResponse } from 'axios';

import { getUrlPart } from 'services/serviceConfigs';
import { API } from 'utils/helpers/api';
import { getQueryByParams } from 'utils/helpers/commonHelper';

const URL_PART = getUrlPart('search-queries');

interface ITotalParams {
  DtBeg: string;
  DtEnd: string;
}

export type ByUserParams = {
  Current: number;
  PageSize?: number;
  SortColumn?: string;
  SortDirection?: 0 | 1;
};

type ByUserResultsDTO = {
  date: string;
  keywords: string;
  resource: string[];
  tag: string[];
};

export type ByUserDTO = {
  current: number;
  pageSize: number;
  results: ByUserResultsDTO[];
  total: number;
  totalPages: number;
};

export const searchQueriesApi = {
  byUser: (params: ByUserParams): Promise<AxiosResponse<ByUserDTO>> => {
    return API.get(`${URL_PART}/by-user/`, {
      params,
    });
  },

  /**
   * Получить количество запросов к системе
   * @param {*} params - фильтры поиска
   * @description Используется для выполнения HTTP-запроса к API
   */
  total: (params: ITotalParams) => {
    return API.get<number>(`${URL_PART}/total/${getQueryByParams(params)}`);
  },
};
