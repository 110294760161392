import { Card, List } from 'antd';

const ListTools = () => (
  <List
    dataSource={dataTools}
    grid={{
      gutter: 16,
      lg: 4,
      md: 4,
      sm: 2,
      xl: 6,
      xs: 1,
      xxl: 3,
    }}
    renderItem={(item) => (
      <List.Item>
        <Card
          hoverable={true}
          title={<span className='mark'>{item.title}</span>}
          extra={
            <a href={item.href} style={{ fontSize: '12px' }}>
              Перейти
            </a>
          }
        >
          {item.content}
        </Card>
      </List.Item>
    )}
  />
);

const dataTools = [
  {
    content:
      'Система поиска, которая на основе семантического облака слов позволяет пройтись по классификатору предметной области и быстро сформировать выборку документов.',
    href: '/#/search',
    title: 'Поисковая система',
  },
  {
    content:
      'Система анализа поискового запроса в виде наглядного BI-отчета по научно-техническим документам, топ-авторам, топ-компаниям и т.д.',
    href: '/#/analytics',
    title: 'Платформа анализа',
  },
  {
    content:
      'Данная система направлена на изучение информации по поисковому запросу в виде интерактивной карты мира с возможностью выбора элементов и их анализа.',
    href: '/#/landscapes',
    title: 'Ландшафты',
  },
  {
    content:
      'Данная система создана для поиска информации по Экспертам в рамках узкоспециализированных компетенций. К примеру, поиск экспертов по <сварке>, <травлению>, <самолетостроению> и т.д.',
    href: '/#/experts',
    title: 'Поиск Экспертов',
  },
  {
    content:
      'Уникальная система, позволяющая искать информации по патентам в формате поиска по химическим элементам. Вам необходимо выбрать химический элемент и диапазон min, max и система найдет все соотвествия химическим элементов среди патентов со всего мира!',
    href: '/#/chemical-composition',
    title: 'Химический состав',
  },
];

export default ListTools;
