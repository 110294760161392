import { Button, Collapse, Space, Tag } from 'antd';
import { FC, ReactNode, useState } from 'react';

import 'components/expertsPageComponents/expertPage/index.css';
import { IExpertKeyword } from 'utils/interfaces/expert.interface';

const { Panel } = Collapse;

interface IExpertStatisticTag {
  title: ReactNode;
  value?: IExpertKeyword[];
}

const initialTagsAmount = 50;

const ExpertStatisticTag: FC<IExpertStatisticTag> = ({ title, value = [] }) => {
  const [tagsAmount, setTagsAmount] = useState<number | undefined>(
    initialTagsAmount
  );

  const handleTagsAmount = () => {
    if (tagsAmount) {
      setTagsAmount(undefined);
    } else {
      setTagsAmount(initialTagsAmount);
    }
  };

  return (
    <Collapse collapsible='header' accordion>
      <Panel header={title} key='1'>
        <Space size={1} wrap>
          {value.slice(0, tagsAmount).map((v: IExpertKeyword) => {
            const color = getColorByCount(v.cnt);

            return (
              <Tag color={color} key={v.value}>
                {`${v.value} (${v.cnt})`}
              </Tag>
            );
          })}
          <Button
            className='experts-page-more-tags-button'
            onClick={handleTagsAmount}
            type='link'
          >
            {tagsAmount ? 'Показать все' : 'Показать меньше'}
          </Button>
        </Space>
      </Panel>
    </Collapse>
  );
};

const getColorByCount = (count: number) => {
  if (count >= 1 && count < 33) {
    return '#006d75';
  } else if (count >= 33 && count < 66) {
    return '#00474f';
  } else if (count >= 66) {
    return '#002329';
  }
  return '#08979c';
};

export default ExpertStatisticTag;
