import { CancelToken } from 'axios';

import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { API } from 'utils/helpers/api';
import {
  IAddNotification,
  IEditNotification,
} from 'utils/interfaces/searchNotifications.interface';

const SERVICE_NAME = 'Notifications';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

/**
 * Получение уведомлений
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getAllNotifications = async (cancelToken?: CancelToken) =>
  await API.get(getUrlPart(), { cancelToken });

const addNotification = async (data: IAddNotification) =>
  await API.post(getUrlPart(), data);
const editNotification = async (data: IEditNotification) =>
  await API.put(getUrlPart(), data);
const deleteNotification = async (id: number) =>
  await API.delete(`${getUrlPart()}/${id}`);

export default {
  addNotification,
  deleteNotification,
  editNotification,
  getAllNotifications,
};
