import { TablePaginationConfig } from 'antd';

import { POP_TRENDS } from 'utils/constants/actionTypes';
import { IPopTrendsSearchPanel } from 'utils/interfaces/popTrendsSearchPanel.interface';

const popTrendsActions = {
  companiesByYear: {
    finishLoad: () => ({
      payload: false,
      type: POP_TRENDS.COMP_BY_YEAR_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: POP_TRENDS.COMP_BY_YEAR_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: POP_TRENDS.COMP_BY_YEAR_LOADING,
    }),
  },
  ipcCloudTags: {
    finishLoad: () => ({
      payload: false,
      type: POP_TRENDS.IPC_CLOUD_TAGS_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: POP_TRENDS.IPC_CLOUD_TAGS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: POP_TRENDS.IPC_CLOUD_TAGS_LOADING,
    }),
  },
  loadData: (
    search: IPopTrendsSearchPanel | null,
    pagination: TablePaginationConfig
  ) => ({
    payload: { ...search, ...pagination },
    type: POP_TRENDS.LOAD_ALL,
  }),
  reset: () => ({
    type: POP_TRENDS.RESET_ALL,
  }),
};

export default popTrendsActions;
