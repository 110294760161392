// TODO: Перенести весь commonHelper.js в commonHelperTS.ts. После переноса переименовать в commonHelper.ts
import dayjs from 'dayjs';

import patentsApi from 'services/patentsApi';
import publicationsApi from 'services/publicationsApi';
import { PUBLICATIONS_RESOURCE } from 'utils/constants/appConfig';

export const getPatentsPublicationsApi = (resource?: number) => {
  return resource === PUBLICATIONS_RESOURCE ? publicationsApi : patentsApi;
};

export const getPatentsPublicationsType = (resource?: number) => {
  return resource === PUBLICATIONS_RESOURCE ? 'publications' : 'patents';
};

export const getFormattedDate = (date: Date, separator: string = '/') => {
  return dayjs(date).format(`DD${separator}MM${separator}YYYY`);
};

export const trimWhitespaces = (str: string = '') =>
  str.replace(/\s+/g, ' ').trim();
