import { useMemo } from 'react';

import useWindowSize from 'utils/hooks/useWindowSize';

/**
 * Хук, возвращаюший рассчитанное значение высоты таблицы
 * @param {Number} extraHeight - Лишняя высота, которая вычитается из высота окна браузера
 * @description Hook
 */
const useTableHeight = (extraHeight: number) => {
  const { height } = useWindowSize();

  return useMemo(() => {
    if (!height) return undefined; // Если не определили высоту окна браузера

    const r = height - extraHeight;

    if (r < 150) return 350; // Если высота меньше 150px, то возвращаем 350

    return r;
  }, [height, extraHeight]);
};

export default useTableHeight;
