export const basic_operators = {
  and: 'AND',
  or: 'OR',
  not: 'NOT',
};

export const KEYWORDS_PATTERN =
  /^\s*([\wа-яё]+(?:-[\wа-яё]+)*(?:\*?)?)(?: ([\wа-яё]+(?:-[\wа-яё]+)*(?:\*?)?)?)*$/gi;

export const MULTIPLE_OPERATORS_IN_A_ROW_RESTRICTION =
  /^(?!.*\b(?:AND|OR|NOT|[1-9]W)\s*(?:AND|OR|NOT|[1-9]W)\b).*$/g;

export const HIGHLIGHT_OPERATORS = /(\b(AND|OR|NOT|[1-9]W)\b|\*)/g;
