import { ANALYTICS } from 'utils/constants/actionTypes';

const analyticsActions = {
  authors: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.AUTHORS_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: ANALYTICS.AUTHORS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.AUTHORS_LOADING,
    }),
  },
  authorsByYear: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.AUTHORS_BY_YEAR_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: ANALYTICS.AUTHORS_BY_YEAR_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.AUTHORS_BY_YEAR_LOADING,
    }),
  },
  companies: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.COMPANIES_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: ANALYTICS.COMPANIES_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.COMPANIES_LOADING,
    }),
  },
  companiesByYear: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.COMPANIES_BY_YEAR_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: ANALYTICS.COMPANIES_BY_YEAR_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.COMPANIES_BY_YEAR_LOADING,
    }),
  },
  countries: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.COUNTRIES_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: ANALYTICS.COUNTRIES_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.COUNTRIES_LOADING,
    }),
  },
  dates: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.DATES_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: ANALYTICS.DATES_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.DATES_LOADING,
    }),
  },
  directions: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.DIRECTIONS_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: ANALYTICS.DIRECTIONS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.DIRECTIONS_LOADING,
    }),
  },
  loadData: (search, pagination) => ({
    payload: { ...search, ...pagination },
    type: ANALYTICS.LOAD_ALL,
  }),
  patents: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.PATENTS_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: ANALYTICS.PATENTS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.PATENTS_LOADING,
    }),
  },
  publications: {
    finishLoad: () => ({
      payload: false,
      type: ANALYTICS.PUBLICATIONS_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: ANALYTICS.PUBLICATIONS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: ANALYTICS.PUBLICATIONS_LOADING,
    }),
  },
  reset: () => ({
    type: ANALYTICS.RESET_ALL,
  }),
};

export default analyticsActions;
