import { useLayoutEffect, useRef } from 'react';

/**
 * Эффективная работа с отслеживанием изменения размеров определенного объекта
 * @param callback колбэк для выполнение на изменение размеров объекта
 * @description Hook
 */
const useResizeObserver = <T extends HTMLElement>(
  callback: (target: T, entry: ResizeObserverEntry) => void
) => {
  const ref = useRef<null | T>(null);
  useLayoutEffect(() => {
    const element = ref?.current;
    if (!element) {
      return;
    }
    const observer = new ResizeObserver((entries) => {
      callback(element, entries[0]);
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);

  return ref;
};

export default useResizeObserver;

// Исползование

// export const Component = () => {
//     const onResize = useCallback((target: HTMLDivElement, entry: ResizeObserverEntry) => {
//         if (entry.contentRect.width < 850) {
//         // do something
//         }
//     }, []);
//
//     const chartContainerRef = useResizeObserver(onResize);
//
//     return <div ref={chartContainerRef}></div>
// }
