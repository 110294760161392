import { put, takeEvery } from 'redux-saga/effects';

import appActions from 'store/actions/appActions';
import { APP } from 'utils/constants/actionTypes';

/**
 * Вставка уведомления
 * @description Worker
 * @param {*} action
 */
export function* setNotification(action) {
  try {
    const { data } = action;
    yield put(appActions.setNotification(data));
  } catch (e) {
    console.log(e);
  }
}

/**
 * Мониторинг действий связанных с логикой приложения
 * @description Watcher
 */
function* appSaga() {
  yield takeEvery(APP.SUCCESS_NOTIFICATION, setNotification);
  yield takeEvery(APP.INFO_NOTIFICATION, setNotification);
  yield takeEvery(APP.WARNING_NOTIFICATION, setNotification);
  yield takeEvery(APP.ERROR_NOTIFICATION, setNotification);
}

export default appSaga;
