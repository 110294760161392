import { Layout } from 'antd';
import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';

import { HelpButton } from 'components/floatButtons/helpFloatButton';
import styles from 'components/layouts/layoutWithTopMenu/index.module.scss';
import LayoutHeader from 'components/layouts/layoutWithTopMenu/layoutHeader';
import LayoutSider from 'components/layouts/layoutWithTopMenu/layoutSider';
import { COMPANY_NAME } from 'utils/constants/appConfig';
import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { useNotification, useUpdatePageTitle } from 'utils/hooks';

const { Content } = Layout;

interface Props {
  children: ReactNode;
  isExpertsPage?: boolean;
  sider?: ReactNode;
}

const LayoutWithTopMenu: FC<Props> = ({ children, isExpertsPage, sider }) => {
  useNotification();
  const pageInfo = useUpdatePageTitle('', ' - ' + COMPANY_NAME);
  const location = useLocation();
  const mainPages = [
    PAGES_INFO.mainPage.path,
    PAGES_INFO.advancedSearchPage.path,
    '/old',
    PAGES_INFO.communitiesPage.path,
    PAGES_INFO.communityPage.path,
  ];

  const isMainPage = mainPages.some((page) =>
    matchPath(page, location.pathname)
  );

  const isChemicalCompositionPage = matchPath(
    PAGES_INFO.chemicalCompositionPage.path,
    location.pathname
  );

  return (
    <Layout className={styles.layout}>
      <LayoutHeader isExpertsPage={isExpertsPage} isMainPage={isMainPage} />
      <Layout>
        <LayoutSider sider={sider}></LayoutSider>
        <Layout className={styles.content}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
      {!isChemicalCompositionPage && <HelpButton />}
    </Layout>
  );
};

export default LayoutWithTopMenu;
