interface ObjectLiteral {
  [key: string]: any;
}

export const codeToCountryList: ObjectLiteral = {
  AD: {
    latitude: 42.515299999999996,
    longitude: 1.5091999999999999,
    name: 'Андорра',
  },
  AE: {
    latitude: 23.481650000000002,
    longitude: 54.14905,
    name: 'Объединенные Арабские Эмираты',
  },
  AF: {
    latitude: 33.84633857225924,
    longitude: 66.00874868820993,
    name: 'Афганистан',
  },
  AG: {
    latitude: 17.07306666670874,
    longitude: -61.797700000148744,
    name: 'Антигуа и Барбуда',
  },
  AI: {
    latitude: 18.23505,
    longitude: -63.048849999999995,
    name: 'Ангуилла',
  },
  AL: { latitude: 40.584649999999996, longitude: 20.15605, name: 'Албания' },
  AM: {
    latitude: 40.30422368014186,
    longitude: 44.92798457617538,
    name: 'Армения',
  },
  AO: { latitude: -11.932699999999999, longitude: 17.86735, name: 'Ангола' },
  AP: {
    latitude: 0,
    longitude: 0,
    name: 'Африканская региональная организация промышленной собственности',
  },
  AR: {
    latitude: -31.829868750000003,
    longitude: -64.24573125,
    name: 'Аргентина',
  },
  AS: {
    latitude: -14.307033333271459,
    longitude: -170.74526666590594,
    name: 'Американское Самоа',
  },
  AT: {
    latitude: 47.58508440176958,
    longitude: 14.127775396656585,
    name: 'Австрия',
  },
  AU: {
    latitude: -23.482896875,
    longitude: 133.241546875,
    name: 'Австралия',
  },
  AW: {
    latitude: 12.50753088904019,
    longitude: -69.97599303913566,
    name: 'Аруба',
  },
  AX: {
    latitude: 60.23793126384454,
    longitude: 19.924953814754748,
    name: 'Аландские острова',
  },
  AZ: { latitude: 40.1509, longitude: 46.7411, name: 'Азербайджан' },
  BA: {
    latitude: 44.169966040571545,
    longitude: 17.74509091526827,
    name: 'Босния и Герцеговина',
  },
  BB: {
    latitude: 13.175228390483252,
    longitude: -59.53699740119683,
    name: 'Барбадос',
  },
  BD: {
    latitude: 23.6098875,
    longitude: 89.77551249999999,
    name: 'Бангладеш',
  },
  BE: {
    latitude: 50.64515853827321,
    longitude: 4.633654573402781,
    name: 'Бельгия',
  },
  BF: {
    latitude: 12.9674875,
    longitude: -0.6429374999999988,
    name: 'Буркина Фасо',
  },
  BG: { latitude: 42.7406, longitude: 23.8412, name: 'Болгария' },
  BH: {
    latitude: 26.042585529988926,
    longitude: 50.5351565714846,
    name: 'Бахрейн',
  },
  BI: {
    latitude: -3.365283764694054,
    longitude: 29.88096947107135,
    name: 'Бурунди',
  },
  BJ: {
    latitude: 9.631545583202852,
    longitude: 2.326494777611167,
    name: 'Бенин',
  },
  BN: {
    latitude: 4.451400049093909,
    longitude: 114.54227524597037,
    name: 'Бруней Даруссалам',
  },
  BO: {
    latitude: -16.691507942123348,
    longitude: -64.68798391713298,
    name: 'Боливия',
  },
  BQ: {
    latitude: 12.221845918081646,
    longitude: -68.28511891749784,
    name: 'Бонайре, Синт-Эстатиус и Саба',
  },
  BR: { latitude: -12.42435625, longitude: -49.02976875, name: 'Бразилия' },
  BT: { latitude: 27.5064, longitude: 90.47075000000001, name: 'Бутан' },
  BW: {
    latitude: -21.757699999999996,
    longitude: 23.941499999999994,
    name: 'Ботсвана',
  },
  BY: { latitude: 53.76275, longitude: 27.95055, name: 'Беларусь' },
  BZ: {
    latitude: 17.169771922097308,
    longitude: -88.7219761859712,
    name: 'Белиз',
  },
  CA: {
    latitude: 59.150909375,
    longitude: -120.69225937500002,
    name: 'Канада',
  },
  CD: {
    latitude: -1.1176125000000006,
    longitude: 23.319912499999997,
    name: 'Демократическая Республика Конго',
  },
  CF: {
    latitude: 7.178675,
    longitude: 21.706775,
    name: 'Центрально-Африканская Республика',
  },
  CG: {
    latitude: 0.13836874999999893,
    longitude: 16.272668749999998,
    name: 'Конго',
  },
  CH: {
    latitude: 46.80588811305936,
    longitude: 8.24696198855464,
    name: 'Швейцария',
  },
  CI: {
    latitude: 7.5377,
    longitude: -5.5546500000000005,
    name: 'Кот д’Ивуар',
  },
  CL: { latitude: -37.668587499999994, longitude: -72.4137375, name: 'Чили' },
  CM: { latitude: 4.536575, longitude: 11.416274999999997, name: 'Камерун' },
  CN: { latitude: 32.224834375, longitude: 109.514303125, name: 'Китай' },
  CO: {
    latitude: 3.6283375000000064,
    longitude: -73.24238749999999,
    name: 'Колумбия',
  },
  CR: { latitude: 10.406025, longitude: -85.052025, name: 'Коста-Рика' },
  CS: { latitude: 0, longitude: 0, name: 'Чехословакия (до 1993 г.)' },
  CU: {
    latitude: 21.106125000000002,
    longitude: -77.27432499999998,
    name: 'Куба',
  },
  CW: {
    latitude: 12.193950000000001,
    longitude: -69.00574999999999,
    name: 'Кюрасао',
  },
  CY: {
    latitude: 35.04249716944221,
    longitude: 33.2320980363507,
    name: 'Кипр',
  },
  CZ: {
    latitude: 49.7487,
    longitude: 15.554200000000002,
    name: 'Чешская республика',
  },
  DD: {
    latitude: 0,
    longitude: 0,
    name: 'Германия, за исключением территории, которая до 3 октября 1990 года составляла Федеративную Республику Германия',
  },
  DE: { latitude: 51.10835, longitude: 9.68995, name: 'Германия' },
  DJ: { latitude: 11.391124999999999, longitude: 42.177525, name: 'Джибути' },
  DK: {
    latitude: 56.23441197347804,
    longitude: 9.275236223044722,
    name: 'Дания',
  },
  DO: {
    latitude: 18.902233723384416,
    longitude: -70.4871466133291,
    name: 'Доминиканская Республика',
  },
  DZ: {
    latitude: 27.471568750000003,
    longitude: 3.192756249999998,
    name: 'Алжир',
  },
  EA: {
    latitude: 0,
    longitude: 0,
    name: 'Евразийская патентная организация',
  },
  EC: {
    latitude: -1.4293664591306479,
    longitude: -78.38696021923955,
    name: 'Эквадор',
  },
  EE: { latitude: 58.58355, longitude: 26.66035, name: 'Эстония' },
  EG: {
    latitude: 26.221306249999998,
    longitude: 28.937706249999998,
    name: 'Египет',
  },
  EH: {
    latitude: 24.2314,
    longitude: -13.623000000000001,
    name: 'Западная Сахара',
  },
  EP: {
    latitude: 72.6022375,
    longitude: -5.6655125,
    name: 'Европейское патентное ведомство',
  },
  ER: {
    latitude: 15.894212499999998,
    longitude: 38.537387499999994,
    name: 'Эритрея',
  },
  ES: {
    latitude: 40.390519372674376,
    longitude: -3.575921247545481,
    name: 'Испания',
  },
  ET: { latitude: 8.798009375, longitude: 38.354809375, name: 'Эфиопия' },
  FI: {
    latitude: 63.120631249999995,
    longitude: 27.56336875,
    name: 'Финляндия',
  },
  FJ: { latitude: -17.81345, longitude: 177.96585, name: 'Фиджи' },
  FM: {
    latitude: 6.879737311165404,
    longitude: 158.23079560976117,
    name: 'Федеративные Штаты Микронезии',
  },
  FO: {
    latitude: 62.20250000000373,
    longitude: -6.938933333333862,
    name: 'Фарерские острова',
  },
  FR: {
    latitude: 46.60749399720904,
    longitude: 2.4654347824043876,
    name: 'Франция',
  },
  GA: {
    latitude: -0.3793624999999998,
    longitude: 12.358437500000003,
    name: 'Габон',
  },
  GB: { latitude: 52.6022375, longitude: -1.6655125, name: 'Великобритания' },
  GC: {
    latitude: 0,
    longitude: 0,
    name: 'Совет сотрудничества стран Персидского залива',
  },
  GD: {
    latitude: 12.121494661023984,
    longitude: -61.66651640461232,
    name: 'Гренада',
  },
  GE: {
    latitude: 42.16080644996161,
    longitude: 43.53340945581518,
    name: 'Грузия',
  },
  GF: { latitude: 3.9517, longitude: -53.13445, name: 'Французская Гвиана' },
  GG: {
    latitude: 49.463833333363056,
    longitude: -2.5686333333348457,
    name: 'Гернси',
  },
  GH: { latitude: 6.9738999999999995, longitude: -1.0242, name: 'Гана' },
  GI: { latitude: 36.13725, longitude: -5.34535, name: 'Гибралтар' },
  GL: {
    latitude: 74.72145,
    longitude: -40.265249999999995,
    name: 'Гренландия',
  },
  GM: {
    latitude: 13.370610297901576,
    longitude: -16.212978950401666,
    name: 'Гамбия',
  },
  GN: {
    latitude: 10.6270125,
    longitude: -10.267262500000001,
    name: 'Гвинея',
  },
  GO: {
    latitude: -11.565528112660337,
    longitude: 47.29057723084124,
    name: 'Глорьёз',
  },
  GP: {
    latitude: 16.332821785076938,
    longitude: -61.4423860462686,
    name: 'Гваделупа',
  },
  GQ: {
    latitude: 1.5695675026515254,
    longitude: 10.475395289811193,
    name: 'Экваториальная Гвинея',
  },
  GR: { latitude: 39.863525, longitude: 21.854475, name: 'Греция' },
  GT: {
    latitude: 15.238950000000004,
    longitude: -90.73264999999999,
    name: 'Гватемала',
  },
  GU: { latitude: 13.3931, longitude: 144.7199, name: 'Гуам' },
  GW: {
    latitude: 12.048603034904886,
    longitude: -14.943194432193785,
    name: 'Гвинея-Биссау',
  },
  GY: { latitude: 4.8763499999999995, longitude: -58.92975, name: 'Гайана' },
  HK: {
    latitude: 22.44943333327509,
    longitude: 114.18306666636963,
    name: 'Гонконг, Особый Административный Район Китая',
  },
  HN: {
    latitude: 14.817542886753204,
    longitude: -86.60763710491332,
    name: 'Гондурас',
  },
  HR: {
    latitude: 45.60934999999999,
    longitude: 16.248849999999997,
    name: 'Хорватия',
  },
  HT: { latitude: 19.4377, longitude: -72.088, name: 'Гаити' },
  HU: { latitude: 47.1613, longitude: 20.265599999999996, name: 'Венгрия' },
  ID: {
    latitude: -0.9753374999999997,
    longitude: 114.22973750000001,
    name: 'Индонезия',
  },
  IE: {
    latitude: 53.23414776965513,
    longitude: -8.117688378564083,
    name: 'Ирландия',
  },
  IL: {
    latitude: 30.695075000000003,
    longitude: 34.65132499999999,
    name: 'Израиль',
  },
  IN: { latitude: 22.215503125, longitude: 78.87497812499998, name: 'Индия' },
  IQ: { latitude: 33.217749999999995, longitude: 42.92765, name: 'Ирак' },
  IR: {
    latitude: 31.937565624999998,
    longitude: 55.41510937500001,
    name: 'Иран',
  },
  IS: { latitude: 64.96055, longitude: -18.92, name: 'Исландия' },
  IT: { latitude: 45.28119375, longitude: 10.58108125, name: 'Италия' },
  JE: { latitude: 49.2214, longitude: -2.1907999999999985, name: 'Джерси' },
  JM: {
    latitude: 18.172883343648614,
    longitude: -77.32853407462757,
    name: 'Ямайка',
  },
  JO: {
    latitude: 30.758625000000002,
    longitude: 36.518724999999996,
    name: 'Иордания',
  },
  JP: { latitude: 36.4857625, longitude: 139.7152875, name: 'Япония' },
  KE: { latitude: 0.40015, longitude: 37.8935, name: 'Кения' },
  KG: { latitude: 41.75355, longitude: 74.68005, name: 'Кыргызстан' },
  KH: { latitude: 12.5581, longitude: 104.4667, name: 'Камбоджа' },
  KI: { latitude: 1.79375, longitude: -157.3331, name: 'Кирибати' },
  KN: {
    latitude: 17.328290982435075,
    longitude: -62.745374812901225,
    name: 'Сент-Киттс и Невис',
  },
  KP: {
    latitude: 39.70567499999999,
    longitude: 126.337675,
    name: 'Корейская Народно-Демократическая Республика',
  },
  KR: {
    latitude: 36.558499999999995,
    longitude: 127.8895,
    name: 'Республика Корея',
  },
  KW: {
    latitude: 29.30611297850319,
    longitude: 47.559133054911015,
    name: 'Кувейт',
  },
  KZ: {
    latitude: 48.94372499999999,
    longitude: 67.83997499999998,
    name: 'Казахстан',
  },
  LA: { latitude: 19.572649999999996, longitude: 102.00515, name: 'Лаос' },
  LB: {
    latitude: 33.915360503302296,
    longitude: 35.88510219928067,
    name: 'Ливан',
  },
  LC: {
    latitude: 13.916366666654225,
    longitude: -60.94853333327914,
    name: 'Сент-Люсия',
  },
  LI: { latitude: 47.1034, longitude: 9.5339, name: 'Лихтенштейн' },
  LK: { latitude: 7.019900000000001, longitude: 80.8332, name: 'Шри-Ланка' },
  LR: { latitude: 6.44285, longitude: -9.4687, name: 'Либерия' },
  LS: { latitude: -29.61185, longitude: 28.08195, name: 'Лесото' },
  LT: {
    latitude: 55.173249999999996,
    longitude: 24.746550000000003,
    name: 'Литва',
  },
  LU: { latitude: 49.7868, longitude: 6.078099999999999, name: 'Люксембург' },
  LV: { latitude: 56.86555, longitude: 27.035949999999993, name: 'Латвия' },
  LY: {
    latitude: 25.911737500000005,
    longitude: 20.857087500000002,
    name: 'Ливия',
  },
  MA: {
    latitude: 31.887710632495892,
    longitude: -6.343475105349019,
    name: 'Марокко',
  },
  MC: { latitude: 43.75135, longitude: 7.4083000000000006, name: 'Монако' },
  MD: {
    latitude: 47.20354902891279,
    longitude: 28.458435847850026,
    name: 'Молдова',
  },
  ME: {
    latitude: 42.79145678223112,
    longitude: 19.20473624251002,
    name: 'Черногория',
  },
  MG: { latitude: -18.82125, longitude: 46.87475, name: 'Мадагаскар' },
  MK: { latitude: 41.5818, longitude: 21.2212, name: 'Македония' },
  ML: { latitude: 18.50631875, longitude: -2.082943749999999, name: 'Мали' },
  MM: {
    latitude: 21.94954374999999,
    longitude: 96.09928124999999,
    name: 'Мьянма',
  },
  MN: {
    latitude: 46.213631250000006,
    longitude: 105.38370624999997,
    name: 'Монголия',
  },
  MO: {
    latitude: 22.21855,
    longitude: 113.50165000000001,
    name: 'Макао (особый административный район КНР)',
  },
  MQ: {
    latitude: 14.627944262223886,
    longitude: -61.01537907419025,
    name: 'Мартиник',
  },
  MR: {
    latitude: 19.393424999999997,
    longitude: -9.407225,
    name: 'Мавритания',
  },
  MS: {
    latitude: 16.718437697401303,
    longitude: -62.18594523466829,
    name: 'Монсеррат',
  },
  MU: {
    latitude: -20.23444665406915,
    longitude: 57.598325153512405,
    name: 'Маврикий',
  },
  MV: { latitude: 4.192149999999997, longitude: 73.50075, name: 'Мальдивы' },
  MW: {
    latitude: -13.524599999999994,
    longitude: 33.862899999999996,
    name: 'Малави',
  },
  MX: { latitude: 23.63035, longitude: -101.9762, name: 'Мексика' },
  MY: { latitude: 5.223050000000001, longitude: 116.89765, name: 'Малайзия' },
  MZ: {
    latitude: -13.595374999999997,
    longitude: 38.184174999999996,
    name: 'Мозамбик',
  },
  NA: {
    latitude: -20.696975000000002,
    longitude: 16.964325000000002,
    name: 'Намибия',
  },
  NC: {
    latitude: -20.778625,
    longitude: 164.70232500000003,
    name: 'Новая Каледония',
  },
  NE: {
    latitude: 17.976578125000003,
    longitude: 10.877215624999998,
    name: 'Нигер',
  },
  NG: { latitude: 9.08155, longitude: 7.47715, name: 'Нигерия' },
  NI: {
    latitude: 12.828608374919549,
    longitude: -85.02123513436841,
    name: 'Никарагуа',
  },
  NL: { latitude: 52.09585, longitude: 5.3922, name: 'Нидерланды' },
  NO: { latitude: 60.47428125, longitude: 7.37768125, name: 'Норвегия' },
  NP: {
    latitude: 28.240664667742166,
    longitude: 83.93120809822271,
    name: 'Непал',
  },
  NR: {
    latitude: -0.5207999999992478,
    longitude: 166.92943333317348,
    name: 'Науру',
  },
  NU: {
    latitude: -19.060702784068784,
    longitude: -169.87003850065344,
    name: 'Ниуе',
  },
  NZ: {
    latitude: -44.70318125,
    longitude: 170.15559374999998,
    name: 'Новая Зеландия',
  },
  OM: { latitude: 22.02623125, longitude: 57.35533125, name: 'Оман' },
  PA: { latitude: 8.40905, longitude: -81.80865, name: 'Панама' },
  PE: {
    latitude: -13.205768749999999,
    longitude: -73.03411874999999,
    name: 'Перу',
  },
  PF: {
    latitude: -17.62234907706391,
    longitude: -149.47576960782953,
    name: 'Французская Полинезия',
  },
  PG: {
    latitude: -6.121668749999999,
    longitude: 143.48180625000003,
    name: 'Папуа-Новая Гвинея',
  },
  PH: {
    latitude: 17.41808749999999,
    longitude: 121.44786250000001,
    name: 'Филиппины',
  },
  PK: {
    latitude: 31.24224375,
    longitude: 71.57741874999999,
    name: 'Пакистан',
  },
  PL: {
    latitude: 52.132737581579434,
    longitude: 19.364455813867174,
    name: 'Польша',
  },
  PM: {
    latitude: 47.015475649779894,
    longitude: -56.32832714895905,
    name: 'Сен-Пьер и Микелон',
  },
  PN: {
    latitude: -24.365408780801992,
    longitude: -128.3168254934691,
    name: 'Питкерн',
  },
  PR: {
    latitude: 18.23685,
    longitude: -66.93424999999999,
    name: 'Пуэрто-Рико',
  },
  PS: {
    latitude: 32.00685,
    longitude: 35.2548,
    name: 'Палестинская автономия',
  },
  PT: {
    latitude: 39.044262499999995,
    longitude: -8.2510625,
    name: 'Португалия',
  },
  PW: { latitude: 7.522600000000001, longitude: 134.58295, name: 'Палау' },
  PY: {
    latitude: -21.864593749999997,
    longitude: -60.07230624999999,
    name: 'Парагвай',
  },
  QA: {
    latitude: 25.355730975810705,
    longitude: 51.17203972974568,
    name: 'Катар',
  },
  RE: {
    latitude: -21.11229267934641,
    longitude: 55.53930047950685,
    name: 'Реюньон',
  },
  RO: {
    latitude: 45.839573124013455,
    longitude: 24.971641078618894,
    name: 'Румыния',
  },
  RS: {
    latitude: 44.214533570208886,
    longitude: 20.743403918206006,
    name: 'Сербия',
  },
  RU: {
    latitude: 63.46575156249998,
    longitude: 92.96908281250002,
    name: 'Россия',
  },
  RW: { latitude: -1.93805, longitude: 29.74715, name: 'Руанда' },
  SA: {
    latitude: 23.755971874999997,
    longitude: 44.95384062499999,
    name: 'Саудовская Аравия',
  },
  SD: {
    latitude: 16.580918750000002,
    longitude: 29.740818750000003,
    name: 'Судан',
  },
  SE: {
    latitude: 65.509446875,
    longitude: 18.057871875000004,
    name: 'Швеция',
  },
  SG: {
    latitude: 1.346993919051679,
    longitude: 103.8128221980573,
    name: 'Сингапур',
  },
  SH: {
    latitude: -15.953566666657325,
    longitude: -5.725033333330105,
    name: 'Остров Святой Елены',
  },
  SI: { latitude: 46.1459, longitude: 14.9578, name: 'Словения' },
  SJ: {
    latitude: 78.2431,
    longitude: 16.12745,
    name: 'Свальбард и Ян-Майен',
  },
  SK: {
    latitude: 48.714412464500136,
    longitude: 19.481798026482785,
    name: 'Словакия',
  },
  SL: {
    latitude: 8.576755348226074,
    longitude: -11.77685756834265,
    name: 'Сьерра-Леоне',
  },
  SM: {
    latitude: 43.9422717689382,
    longitude: 12.458740643182601,
    name: 'Сан-Марино',
  },
  SN: { latitude: 14.4864, longitude: -14.2787, name: 'Сенегал' },
  SO: { latitude: 2.806506250000001, longitude: 42.89403125, name: 'Сомали' },
  SR: {
    latitude: 4.128425980848393,
    longitude: -55.91295551877844,
    name: 'Суринам',
  },
  SS: {
    latitude: 7.307502723574737,
    longitude: 30.253641845364793,
    name: 'Южный Судан',
  },
  ST: {
    latitude: 0.27753962355251405,
    longitude: 6.648781643111736,
    name: 'Сан-Томе и Принсипи',
  },
  SU: {
    latitude: 64.46575156249998,
    longitude: 92.96908281250002,
    name: 'Советский союз',
  },
  SV: {
    latitude: 13.738588177898446,
    longitude: -88.8686600479542,
    name: 'Сальвадор',
  },
  SX: {
    latitude: 18.052233333393623,
    longitude: -63.05240000021236,
    name: 'Синт-Mартен (голландская Часть)',
  },
  SY: {
    latitude: 35.02565312955368,
    longitude: 38.49915462419216,
    name: 'Сирийская Арабская Республика',
  },
  SZ: {
    latitude: -26.5261,
    longitude: 31.453500000000002,
    name: 'Свазиленд',
  },
  TC: {
    latitude: 21.80910321740733,
    longitude: -71.74133910620496,
    name: 'Острова Тёркс и Кайкос',
  },
  TD: { latitude: 17.352237499999998, longitude: 19.3742625, name: 'Чад' },
  TF: {
    latitude: -49.3860987043008,
    longitude: 69.39165831285145,
    name: 'Французские Южные Территории',
  },
  TG: { latitude: 7.35775, longitude: 1.19965, name: 'Того' },
  TH: {
    latitude: 15.452562499999999,
    longitude: 101.04501249999998,
    name: 'Таиланд',
  },
  TJ: {
    latitude: 38.3175875,
    longitude: 72.21996250000002,
    name: 'Таджикистан',
  },
  TK: {
    latitude: -8.565876732484282,
    longitude: -172.4877890118085,
    name: 'Токелау',
  },
  TL: {
    latitude: -8.858396699200172,
    longitude: 125.78200114955501,
    name: 'Восточный Тимор',
  },
  TM: {
    latitude: 39.47747499999999,
    longitude: 59.56742500000001,
    name: 'Туркменистан',
  },
  TN: { latitude: 36.2365, longitude: 9.5024, name: 'Тунис' },
  TO: {
    latitude: -21.181007669970423,
    longitude: -175.22563189902706,
    name: 'Тонга',
  },
  TR: { latitude: 39.31128125, longitude: 33.45929374999999, name: 'Турция' },
  TT: {
    latitude: 10.33997024268313,
    longitude: -61.27049327782416,
    name: 'Тринидад и Тобаго',
  },
  TV: {
    latitude: -8.508033333751545,
    longitude: 179.2186000089678,
    name: 'Тувалу',
  },
  TW: {
    latitude: 23.811729990708372,
    longitude: 120.96783338871558,
    name: 'Тайвань',
  },
  TZ: {
    latitude: -5.689562500000001,
    longitude: 34.0300625,
    name: 'Танзания',
  },
  UA: { latitude: 48.863524999999996, longitude: 33.570475, name: 'Украина' },
  UG: { latitude: 1.9893875000000008, longitude: 32.9390875, name: 'Уганда' },
  UK: {
    latitude: 51.509865,
    longitude: -0.118092,
    name: 'Объединенное королевство',
  },
  US: {
    latitude: 38.937092187500006,
    longitude: -97.44396406249997,
    name: 'США',
  },
  UY: {
    latitude: -32.80179614488365,
    longitude: -56.01354101371762,
    name: 'Уругвай',
  },
  UZ: {
    latitude: 41.887806250000004,
    longitude: 63.835043750000004,
    name: 'Узбекистан',
  },
  VA: {
    latitude: 41.90177687652717,
    longitude: 12.434737553328286,
    name: 'Ватикан',
  },
  VC: {
    latitude: 13.234799999999998,
    longitude: -61.200599999999994,
    name: 'Сент-Винсент и Гренадины',
  },
  VE: { latitude: 7.19368125, longitude: -64.09989375, name: 'Венесуэла' },
  VN: { latitude: 21.8076625, longitude: 105.3196875, name: 'Вьетнам' },
  VU: { latitude: -15.29895, longitude: 166.86345, name: 'Вануату' },
  WF: {
    latitude: -14.290899999848246,
    longitude: -178.11583333148576,
    name: 'Уоллис и Футуна',
  },
  WO: {
    latitude: -11.932699999999999,
    longitude: -15,
    name: 'Всемирная организация интеллектуальной собственности',
  },
  WS: {
    latitude: -13.648257683793805,
    longitude: -172.4540714397885,
    name: 'Самоа',
  },
  XK: {
    latitude: 42.526466365373444,
    longitude: 20.901475390862913,
    name: 'Республика Косово',
  },
  YE: {
    latitude: 15.407575000000001,
    longitude: 45.508174999999994,
    name: 'Йемен',
  },
  YT: { latitude: -12.8429, longitude: 45.14625, name: 'Майотта' },
  YU: { latitude: 0, longitude: 0, name: 'Югославия (Сербия и Черногория)' },
  ZA: {
    latitude: -30.441040625,
    longitude: 23.162128125000002,
    name: 'Южная Африка',
  },
  ZM: {
    latitude: -14.90683125,
    longitude: 26.250956249999998,
    name: 'Замбия',
  },
  ZW: {
    latitude: -19.006839036907028,
    longitude: 29.851226690474693,
    name: 'Зимбабве',
  },
};

export const countryNameToCodeList: ObjectLiteral = {
  Австралия: {
    code: 'AU',
  },
  Австрия: {
    code: 'AT',
  },
  Азербайджан: { code: 'AZ' },
  'Аландские острова': {
    code: 'AX',
  },
  Албания: {
    code: 'AL',
  },
  Алжир: {
    code: 'DZ',
  },
  'Американское Самоа': {
    code: 'AS',
  },
  Ангола: {
    code: 'AO',
  },
  Ангуилла: {
    code: 'AI',
  },
  Андорра: {
    code: 'AD',
  },
  'Антигуа и Барбуда': {
    code: 'AG',
  },
  Аргентина: {
    code: 'AR',
  },
  Армения: {
    code: 'AM',
  },
  Аруба: {
    code: 'AW',
  },
  Афганистан: {
    code: 'AF',
  },
  'Африканская региональная организация промышленной собственности': {
    code: 'AP',
  },
  Бангладеш: {
    code: 'BD',
  },
  Барбадос: {
    code: 'BB',
  },
  Бахрейн: {
    code: 'BH',
  },
  Беларусь: { code: 'BY' },
  Белиз: {
    code: 'BZ',
  },
  Бельгия: {
    code: 'BE',
  },
  Бенин: {
    code: 'BJ',
  },
  Болгария: { code: 'BG' },
  Боливия: {
    code: 'BO',
  },
  'Бонайре, Синт-Эстатиус и Саба': {
    code: 'BQ',
  },
  'Босния и Герцеговина': {
    code: 'BA',
  },
  Ботсвана: {
    code: 'BW',
    name: 'Ботсвана',
  },
  Бразилия: {
    code: 'BR',
    name: 'Бразилия',
  },
  'Бруней Даруссалам': {
    code: 'BN',
  },
  'Буркина Фасо': {
    code: 'BF',
  },
  Бурунди: {
    code: 'BI',
  },
  Бутан: {
    code: 'BT',
    name: 'Бутан',
  },
  Вануату: { code: 'VU' },
  Ватикан: {
    code: 'VA',
  },
  Великобритания: {
    code: 'GB',
  },
  Венгрия: {
    code: 'HU',
  },
  Венесуэла: {
    code: 'VE',
  },
  'Восточный Тимор': {
    code: 'TL',
  },
  'Всемирная организация интеллектуальной собственности': {
    code: 'WO',
  },
  Вьетнам: {
    code: 'VN',
  },
  Габон: {
    code: 'GA',
  },
  Гаити: { code: 'HT' },
  Гайана: {
    code: 'GY',
  },
  Гамбия: {
    code: 'GM',
  },
  Гана: {
    code: 'GH',
  },
  Гваделупа: {
    code: 'GP',
  },
  Гватемала: {
    code: 'GT',
  },
  Гвинея: {
    code: 'GN',
  },
  'Гвинея-Биссау': {
    code: 'GW',
  },
  Германия: { code: 'DE' },
  'Германия, за исключением территории, которая до 3 октября 1990 года составляла Федеративную Республику Германия':
    {
      code: 'DD',
    },
  Гернси: {
    code: 'GG',
  },
  Гибралтар: { code: 'GI' },
  Глорьёз: {
    code: 'GO',
  },
  Гондурас: {
    code: 'HN',
  },
  'Гонконг, Особый Административный Район Китая': {
    code: 'HK',
  },
  Гренада: {
    code: 'GD',
  },
  Гренландия: {
    code: 'GL',
  },
  Греция: { code: 'GR' },
  Грузия: {
    code: 'GE',
  },
  Гуам: { code: 'GU' },
  Дания: {
    code: 'DK',
  },
  'Демократическая Республика Конго': {
    code: 'CD',
  },
  Джерси: {
    code: 'JE',
  },
  Джибути: {
    code: 'DJ',
  },
  'Доминиканская Республика': {
    code: 'DO',
  },
  'Евразийская патентная организация': {
    code: 'EA',
  },
  'Европейское патентное ведомство': {
    code: 'EP',
  },
  Египет: {
    code: 'EG',
  },
  Замбия: {
    code: 'ZM',
  },
  'Западная Сахара': {
    code: 'EH',
  },
  Зимбабве: {
    code: 'ZW',
  },
  Израиль: {
    code: 'IL',
  },
  Индия: {
    code: 'IN',
  },
  Индонезия: {
    code: 'ID',
  },
  Иордания: {
    code: 'JO',
  },
  Ирак: {
    code: 'IQ',
  },
  Иран: {
    code: 'IR',
  },
  Ирландия: {
    code: 'IE',
  },
  Исландия: { code: 'IS' },
  Испания: {
    code: 'ES',
  },
  Италия: {
    code: 'IT',
  },
  Йемен: {
    code: 'YE',
  },
  Казахстан: {
    code: 'KZ',
  },
  Камбоджа: { code: 'KH' },
  Камерун: {
    code: 'CM',
  },
  Канада: {
    code: 'CA',
  },
  Катар: {
    code: 'QA',
  },
  Кения: { code: 'KE' },
  Кипр: {
    code: 'CY',
  },
  Кирибати: { code: 'KI' },
  Китай: {
    code: 'CN',
  },
  Колумбия: {
    code: 'CO',
  },
  Конго: {
    code: 'CG',
  },
  'Корейская Народно-Демократическая Республика': {
    code: 'KP',
  },
  'Коста-Рика': {
    code: 'CR',
  },
  'Кот д’Ивуар': {
    code: 'CI',
  },
  Куба: {
    code: 'CU',
  },
  Кувейт: {
    code: 'KW',
  },
  Кыргызстан: { code: 'KG' },
  Кюрасао: {
    code: 'CW',
  },
  Лаос: {
    code: 'LA',
  },
  Латвия: {
    code: 'LV',
  },
  Лесото: { code: 'LS' },
  Либерия: { code: 'LR' },
  Ливан: {
    code: 'LB',
  },
  Ливия: {
    code: 'LY',
  },
  Литва: {
    code: 'LT',
  },
  Лихтенштейн: { code: 'LI' },
  Люксембург: {
    code: 'LU',
  },
  Маврикий: {
    code: 'MU',
  },
  Мавритания: {
    code: 'MR',
  },
  Мадагаскар: {
    code: 'MG',
  },
  Майотта: { code: 'YT' },
  'Макао (особый административный район КНР)': {
    code: 'MO',
  },
  Македония: { code: 'MK' },
  Малави: {
    code: 'MW',
  },
  Малайзия: {
    code: 'MY',
  },
  Мали: {
    code: 'ML',
  },
  Мальдивы: {
    code: 'MV',
  },
  Марокко: {
    code: 'MA',
  },
  Мартиник: {
    code: 'MQ',
  },
  Мексика: { code: 'MX' },
  Мозамбик: {
    code: 'MZ',
  },
  Молдова: {
    code: 'MD',
  },
  Монако: {
    code: 'MC',
  },
  Монголия: {
    code: 'MN',
  },
  Монсеррат: {
    code: 'MS',
  },
  Мьянма: {
    code: 'MM',
  },
  Намибия: {
    code: 'NA',
  },
  Науру: {
    code: 'NR',
  },
  Непал: {
    code: 'NP',
  },
  Нигер: {
    code: 'NE',
  },
  Нигерия: { code: 'NG' },
  Нидерланды: { code: 'NL' },
  Никарагуа: {
    code: 'NI',
  },
  Ниуе: {
    code: 'NU',
  },
  'Новая Зеландия': {
    code: 'NZ',
  },
  'Новая Каледония': {
    code: 'NC',
  },
  Норвегия: {
    code: 'NO',
  },
  'Объединенное королевство': { code: 'UK' },
  'Объединенные Арабские Эмираты': {
    code: 'AE',
  },
  Оман: { code: 'OM' },
  'Остров Святой Елены': {
    code: 'SH',
  },
  'Острова Тёркс и Кайкос': {
    code: 'TC',
  },
  Пакистан: {
    code: 'PK',
  },
  Палау: {
    code: 'PW',
  },
  'Палестинская автономия': {
    code: 'PS',
  },
  Панама: { code: 'PA' },
  'Папуа-Новая Гвинея': {
    code: 'PG',
  },
  Парагвай: {
    code: 'PY',
  },
  Перу: {
    code: 'PE',
  },
  Питкерн: {
    code: 'PN',
  },
  Польша: {
    code: 'PL',
  },
  Португалия: {
    code: 'PT',
  },
  'Пуэрто-Рико': {
    code: 'PR',
  },
  'Республика Корея': {
    code: 'KR',
  },
  'Республика Косово': {
    code: 'XK',
  },
  Реюньон: {
    code: 'RE',
  },
  Россия: {
    code: 'RU',
  },
  Руанда: { code: 'RW' },
  Румыния: {
    code: 'RO',
  },
  Сальвадор: {
    code: 'SV',
  },
  Самоа: {
    code: 'WS',
  },
  'Сан-Марино': {
    code: 'SM',
  },
  'Сан-Томе и Принсипи': {
    code: 'ST',
  },
  'Саудовская Аравия': {
    code: 'SA',
  },
  Свазиленд: {
    code: 'SZ',
  },
  'Свальбард и Ян-Майен': {
    code: 'SJ',
  },
  'Сен-Пьер и Микелон': {
    code: 'PM',
  },
  Сенегал: { code: 'SN' },
  'Сент-Винсент и Гренадины': {
    code: 'VC',
  },
  'Сент-Киттс и Невис': {
    code: 'KN',
  },
  'Сент-Люсия': {
    code: 'LC',
  },
  Сербия: {
    code: 'RS',
  },
  Сингапур: {
    code: 'SG',
  },
  'Синт-Mартен (голландская Часть)': {
    code: 'SX',
  },
  'Сирийская Арабская Республика': {
    code: 'SY',
  },
  Словакия: {
    code: 'SK',
  },
  Словения: { code: 'SI' },
  'Совесткий союз': {
    code: 'SU',
  },
  'Совет сотрудничества стран Персидского залива': {
    code: 'GC',
  },
  Сомали: {
    code: 'SO',
  },
  Судан: {
    code: 'SD',
  },
  Суринам: {
    code: 'SR',
  },
  США: {
    code: 'US',
  },
  'Сьерра-Леоне': {
    code: 'SL',
  },
  Таджикистан: {
    code: 'TJ',
  },
  Таиланд: {
    code: 'TH',
  },
  Тайвань: {
    code: 'TW',
  },
  Танзания: {
    code: 'TZ',
  },
  Того: { code: 'TG' },
  Токелау: {
    code: 'TK',
  },
  Тонга: {
    code: 'TO',
  },
  'Тринидад и Тобаго': {
    code: 'TT',
  },
  Тувалу: {
    code: 'TV',
  },
  Тунис: { code: 'TN' },
  Туркменистан: {
    code: 'TM',
  },
  Турция: {
    code: 'TR',
  },
  Уганда: {
    code: 'UG',
  },
  Узбекистан: {
    code: 'UZ',
  },
  Украина: {
    code: 'UA',
  },
  'Уоллис и Футуна': {
    code: 'WF',
  },
  Уругвай: {
    code: 'UY',
  },
  'Фарерские острова': {
    code: 'FO',
  },
  'Федеративные Штаты Микронезии': {
    code: 'FM',
  },
  Фиджи: { code: 'FJ' },
  Филиппины: {
    code: 'PH',
    name: 'Филиппины',
  },
  Финляндия: {
    code: 'FI',
  },
  Франция: {
    code: 'FR',
  },
  'Французская Гвиана': {
    code: 'GF',
  },
  'Французская Полинезия': {
    code: 'PF',
  },
  'Французские Южные Территории': {
    code: 'TF',
  },
  Хорватия: {
    code: 'HR',
  },
  'Центрально-Африканская Республика': {
    code: 'CF',
  },
  Чад: {
    code: 'TD',
  },
  Черногория: {
    code: 'ME',
  },
  'Чехословакия (до 1993 г.)': {
    code: 'CS',
  },
  'Чешская республика': {
    code: 'CZ',
  },
  Чили: {
    code: 'CL',
  },
  Швейцария: {
    code: 'CH',
  },
  Швеция: {
    code: 'SE',
  },
  'Шри-Ланка': {
    code: 'LK',
  },
  Эквадор: {
    code: 'EC',
  },
  'Экваториальная Гвинея': {
    code: 'GQ',
  },
  Эритрея: {
    code: 'ER',
  },
  Эстония: { code: 'EE' },
  Эфиопия: {
    code: 'ET',
  },
  'Югославия (Сербия и Черногория)': {
    code: 'YU',
  },
  'Южная Африка': {
    code: 'ZA',
  },
  'Южный Судан': {
    code: 'SS',
  },
  Ямайка: {
    code: 'JM',
  },
  Япония: {
    code: 'JP',
  },
};
