import { Form, Radio } from 'antd';

import { LAST_DATE_TYPE_ARR } from 'utils/constants/lastDateType';

const LastDateTypeRadio = () => (
  <Form.Item name='lastDateType' rules={rules}>
    <Radio.Group
      buttonStyle='solid'
      optionType='button'
      options={radioOptions}
    />
  </Form.Item>
);

const rules = [{ required: true }];
const radioOptions = LAST_DATE_TYPE_ARR.map((v) => ({ ...v, label: v.title }));

export default LastDateTypeRadio;
