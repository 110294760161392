import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

import { IChemicalCombination } from 'utils/interfaces/chemicalElementsFilter.interface';

const initialState = {
  similarCombinations: [],
  similarCombinationsNames: [],
  selectedElements: [],
  selectedElementsNames: [],
};

interface IInitialState {
  similarCombinations: IChemicalCombination[];
  similarCombinationsNames: string[];
  selectedElements: IChemicalCombination[];
  selectedElementsNames: string[];
}

const communitiesSlice = createSlice<
  IInitialState,
  SliceCaseReducers<IInitialState>
>({
  initialState,
  name: 'chemical',
  reducers: {
    setSimilarCombinations(
      state,
      action: PayloadAction<IChemicalCombination[]>
    ) {
      state.similarCombinations = action.payload;
      state.similarCombinationsNames = action.payload.map((e) => e.name);
    },
    setSelectedElements(state, action: PayloadAction<IChemicalCombination[]>) {
      state.selectedElements = action.payload;
      state.selectedElementsNames = action.payload.map((e) => e.name);
    },
    updateSelectedElement(state, action: PayloadAction<IChemicalCombination>) {
      state.selectedElements = state.selectedElements.map((element) => {
        if (element.name === action.payload.name) {
          return action.payload;
        }

        return element;
      });
    },
  },
});

const { actions, reducer } = communitiesSlice;
export const {
  setSimilarCombinations,
  setSelectedElements,
  updateSelectedElement,
} = actions;

export default reducer;
