import { TRENDS_ANALYSIS } from 'utils/constants/actionTypes';

const trendsAnalysisActions = {
  externalNewsByTopic: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_BY_TOPIC_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_BY_TOPIC_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_BY_TOPIC_LOADING,
    }),
  },

  companies: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.COMPANIES_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: TRENDS_ANALYSIS.COMPANIES_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.COMPANIES_LOADING,
    }),
  },

  countries: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.COUNTRIES_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: TRENDS_ANALYSIS.COUNTRIES_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.COUNTRIES_LOADING,
    }),
  },

  externalNews: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_LOADING,
    }),
  },

  externalNewsTopics: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_TOPICS_LOADING,
    }),
    set: (data = []) => ({
      payload: data,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_TOPICS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.EXTERNAL_NEWS_TOPICS_LOADING,
    }),
  },

  finishLoadAll: () => ({
    payload: false,
    type: TRENDS_ANALYSIS.LOADING_ALL,
  }),

  load: (data: any) => ({
    payload: data,
    type: TRENDS_ANALYSIS.LOAD,
  }),

  loadAll: (filters: any) => ({
    payload: filters,
    type: TRENDS_ANALYSIS.LOAD_ALL,
  }),

  patents: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.PATENTS_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: TRENDS_ANALYSIS.PATENTS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.PATENTS_LOADING,
    }),
  },

  patentsDirections: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.PATENTS_DIRECTIONS_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: TRENDS_ANALYSIS.PATENTS_DIRECTIONS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.PATENTS_DIRECTIONS_LOADING,
    }),
  },
  publications: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.PUBLICATIONS_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: TRENDS_ANALYSIS.PUBLICATIONS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.PUBLICATIONS_LOADING,
    }),
  },
  publicationsDirections: {
    finishLoad: () => ({
      payload: false,
      type: TRENDS_ANALYSIS.PUBLICATIONS_DIRECTIONS_LOADING,
    }),
    set: (data = {}) => ({
      payload: data,
      type: TRENDS_ANALYSIS.PUBLICATIONS_DIRECTIONS_SET_DATA,
    }),
    startLoad: () => ({
      payload: true,
      type: TRENDS_ANALYSIS.PUBLICATIONS_DIRECTIONS_LOADING,
    }),
  },
  resetAll: () => ({
    type: TRENDS_ANALYSIS.RESET_ALL,
  }),
  startLoadAll: () => ({
    payload: true,
    type: TRENDS_ANALYSIS.LOADING_ALL,
  }),
};

export default trendsAnalysisActions;
