import { CloseOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { FC, useState } from 'react';

import StatisticTable from 'components/expertsPageComponents/expertPage/statisticTable';
import PatentDescription from 'components/searchPageComponents/patentDescription';
import { getPatentsPublicationsApi } from 'utils/helpers/commonHelperTS';
import { useLogError } from 'utils/hooks';
import { IExpertPatent } from 'utils/interfaces/expert.interface';
import { IPatent } from 'utils/interfaces/patent.interface';

interface IPatentsOrPublications {
  items: IExpertPatent[];
  resource: number;
}

const PatentsOrPublications: FC<IPatentsOrPublications> = ({
  items,
  resource,
}) => {
  const logError = useLogError();
  const api = getPatentsPublicationsApi(resource);
  const [step, setStep] = useState(0);
  const [item, setItem] = useState<IPatent>();

  const closeDescription = () => {
    setItem(undefined);
  };

  const onSelect = (value: IExpertPatent) => {
    api
      .getById(value.id)
      .then((v: any) => {
        setItem(v.data);
        setStep(step + 1);
      })
      .catch(logError);
  };

  return (
    <div>
      {item ? (
        <Space align='start' direction='horizontal'>
          <Button
            onClick={closeDescription}
            style={{ marginRight: '8px', marginTop: '4px' }}
            type='text'
          >
            <CloseOutlined />
          </Button>
          <div style={{ marginTop: '-11px' }}>
            <PatentDescription
              data={item}
              expertResource={resource}
              keywords={[]}
            />
          </div>
        </Space>
      ) : (
        <StatisticTable data={items} onSelect={onSelect} />
      )}
    </div>
  );
};

export default PatentsOrPublications;
