import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Popconfirm,
  Radio,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { FC, ReactNode, useEffect, useState } from 'react';

import searchNotificationsApi from 'services/searchNotificationsApi';
import searchActions from 'store/actions/searchActions';
import { useAppDispatch, useAppSelector } from 'store/selectors/useAppSelector';
import { INotification } from 'utils/interfaces/notification.interface';

interface Props {
  children: ReactNode;
  dataIndex: any;
  editing: boolean;
  index: number;
  record: any;
  title: string;
}

const EditableCell: FC<Props> = ({
  children,
  dataIndex,
  editing,
  index,
  record,
  title,
  ...restProps
}) => {
  let inputNode = null;

  switch (dataIndex) {
    case 'frequency':
      inputNode = (
        <Radio.Group value={record.frequency}>
          <Space direction='vertical'>
            <Radio value={0}>Еженедельно</Radio>
            <Radio value={1}>Ежемесячно</Radio>
          </Space>
        </Radio.Group>
      );
      break;
    case 'isActive':
      inputNode = (
        <Switch checkedChildren='Активно' unCheckedChildren='Неактивно' />
      );
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={dataIndex === 'isActive' ? 'checked' : 'value'}
          rules={[
            {
              message: `Введите ${title}!`,
              required: true,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SearchNotifications = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const originData = useAppSelector((s) => s.search.notifications);
  const loading = useAppSelector((s) => s.search.notificationsLoading);
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState<number | string>('');
  const isEditing = (record: INotification) => record.id === editingKey;

  useEffect(() => {
    dispatch(searchActions.notifications.load());
  }, []);

  useEffect(() => {
    setData(originData.map((item) => ({ ...item, key: item.id })));
  }, [originData]);

  const edit = (record: INotification) => {
    form.setFieldsValue({
      ...record,
      frequency: '',
      isActive: '',
      keyWords: '',
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: number) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      const item = newData[index];
      const res = await searchNotificationsApi.editNotification({
        ...row,
        id: item.id,
      });

      newData.splice(index, 1, { ...item, ...row });
      setData(newData);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const del = async (id: number) => {
    try {
      const newData = data.filter((item) => item.id !== id);
      const res = await searchNotificationsApi.deleteNotification(id);

      setData(newData);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Delete Failed:', errInfo);
    }
  };

  const columns = [
    {
      dataIndex: 'keyWords',
      editable: true,
      title: 'Набор ключевых слов',
      width: '30%',
    },
    {
      dataIndex: 'frequency',
      editable: true,
      render: (_: any, record: INotification) =>
        !isEditing(record) && (
          <div>{record.frequency ? 'Ежемесячно' : 'Еженедельно'}</div>
        ),
      title: 'Частота',
      width: '30%',
    },
    {
      dataIndex: 'isActive',
      editable: true,
      render: (_: any, record: INotification) =>
        !isEditing(record) && (
          <div>{record.isActive ? 'Активно' : 'Неактивно'}</div>
        ),
      title: 'Статус',
      width: '20%',
    },
    {
      align: 'center' as const,
      dataIndex: 'operation',
      render: (_: any, record: INotification) => {
        const editable = isEditing(record);

        return editable ? (
          <Space align='start' direction='vertical'>
            <Typography.Link onClick={() => save(record.id)}>
              Сохранить
            </Typography.Link>
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                cancel();
              }}
            >
              Закрыть
            </Typography.Link>
          </Space>
        ) : (
          <Space size='middle'>
            <Typography.Link
              disabled={editingKey !== ''}
              title='Изменить'
              type='warning'
              onClick={(e) => {
                e.stopPropagation();
                edit(record);
              }}
            >
              <EditOutlined />
            </Typography.Link>
            <Popconfirm onConfirm={() => del(record.id)} title='Удалить?'>
              <Typography.Link
                disabled={editingKey !== ''}
                title='Удалить'
                type='danger'
              >
                <DeleteOutlined />
              </Typography.Link>
            </Popconfirm>
          </Space>
        );
      },
      title: 'Действия',
      width: '20%',
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: INotification) => ({
        dataIndex: col.dataIndex,
        editing: isEditing(record),
        record,
        title: col.title,
      }),
    };
  });

  return (
    <Form component={false} form={form}>
      <Table
        columns={mergedColumns}
        dataSource={data}
        loading={loading}
        pagination={false}
        rowClassName='editable-row'
        size='small'
        style={{ marginBottom: '15px' }}
        bordered
        components={{
          body: {
            cell: EditableCell,
          },
        }}
      />
      <div style={{ color: '#707070' }}>
        Для создания нового оповещения о поиске необходимо выполнить следующее:
        <br />
        1) Выполните новый поиск.
        <br />
        2) Щелкните на странице результатов ссылку «Настроить оповещение»
      </div>
    </Form>
  );
};

export default SearchNotifications;
