import dayjs from 'dayjs';

/**
 * Общие вспомогательные функции
 * @module utils/helpers/commonHelper
 */

/**
 * Проверка строки на пустоту
 * @param {String} text - Проверяема строка
 * @return {Boolean} true - пустрое значение; false - не пустое
 */
export const isEmpty = (text) =>
  typeof text === 'undefined' ||
  text === null ||
  text === 'null' ||
  String(text).trim() === '';

/**
 * Проверка строки на наличие содержимого
 * @param {String} text - Проверяема строка
 */
export const isNotEmpty = (text) =>
  typeof text !== 'undefined' &&
  text !== null &&
  text !== 'null' &&
  String(text).trim() !== '';

/**
 * Проверка объекта на пустоту
 * @param {Object} value - Проверяемый объект
 */
export const objectIsEmpty = (value) =>
  !value || value.constructor !== Object || Object.keys(value).length === 0;

/**
 * Проверка объекта на наличие содержимого
 * @param {Object | null} value - Проверяемый объект
 */
export const objectIsNotEmpty = (value) =>
  value && value.constructor === Object && Object.keys(value).length !== 0;

/**
 * Усечения числа
 * @param {Number} num - Исходное число
 * @param {Number} decimalPlaces - Количество цифр после запятой
 */
export const trunc = (num, decimalPlaces) => {
  var numPowerConverter = Math.pow(10, decimalPlaces);
  return ~~(num * numPowerConverter) / numPowerConverter;
};

/**
 *  Проверка значения на число
 * @param {*} value - Исходное значение
 */
export const isInt = (value) =>
  !isNaN(value) &&
  (function (x) {
    return (x | 0) === x;
  })(parseFloat(value));

/**
 * Возвращает сгенерированный GUID
 */
export const generateGuid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

/**
 * Возвращает эмоджи флага страны
 * @param {String} countryCode Код страны
 * @returns {String} Эмоджи флага страны
 */
export const getCountryFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

/**
 * Получение query URL по входным параметрам
 * @param {*} params Входные параметры
 */
export const getQueryByParams = (params) => {
  let result = '';
  const setParam = (key, text) =>
    (result += `${getAmpersand(result)}${key}=${text}`);

  for (const key in params) {
    const element = params[key];

    switch (typeof element) {
      case 'string':
        if (!element) continue;
        setParam(key, element.replaceAll('|', '%7C'));
        break;
      case 'number':
      case 'boolean':
        setParam(key, element.toString());
        break;
      case 'object':
        switch (element.constructor.name) {
          case 'Array':
            if (!element.length) continue;
            result += getAmpersand(result) + getQueryArray(key, element);
            break;
          case 'Set':
            if (!element.size) continue;
            result += getAmpersand(result) + getQueryArray(key, [...element]);
            break;
          case 'Date':
            setParam(key, element.toISOString());
            break;
          default:
            if (element?.$d) {
              setParam(key, element.$d.toISOString());
            } else {
              continue;
            }
        }

        break;
      default:
        continue;
    }
  }

  if (result.length > 0) return `?${result}`;

  return result;
};

const getAmpersand = (str) => (str.length > 1 ? '&' : '');

const getQueryArray = (key, arr = []) => {
  const lastIdx = arr.length - 1;
  return arr.reduce(
    (prev, cur, idx) => `${prev}${key}=${cur}${idx === lastIdx ? '' : '&'}`,
    ''
  );
};

/**
 * Получение параметров из query URL
 * @param {String} search query URL
 * @param {Any} paramsTypes Типы данных параметров
 */
export const getParamsByQuery = (search, paramsTypes) => {
  if (search.length < 3) return {};

  return decodeURI(search)
    .slice(1)
    .split('&')
    .reduce((prev, cur) => {
      const item = cur.split('=');
      const key = item[0];

      if (item.length === 2 && paramsTypes.hasOwnProperty(key)) {
        let value = null;

        switch (paramsTypes[key]?.toLowerCase()) {
          case 'string':
            value = item[1];
            break;
          case 'number':
          case 'boolean':
            value = JSON.parse(item[1]);
            break;
          case 'array':
            if (prev.hasOwnProperty(key)) {
              value = prev[key];
            } else {
              value = [];
            }
            value.push(item[1]);
            break;
          case 'set':
            if (prev.hasOwnProperty(key)) {
              value = prev[key];
            } else {
              value = new Set();
            }
            value.add(item[1]);
            break;
          case 'date':
            value = dayjs(item[1])._d;
            break;
          case 'dayjs':
            value = dayjs(item[1]);
            break;
          default:
            break;
        }
        prev[key] = value;
      }

      return prev;
    }, {});
};

/**
 * Обрезание строки с добавлением троеточия при превышении макс. допустимой длины
 * @param {String} text Текст
 * @param {Number} maxLength Макс. допустимая длина
 */
export const ellipsisText = (text, maxLength) =>
  isNotEmpty(text)
    ? text.substr(0, maxLength).concat(text.length > maxLength ? '...' : '')
    : '';

/**
 *  Мемоизация результата
 * @param {Function} fn Функция возвращающая результат
 */
export const memoize = (fn) => {
  let cache = {};

  return (...args) => {
    let n = args[0];
    if (n in cache) {
      return cache[n];
    } else {
      let result = fn(n);
      cache[n] = result;
      return result;
    }
  };
};

/**
 *  Получение элемента из массива объектов с макс. значением поля
 * @param {Array} data Массив
 * @param {String} fields Поле, по которому ищем макс. значение
 * @return {any} Элемент массива
 */
export const getMaxByObjItem = (data = [], fields = '') =>
  data.reduce((prev, current) =>
    prev[fields] > current[fields] ? prev : current
  );

/**
 * Получение текущего ресурса из url
 * @return {Number} 0 - патенты, 1 - публикации
 */
export const getResourceFromUrl = () => {
  // не работает
  const urlParams = new URLSearchParams(window.location.href);

  return +urlParams.get('resource');
};

/**
 * Склонение слова.
 * Пример использования: getDeclension(12, ['результат', 'результата', 'результатов']) вернет 'результатов'
 * @param {Number} number - число
 * @param {Array} titles - массив слов для склонения
 * @return {String} - подходящее слово для числа
 */
export const getDeclension = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

/**
 * Получить массив слов из текста на кириллице или латинице
 * @param {String} string - текст
 * @return {Array} - массив слов из текста
 */
export const getArrayOfWords = (string) => {
  return string ? string.match(/[-\*А-яЁёA-z0-9]+/g) : [];
};

/**
 * Получить массив элементов имеющих уникальный id
 * @param {Array} arr
 * @returns {Array}
 */
export const getUniqueElementsById = (arr) => {
  const arrOfId = arr.map((r) => r.id);
  const uniqueIds = Array.from(new Set(arrOfId));

  return arr.filter((r) => {
    const i = uniqueIds.indexOf(r.id);

    if (i >= 0) {
      uniqueIds.splice(i, 1);

      return true;
    } else {
      return false;
    }
  });
};
