const typeTemplate = 'Значение не является допустимым';

export default {
  array: {
    len: 'Должно быть ${len} шт.',
    max: 'Не может быть больше ${max} шт.',
    min: 'Не может быть меньше ${min} шт.',
    range: 'Кол-во должно быть от ${min} и до ${max}',
  },
  date: {
    format: 'Значение недействительно для формата даты',
    invalid: 'Значение содержит не действительную дату',
    parse: 'Значение не может быть обработано как дата',
  },
  default: "Ошибка проверки в поле '$ {label}'",
  enum: 'Значение не должно быть одним из [${enum}]',
  number: {
    len: 'Значение должено равняться ${len}',
    max: 'Значение не может быть больше ${max}',
    min: 'Значение не может быть меньше ${min}',
    range: 'Значение должно быть от ${min} и до ${max}',
  },
  pattern: {
    mismatch: 'Значение не соответствует шаблону ${pattern}',
  },
  required: 'Поле является обязательным',
  string: {
    len: 'Длина должна равняться ${len}',
    max: 'Длина не может быть больше ${max}',
    min: 'Длина не может быть меньше ${min}',
    range: 'Длина должна быть от ${min} и до ${max}',
  },
  types: {
    array: typeTemplate,
    boolean: typeTemplate,
    date: typeTemplate,
    email: typeTemplate,
    float: typeTemplate,
    hex: typeTemplate,
    integer: typeTemplate,
    method: typeTemplate,
    number: typeTemplate,
    object: typeTemplate,
    regexp: typeTemplate,
    string: typeTemplate,
    url: typeTemplate,
  },
  whitespace: 'Значение не может быть пустым',
};
