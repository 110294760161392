import { useRef, useState } from 'react';

export interface IUpdateValue {
  off: () => void;
  on: () => void;
  toggle: () => void;
}

/**
 * Манипуляция булевым значением
 * @param initialValue Первоначальное состояние значения
 * @description Hook
 */
const useBoolean = (initialValue: boolean): [boolean, IUpdateValue] => {
  const [value, setValue] = useState(initialValue);
  const updateValue = useRef({
    off: () => setValue(false),
    on: () => setValue(true),
    toggle: () => setValue((v) => !v),
  });

  return [value, updateValue.current];
};

export default useBoolean;
