import { Button, Col, Result, Row } from 'antd';

import styles from 'pages/GlobalErrorPage/index.module.css';

const GlobalErrorPage = () => {
  return (
    <Row align='middle' className={styles.errorPageContainer} justify='center'>
      <Col>
        <Result
          className='non-selectable'
          status='500'
          title='Ошибка в работе приложения'
        />
        <Row justify='center'>
          <a href='/'>
            <Button className={styles.errorPageNavigateButton}>Поиск</Button>
          </a>
        </Row>
      </Col>
    </Row>
  );
};

export default GlobalErrorPage;
