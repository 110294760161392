import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { API } from 'utils/helpers/api';
import { getQueryByParams } from 'utils/helpers/commonHelper';
import {
  IExternalNews,
  IExternalNewsTopic,
} from 'utils/interfaces/externalNews.interface';
import { IPaginationResponse } from 'utils/interfaces/pagination.interface';

const SERVICE_NAME = 'external-news';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

/**
 * Получение списка внешних новостей
 * @param {Object} params - параметры
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getAll = (params: any, cancelToken: any = null) =>
  API.get<IPaginationResponse<IExternalNews>>(
    `${getUrlPart()}${getQueryByParams({
      ...params,
      keywords: undefined,
      searchText: params.keywords,
    })}`,
    { cancelToken }
  );

/**
 * Получение списка внешних новостей
 * @param {Object} params - параметры
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getFiltered = (params: any, cancelToken: any = null) =>
  API.get<IPaginationResponse<IExternalNews>>(
    `${getUrlPart()}${getQueryByParams({
      ...params,
      searchText: params.keywords,
      keywords: undefined,
      topicIds: params.topicIds,
      startDate: params.dtBeg || undefined,
      endDate: params.dtEnd || undefined,
      dtBeg: undefined,
      dtEnd: undefined,
    })}`,
    { cancelToken }
  );

/**
 * Получение списка тем внешних новостей
 * @param {Object} params - параметры
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getFilteredTopics = (params: any, cancelToken: any = null) =>
  API.get<IExternalNewsTopic[]>(
    `${getUrlPart()}/all-topics${getQueryByParams({
      ...params,
      searchText: params.keywords,
      keywords: undefined,
      startDate: params.dtBeg || undefined,
      endDate: params.dtEnd || undefined,
      dtBeg: undefined,
      dtEnd: undefined,
    })}`,
    {
      cancelToken,
    }
  );

export default {
  getAll,
  getFiltered,
  getFilteredTopics,
};
