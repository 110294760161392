import { DatePicker, Form } from 'antd';

import 'components/searchPanel/searchForm/inputs/index.css';

const DtRange = () => (
  <Form.Item name='dt' rules={[{ type: 'array' }]}>
    <DatePicker.RangePicker className='search-form-field' format='DD.MM.YYYY' />
  </Form.Item>
);

export default DtRange;
