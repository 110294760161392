import { Button, Popover, theme } from 'antd';
import { FC, useState } from 'react';

import { BookmarkOutlined } from 'components/icons';
import SearchNotificationsSettings from 'components/searchPanel/searchNotifications/searchNotificationsSettings';
import { isEmpty } from 'utils/helpers/commonHelper';

interface Props {
  searchText: string;
}

const SearchNotificationsSettingsButton: FC<Props> = ({ searchText }) => {
  const { token } = theme.useToken();

  const [isPopoverVisible, updateIsPopoverVisible] = useState(false);

  const onSuccess = () => {
    updateIsPopoverVisible(false);
  };

  const onOpenChange = (open: boolean) => {
    updateIsPopoverVisible(open);
  };

  if (isEmpty(searchText)) return null;

  return (
    <Popover
      content={<SearchNotificationsSettings onSuccess={onSuccess} />}
      destroyTooltipOnHide={true}
      onOpenChange={onOpenChange}
      open={isPopoverVisible}
      overlayStyle={{ maxWidth: '640px' }}
      placement='bottomLeft'
      title={<h2>Настройка оповещения</h2>}
      trigger='click'
    >
      <Button
        icon={<BookmarkOutlined />}
        style={{ background: token.colorFillTertiary }}
        type='text'
      />
    </Popover>
  );
};

export default SearchNotificationsSettingsButton;
