import { useEffect, useLayoutEffect } from 'react';

/**
 * Помогает решить проблему отсутсвия useLayoutEffect на сервере
 * @description Hook
 */
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export default useIsomorphicLayoutEffect;

// Использование

// export const Component = () => {
//   useIsomorphicLayoutEffect(() => {
//     console.log(
//       "In the browser, I'm an `useLayoutEffect`, but in SSR, I'm an `useEffect`.",
//     )
//   }, [])
//
//   return <p>Hello, world</p>
// }
