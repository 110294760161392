import { Divider, Spin } from 'antd';

import BasicInfo from 'components/expertsPageComponents/comparison/basicInfo';
import SearchCompaniesForm from 'components/expertsPageComponents/comparison/searchCompaniesForm';
import Top30Authors from 'components/expertsPageComponents/comparison/top30Authors';
import 'components/expertsPageComponents/styles.scss';

import { FC, useState } from 'react';

import { useLazyGetAuthorsCompaniesComparisonQuery } from 'services/authorsRTKQApi';
import patentsApi from 'services/patentsApi';
import publicationsApi from 'services/publicationsApi';
import { useBoolean, useLogError, useUrlQuery } from 'utils/hooks';
import {
  IComparisonOfCompanies,
  IComparisonOfCompaniesData,
} from 'utils/interfaces/comparisonOfCompanies.interface';

const companyInitial = {
  competences: { results: [] },
  patentsAmount: 0,
  publicationsAmount: 0,
  top: [],
  unique: 0,
};
const companiesInitial = { c1: companyInitial, c2: companyInitial };

const ComparisonOfCompanies: FC = () => {
  const logError = useLogError();
  const [companiesData, setCompaniesData] =
    useState<IComparisonOfCompaniesData>(companiesInitial);
  const [loading, updateLoading] = useBoolean(false);
  const [urlParams, setUrlParams] = useUrlQuery({
    c1: 'string',
    c2: 'string',
    tab: 'string',
  });

  const [getAuthorsCompaniesComparison] =
    useLazyGetAuthorsCompaniesComparisonQuery();

  const searchCompanies = ({ c1, c2 }: IComparisonOfCompanies) => {
    updateLoading.on();
    Promise.allSettled([
      getAuthorsCompaniesComparison({ c1, c2 }).unwrap(),
      patentsApi.searchCompanies({ c1, c2 }),
      publicationsApi.searchCompanies({ c1, c2 }),
    ])
      .then((res) => {
        setCompaniesData({
          c1: {
            competences:
              res[0].status === 'fulfilled'
                ? res[0].value.c1.competences
                : { results: [] },
            patentsAmount:
              res[1].status === 'fulfilled' ? res[1].value.c1.patentsAmount : 0,
            publicationsAmount:
              res[2].status === 'fulfilled'
                ? res[2].value.c1.publicationsAmount
                : 0,
            top: res[0].status === 'fulfilled' ? res[0].value.c1.top : [],
            unique: res[0].status === 'fulfilled' ? res[0].value.c1.unique : 0,
          },
          c2: {
            competences:
              res[0].status === 'fulfilled'
                ? res[0].value.c2.competences
                : { results: [] },
            patentsAmount:
              res[1].status === 'fulfilled' ? res[1].value.c2.patentsAmount : 0,
            publicationsAmount:
              res[2].status === 'fulfilled'
                ? res[2].value.c2.publicationsAmount
                : 0,
            top: res[0].status === 'fulfilled' ? res[0].value.c2.top : [],
            unique: res[0].status === 'fulfilled' ? res[0].value.c2.unique : 0,
          },
        });
        setUrlParams({ ...urlParams, c1, c2 });
      })
      .catch(logError)
      .finally(updateLoading.off);
  };

  return (
    <div style={{ marginTop: '24px' }}>
      <SearchCompaniesForm
        loading={loading}
        onSubmit={searchCompanies}
        initialValues={{
          c1: urlParams.c1,
          c2: urlParams.c2,
        }}
      />

      <Spin
        size='large'
        spinning={loading}
        style={{ minHeight: '200px' }}
        tip='Загрузка...'
      >
        <Divider>Основная информация</Divider>
        <BasicInfo companies={companiesData} />
        <Divider>Топ-30 авторов компании</Divider>
        <Top30Authors companies={companiesData} />
      </Spin>
    </div>
  );
};

export default ComparisonOfCompanies;
