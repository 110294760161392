import { PRIORITY_DIRECTIONS } from 'utils/constants/actionTypes';

const initialState = {
  data: [],
  loading: false,
};

const priorityDirectionsReducer = (
  state = initialState,
  { payload, type }: any
) => {
  switch (type) {
    case PRIORITY_DIRECTIONS.SET_DATA:
      return { ...state, data: payload };
    case PRIORITY_DIRECTIONS.LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};

export default priorityDirectionsReducer;
