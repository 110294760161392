import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

import { IComparisonOfCompaniesCompanyData } from 'utils/interfaces/comparisonOfCompanies.interface';

const getPrefixAndStyle = (condition: boolean) => {
  return {
    prefix: condition ? <ArrowUpOutlined /> : <ArrowDownOutlined />,
    valueStyle: {
      color: condition ? '#3f8600' : '#cf1322',
    },
  };
};

interface ICompetenciesInfo {
  company: IComparisonOfCompaniesCompanyData;
  otherCompany: IComparisonOfCompaniesCompanyData;
}

const CompetenciesInfo: FC<ICompetenciesInfo> = ({ company, otherCompany }) => {
  const [uniq, total] = useMemo(() => {
    return [
      getPrefixAndStyle(company.unique >= otherCompany.unique),
      getPrefixAndStyle(
        !!company.competences.total &&
          !!otherCompany.competences.total &&
          company.competences.total >= otherCompany.competences.total
      ),
    ];
  }, [company, otherCompany]);

  return (
    <Row gutter={[16, 30]}>
      <Col lg={24} xl={8} xs={24}>
        <Statistic
          title='Пик развития компетенций:'
          value={dayjs(company.competences.results[0].date).format(
            'DD.MM.YYYY'
          )}
        />
      </Col>
      <Col lg={12} xl={8} xs={24}>
        <Statistic
          groupSeparator=' '
          prefix={uniq.prefix}
          title='Количество уникальных компетенций:'
          value={company.unique}
          valueStyle={uniq.valueStyle}
        />
      </Col>
      <Col lg={12} xl={8} xs={24}>
        <Statistic
          groupSeparator=' '
          prefix={total.prefix}
          title='Суммарное кол-во освоенных компетенций:'
          value={company.competences.total}
          valueStyle={total.valueStyle}
        />
      </Col>
    </Row>
  );
};

export default CompetenciesInfo;
