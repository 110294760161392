import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';

import security from 'services/security';
import appActions from 'store/actions/appActions';
import {
  API_CONFIG,
  API_CONFIG_WITH_NO_CACHE,
  API_URL,
} from 'utils/constants/apiConfig';
import { IErrorResponse } from 'utils/interfaces/errorResponse.interface';

/**
 * Объект для работы с HTTP-запросов, которые могут кэшироваться
 */
export const API = axios.create(API_CONFIG);

/**
 * Объект для работы с HTTP-запросов, которые не могут кэшироваться
 */
export const API_NO_CACHE = axios.create(API_CONFIG_WITH_NO_CACHE);

/**
 * Обрабатывает ошибку и возвращает сообщение с информацией о возникшей проблеме
 * @param exception Ошибка
 * @returns Сообщение
 */
export const ApiErrorHandler = (
  exception: AxiosError<IErrorResponse> | { response: { status: number } }
) => {
  if (!exception) return 'Возникла проблема с отправкой запроса к API';

  switch (exception.response?.status) {
    case 400:
      return 'Не верный формат ввода';
    case 401:
      return 'Не авторизованы';
    case 403:
      return 'Отсутствуют права доступа';
    case 404:
      return 'По данному запросу ничего не найдено';
    case 500:
      return 'Ошибка в работе API';
    default:
      return 'Возникла непредвиденная ошибка в работе приложения';
  }
};

/**
 * Получение CancelTokenSource
 * @return Объект с токеном и методом для прерывания
 */
export const GetCancelTokenSource = () => axios.CancelToken.source();

export const configure = () => {
  // @ts-ignore
  API.interceptors.request.use((config) => {
    if (security.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${security.getToken()}`;
        return Promise.resolve(config);
      };
      return security.updateToken(cb);
    }
  });
  // @ts-ignore
  API_NO_CACHE.interceptors.request.use((config) => {
    if (security.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${security.getToken()}`;
        return Promise.resolve(config);
      };
      return security.updateToken(cb);
    }
  });
};

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: async (headers) => {
      if (security.isLoggedIn()) {
        const cb = () => {
          headers.set('Authorization', `Bearer ${security.getToken()}`);
          return Promise.resolve(headers);
        };
        await security.updateToken(cb);
      }

      return headers;
    },
  });

  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    api.dispatch(
      appActions.errorNotificationByApiExc(
        result.meta as { response: { status: number } }
      )
    );
  }

  return result;
};

export const tagTypes = {
  profile: 'profile',
  searchCategories: 'searchCategories',
  chemicalComposition: 'chemicalComposition',
  authors: 'authors',
  analytics: 'analytics',
  expertData: 'expertData',
  companiesComparison: 'companiesComparison',
  favoriteExperts: 'favoriteExperts',
  communities: 'communities',
} as const;

export const baseApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: Object.values(tagTypes),
});
