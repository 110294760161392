import { AnyAction, Reducer } from '@reduxjs/toolkit';

import { TRENDS_ANALYSIS } from 'utils/constants/actionTypes';
import {
  IExternalNews,
  IExternalNewsTopic,
} from 'utils/interfaces/externalNews.interface';
import { IPaginationResponse } from 'utils/interfaces/pagination.interface';
import { IPatent } from 'utils/interfaces/patent.interface';
import { IPublication } from 'utils/interfaces/publication.interface';

const initialState = {
  companies: [],
  companiesLoading: false,
  countries: [],
  countriesLoading: false,
  externalNews: { results: [] },
  externalNewsLoading: false,
  externalNewsByTopic: { results: [] },
  externalNewsByTopicLoading: false,
  externalNewsTopics: [],
  externalNewsTopicsLoading: false,
  loadingAll: false,
  patents: { results: [] },
  patentsDirections: {},
  patentsDirectionsLoading: false,
  patentsLoading: false,
  publications: { results: [] },
  publicationsDirections: {},
  publicationsDirectionsLoading: false,
  publicationsLoading: false,
};

interface IInitialState {
  loadingAll: boolean;
  companies: any;
  companiesLoading: boolean;
  countries: any;
  countriesLoading: boolean;
  patents: IPaginationResponse<IPatent>;
  patentsLoading: boolean;
  publications: IPaginationResponse<IPublication>;
  publicationsLoading: boolean;
  patentsDirectionsLoading: boolean;
  patentsDirections: Record<string, { cnt: number; value: string }[]>;
  publicationsDirections: Record<string, { cnt: number; value: string }[]>;
  publicationsDirectionsLoading: boolean;
  externalNews: IPaginationResponse<IExternalNews>;
  externalNewsLoading: boolean;
  externalNewsByTopic: IPaginationResponse<IExternalNews>;
  externalNewsByTopicLoading: boolean;
  externalNewsTopics: IExternalNewsTopic[];
  externalNewsTopicsLoading: boolean;
}

const trendsAnalysisReducer: Reducer<IInitialState> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case TRENDS_ANALYSIS.LOADING_ALL:
      return { ...state, loadingAll: action.payload };
    case TRENDS_ANALYSIS.RESET_ALL:
      return { ...initialState };

    case TRENDS_ANALYSIS.PATENTS_SET_DATA:
      return { ...state, patents: action.payload };
    case TRENDS_ANALYSIS.PATENTS_LOADING:
      return { ...state, patentsLoading: action.payload };

    case TRENDS_ANALYSIS.PUBLICATIONS_SET_DATA:
      return { ...state, publications: action.payload };
    case TRENDS_ANALYSIS.PUBLICATIONS_LOADING:
      return { ...state, publicationsLoading: action.payload };

    case TRENDS_ANALYSIS.PATENTS_DIRECTIONS_SET_DATA:
      return { ...state, patentsDirections: action.payload };
    case TRENDS_ANALYSIS.PATENTS_DIRECTIONS_LOADING:
      return { ...state, patentsDirectionsLoading: action.payload };

    case TRENDS_ANALYSIS.PUBLICATIONS_DIRECTIONS_SET_DATA:
      return { ...state, publicationsDirections: action.payload };
    case TRENDS_ANALYSIS.PUBLICATIONS_DIRECTIONS_LOADING:
      return { ...state, publicationsDirectionsLoading: action.payload };

    case TRENDS_ANALYSIS.COMPANIES_SET_DATA:
      return { ...state, companies: action.payload };
    case TRENDS_ANALYSIS.COMPANIES_LOADING:
      return { ...state, companiesLoading: action.payload };

    case TRENDS_ANALYSIS.COUNTRIES_SET_DATA:
      return { ...state, countries: action.payload };
    case TRENDS_ANALYSIS.COUNTRIES_LOADING:
      return { ...state, countriesLoading: action.payload };

    case TRENDS_ANALYSIS.EXTERNAL_NEWS_SET_DATA:
      return { ...state, externalNews: action.payload };
    case TRENDS_ANALYSIS.EXTERNAL_NEWS_LOADING:
      return { ...state, externalNewsLoading: action.payload };

    case TRENDS_ANALYSIS.EXTERNAL_NEWS_BY_TOPIC_SET_DATA:
      return { ...state, externalNewsByTopic: action.payload };
    case TRENDS_ANALYSIS.EXTERNAL_NEWS_BY_TOPIC_LOADING:
      return { ...state, externalNewsByTopicLoading: action.payload };

    case TRENDS_ANALYSIS.EXTERNAL_NEWS_TOPICS_SET_DATA:
      return { ...state, externalNewsTopics: action.payload };
    case TRENDS_ANALYSIS.EXTERNAL_NEWS_TOPICS_LOADING:
      return { ...state, externalNewsTopicsLoading: action.payload };

    default:
      return state;
  }
};

export default trendsAnalysisReducer;
