import {
  AppstoreOutlined,
  CalendarOutlined,
  ClusterOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Divider, Grid, Space, Tabs, Typography } from 'antd';

import ListTools from 'components/floatButtons/helpFloatButton/listTools';
import 'components/floatButtons/helpFloatButton/styles.scss';
import { ADMIN_EMAIL, COMPANY_NAME } from 'utils/constants/appConfig';

const { useBreakpoint } = Grid;

const HelpModalContent = () => {
  const screens = useBreakpoint();
  const { Paragraph } = Typography;
  const items = [
    {
      children: (
        <>
          <h2>Куда я попал, что такое {COMPANY_NAME} и как в ней работать?</h2>
          <Divider />
          <Typography>
            <Paragraph>Привет!</Paragraph>
            <Paragraph>
              {COMPANY_NAME} - это поисково-аналитическая система, которая
              объединяет в себе большое количество верифицированных научных
              источников информации (новости, патенты, НТИ), а также имеет в
              себе большой набор инструментов для работы с информацией -
              интеллектуальная поисковая система, уникальный отраслевой
              поисковый движок, система аналитики, подсказок и дэшбордирования.
            </Paragraph>
            <Paragraph>
              Для начала, Вам нужно сформировать поисковый запрос и заполнить
              обязательные поля.
              <br />
              Для этого:
            </Paragraph>
            <Paragraph>
              1) Выберите, по какой части источника информации Вы будете искать
              - по <span className='mark'>содержимому</span> или{' '}
              <span className='mark'>заголовку</span>.
            </Paragraph>
            <Paragraph>
              2) Введите ключевые слова - к примеру,{' '}
              <span className='mark'>magnetic steel</span> или длинный запрос с
              поисковыми операторами{' '}
              <span className='mark'>
                detection corrosion on steel | rust detection system
              </span>
              .
              <br />
              <span className='mark'>ОБРАТИТЕ ВНИМАНИЕ</span>: ключевые слова
              формируются на английском языке!
            </Paragraph>
            <Paragraph>
              3) Выберите диапазон дат, который должен входить в поисковый
              запрос -{' '}
              <span className='mark'>
                для этого нажмите на окно ввода дат, выберите дату начала и дату
                окончания
              </span>
              .
            </Paragraph>
            <Paragraph>
              4) Выберите направление{' '}
              <span className='mark'>(предметную область)</span> в рамках
              которой необходимо выполнить поиск
            </Paragraph>
            <Paragraph>
              5) Нажмите кнопку <span className='mark'>“Поиск”</span>.
            </Paragraph>
            <Paragraph>
              После ввода поискового запроса, сформируется аналитическая выборка
              в формате BI-аналитики с которой можно взаимодействовать. Также в
              меню взаимодействия можно выбирать инструменты анализа информации.
              При переходе на другие инструменты, поисковый запрос сохраняется и
              повторно заполнять поля не нужно.
              <br />
              Слева, в данном меню помощи, Вы можете ознакомиться с другой
              важной информацией по системе, например с функцией поисковых
              операторов.
            </Paragraph>
          </Typography>
        </>
      ),
      key: 'item-1',
      label: (
        <span>
          <MailOutlined /> {COMPANY_NAME} - что это?
        </span>
      ),
    },
    {
      children: (
        <>
          <h2>Доступные модули в {COMPANY_NAME}</h2>
          <Divider />
          <Typography>
            <Paragraph>
              В системе доступно пять модулей поиска информации:
            </Paragraph>
            <ListTools />
            <Paragraph>
              Все системы поддерживают динамический переход - сохранение
              параметров поискового запроса и автоматическое выполнение{' '}
              <span className='mark'>при переходе между системами</span>.
            </Paragraph>
          </Typography>
        </>
      ),
      key: 'item-2',
      label: (
        <span>
          <ClusterOutlined /> Доступные модули
        </span>
      ),
    },
    {
      children: (
        <>
          <h2>Поисковые операторы</h2>
          <Divider />
          <Typography>
            <Paragraph>
              Раз Вы уже ознакомились, что такое {COMPANY_NAME}, то полезно
              знать и о поисковых операторах, которые существуют в поисковой
              системе.
              <br />
              Сейчас их всего 3, более подробно о них расскажем ниже:
            </Paragraph>
            <Paragraph>
              1) <span className='mark'>Оператор “ * ”</span> - логический
              оператор "И", задает список выражений, которые должны учитываться
              в совокупности в найденном документе при расчете релевантности:
              <br />
              &lt;выражение1&gt;*&lt;выражение2&gt;|...
            </Paragraph>
            <Paragraph>Например: white corrosion * rust</Paragraph>
            <Paragraph>
              2) <span className='mark'>Оператор “ | ”</span> - логический
              оператор "ИЛИ", задает список выражений — синонимов, которые
              должны одинаково учитываться при расчете релевантности:
              <br />
              &lt;выражение1&gt;|&lt;выражение2&gt;|...
            </Paragraph>
            <Paragraph>Например: white corrosion | rust</Paragraph>
            <Paragraph>
              3) <span className='mark'>Оператор “ - “</span> - данный оператор
              используется для определения выражений, которые не должны
              встречаться в найденном документе.
            </Paragraph>
            <Paragraph>
              Например: white corrosion -rust (это означает, что запрос долже
              искать все про “white corrosion”, но исключить в поиске слово
              “rust”)
            </Paragraph>
            <Paragraph>
              Все эти операторы доступны при формировании запроса в поле{' '}
              <span className='mark'>ключевых слов</span>.
            </Paragraph>
          </Typography>
        </>
      ),
      key: 'item-3',
      label: (
        <span>
          <CalendarOutlined /> Поисковые операторы
        </span>
      ),
    },
    {
      children: (
        <>
          <h2>Связаться с нами</h2>
          <Divider />
          <Typography>
            <Paragraph>
              Если у Вас появились предложения по улучшению работы сервиса или
              нашли ошибки в работе системы, то просим обращаться Вас к
              следующим сотрудникам:
            </Paragraph>
            <Paragraph>
              1) <span className='mark'>Веденеев Виктор Алексеевич</span> -
              держатель решения, разработчик.
              <br />
              {ADMIN_EMAIL}
              <br />
              +7(964)673-06-88
            </Paragraph>
            <Paragraph>
              2) <span className='mark'>Маевский Антон Витальевич</span> -
              продвижение и развитие проекта.
              <br />
              a.maevskii@severstal.com
              <br />
              +7(911)792-72-21
            </Paragraph>
          </Typography>
        </>
      ),
      key: 'item-4',
      label: (
        <span>
          <AppstoreOutlined /> Связаться с нами
        </span>
      ),
    },
  ];

  return (
    <div className='help-content'>
      <Tabs
        items={items}
        tabPosition={screens.md ? 'left' : 'top'}
        tabBarExtraContent={{
          left: screens.md && (
            <h2 style={{ marginBottom: '40px' }}>Центр помощи</h2>
          ),
        }}
      />
    </div>
  );
};

export default HelpModalContent;
