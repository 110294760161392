import { Typography } from 'antd';
import { FC } from 'react';
import Highlighter from 'react-highlight-words';

import { getRegexForHighlightKeywords } from 'utils/helpers/keywordsHelper';

interface IMarkKeywordsInText {
  cloudTag?: string;
  keywords: string[];
  text: string;
}

const MarkKeywordsInText: FC<IMarkKeywordsInText> = ({
  cloudTag = null,
  keywords,
  text,
}) => {
  const keywordsWithCloudTag = cloudTag ? [...keywords, cloudTag] : keywords;

  return (
    <Highlighter
      autoEscape={false}
      // caseSensitive={true}
      searchWords={[getRegexForHighlightKeywords(keywordsWithCloudTag)]}
      textToHighlight={text || ''}
      highlightStyle={{
        backgroundColor: '#ffe9a0',
      }}
      highlightTag={(children) => {
        return children.children.toLowerCase() === cloudTag ? (
          <Typography.Text keyboard>{children.children}</Typography.Text>
        ) : (
          <mark style={children.style}>{children.children}</mark>
        );
      }}
    />
  );
};

export default MarkKeywordsInText;
