import { BellOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';

import SearchNotifications from 'components/searchPanel/searchNotifications/searchNotifications';

const SearchNotificationsButton = () => {
  return (
    <div>
      <Popover
        content={<SearchNotifications />}
        destroyTooltipOnHide={true}
        overlayStyle={{ maxWidth: '640px' }}
        placement='bottomRight'
        title={<h2>Оповещения о поиске</h2>}
        trigger='click'
      >
        <Button className='expert-button' icon={<BellOutlined />} />
      </Popover>
    </div>
  );
};

export default SearchNotificationsButton;
