import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { getParamsByQuery, getQueryByParams } from 'utils/helpers/commonHelper';

// TODO: Рассмотреть переход на useSearchParams (react-router-dom)

/**
 * Получение параметров из query URL
 * @description Hook
 */
const useUrlQuery = (paramsTypes: any, initialParamsFormatter?: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialParams = useMemo(() => {
    const initial = getParamsByQuery(location.search, paramsTypes);

    return initialParamsFormatter ? initialParamsFormatter(initial) : initial;
  }, []);

  const setParams = (params?: any) => {
    navigate(
      {
        pathname: location.pathname,
        search: getQueryByParams(params),
      },
      { replace: true }
    );
  };

  return [initialParams, setParams];
};

export default useUrlQuery;
