import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { baseApi, tagTypes } from 'utils/helpers/api';
import {
  IChemicalCombination,
  IChemicalCombinations,
  IChemicalElementsFilterResult,
} from 'utils/interfaces/chemicalElementsFilter.interface';
import { IPaginationResponse } from 'utils/interfaces/pagination.interface';

const SERVICE_NAME = 'chemical-elements';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

export const chemicalCompositionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Получить химический состав
     */
    getChemicalComposition: build.mutation<
      IPaginationResponse<IChemicalElementsFilterResult>,
      any
    >({
      invalidatesTags: [tagTypes.chemicalComposition],
      query: (body) => ({
        body,
        method: 'POST',
        url: `/${getUrlPart()}/filter`,
      }),
    }),

    /**
     * Получить количество хим. составов в системе
     */
    getTotal: build.query<{ data: number }, void>({
      providesTags: [tagTypes.chemicalComposition],
      query: () => `/${getUrlPart()}/total`,
      transformResponse: (res: number) => ({ data: res }),
    }),

    /**
     * Схожие комбинации хим. элементов
     */
    similarCombinations: build.mutation<
      IChemicalCombination[],
      IChemicalCombinations
    >({
      invalidatesTags: [tagTypes.chemicalComposition],
      query: (body) => ({
        body,
        method: 'POST',
        url: `/${getUrlPart()}/similar-combinations`,
      }),
    }),

    /**
     * Загрузка химического состава
     */
    uploadChemicalComposition: build.mutation<any, FormData>({
      query: (body) => ({
        body,
        formData: true,
        method: 'POST',
        url: `/${getUrlPart()}/load`,
      }),
    }),
  }),
});

export const {
  useGetChemicalCompositionMutation,
  useLazyGetTotalQuery,
  useSimilarCombinationsMutation,
  useUploadChemicalCompositionMutation,
} = chemicalCompositionApi;
