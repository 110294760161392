export const LAST_DATE_TYPE = {
  decade: {
    calc: () => getDates(3652),
    title: '10 лет',
    value: 5,
  },
  fiveYears: {
    calc: () => getDates(1826),
    title: '5 лет',
    value: 4,
  },
  month: {
    calc: () => getDates(30),
    title: 'Месяц',
    value: 2,
  },
  week: {
    calc: () => getDates(7),
    title: 'Неделя',
    value: 1,
  },
  year: {
    calc: () => getDates(365),
    title: 'Год',
    value: 3,
  },
};

export const LAST_DATE_TYPE_ARR = Object.values(LAST_DATE_TYPE);

const getDates = (lastDate: number) => {
  const endDt = new Date();
  const begDt = new Date();
  begDt.setDate(begDt.getDate() - lastDate);
  return { dtBeg: begDt, dtEnd: endDt };
};
