import { all, call, cancelled, put, takeLatest } from 'redux-saga/effects';

import analyticsActions from 'store/actions/analyticsActions';
import appActions from 'store/actions/appActions';
import { ANALYTICS } from 'utils/constants/actionTypes';
import { GetCancelTokenSource } from 'utils/helpers/api';
import { getPatentsPublicationsApi } from 'utils/helpers/commonHelperTS';

/**
 * Сброс всех данных
 * @description Worker
 * @param {*} action
 */
function* resetAll() {
  yield put(analyticsActions.patents.set());
  yield put(analyticsActions.publications.set());
  yield put(analyticsActions.dates.set());
  yield put(analyticsActions.authors.set());
  yield put(analyticsActions.companies.set());
  yield put(analyticsActions.directions.set());
  yield put(analyticsActions.countries.set());
}

/**
 * Загрузка всех данных
 * @description Worker
 * @param {*} action
 */
function* loadAll(action) {
  const { payload } = action;
  const resource = +payload.resource;
  const resourceName = resource ? 'Publications' : 'Patents';
  const api = getPatentsPublicationsApi(resource);

  yield all([
    call(load, resourceName.toLowerCase(), api.getFiltered, payload),
    call(load, 'dates', api[`GetChart${resourceName}ByDt`], payload),
    call(load, 'authors', api[`GetChart${resourceName}ByAuthors`], payload),
    call(load, 'companies', api.getChartByAssignees, payload),
    call(load, 'directions', api.getChartByClasses, payload),
    call(load, 'countries', api.getChartByCountries, payload),
    call(load, 'companiesByYear', api.getTrendsCompaniesByYear, payload),
    call(load, 'authorsByYear', api.getChartAuthorsByYear, payload),
  ]);
}

/**
 * Загрузка данных
 * @description Worker
 * @param {String} actionType - Тип действия
 * @param {*} method  - Метод сервиса API
 * @param {*} data - Данные передаваемые в метод сервиса API
 */
function* load(actionType, method, data) {
  const cancelSource = GetCancelTokenSource();

  try {
    yield put(analyticsActions[actionType].startLoad());

    const response = yield call(method, data, cancelSource.token);

    yield put(analyticsActions[actionType].set(response.data));
  } catch (e) {
    yield put(appActions.errorNotificationByApiExc(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }

    yield put(analyticsActions[actionType].finishLoad());
  }
}

/**
 * Мониторинг действий связанных с загрузкой данных аналитики
 * @description Watcher
 */
function* analyticsSaga() {
  yield takeLatest(ANALYTICS.LOAD_ALL, loadAll);
  yield takeLatest(ANALYTICS.RESET_ALL, resetAll);
}

export default analyticsSaga;
