import { Col, Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ExpertContacts from 'assets/expertContacts.json';
import {
  ExpertDescription,
  ExpertRelations,
  PatentsOrPublications,
} from 'components/expertPageComponents';
import {
  competenciesByCountriesInitial,
  expertInitial,
  ExpertsAnalytics,
} from 'components/expertsPageComponents';
import { LayoutWithTopMenu } from 'components/layouts';
import Loading from 'components/loading';
import {
  useGetExpertAnalyticsQuery,
  useGetExpertsAnalyticsQuery,
} from 'services/authorsRTKQApi';
import {
  PATENTS_RESOURCE,
  PUBLICATIONS_RESOURCE,
} from 'utils/constants/appConfig';
import { IExpertContact } from 'utils/interfaces/expert.interface';

const ExpertPage: FC = () => {
  const { name } = useParams();
  const [expertContact, setExpertContact] = useState<IExpertContact[]>([]);

  const {
    data: competenciesByCountries = competenciesByCountriesInitial,
    isFetching: authorsAnalyticsLoading,
  } = useGetExpertsAnalyticsQuery(
    { searchParams: { name: name } },
    {
      skip: !name?.length,
    }
  );

  const { data: expertData = expertInitial, isFetching: authorsDataLoading } =
    useGetExpertAnalyticsQuery(name!, {
      skip: !name?.length,
    });

  const { expert, patents, publications } = expertData;

  const items = [
    {
      children: (
        <Col>
          {expert && (
            <PatentsOrPublications
              items={patents}
              resource={PATENTS_RESOURCE}
            />
          )}
        </Col>
      ),
      key: '0',
      label: `Патенты  (${expert?.patentsCount})`,
    },
    {
      children: (
        <Col>
          {expert ? (
            <PatentsOrPublications
              items={publications}
              resource={PUBLICATIONS_RESOURCE}
            />
          ) : null}
        </Col>
      ),
      key: '1',
      label: `Публикации (${expert?.publicationsCount})`,
    },
  ];

  useEffect(() => {
    if (name) {
      const expertContact = ExpertContacts.experts.filter((similar) =>
        similar.nameEN.includes(name)
      );
      setExpertContact(expertContact);
    }
  }, []);

  return (
    <LayoutWithTopMenu isExpertsPage={true}>
      {authorsDataLoading ? (
        <Loading />
      ) : (
        <>
          {expert ? (
            <>
              <ExpertDescription
                expertContact={expertContact}
                expertData={expertData}
              />
              <ExpertRelations expertData={expertData} />
              <Tabs items={items} />
              <div style={{ marginTop: '32px' }}>
                <ExpertsAnalytics
                  loading={authorsAnalyticsLoading}
                  dataSource={{
                    countryClasses: {
                      data: competenciesByCountries.countryClasses,
                      title: 'Компетенции в разрезе предметных областей',
                    },
                    countryGroups: {
                      data: competenciesByCountries.countryGroups,
                      title: 'Компетенции в разрезе групп',
                    },
                    countrySections: {
                      data: competenciesByCountries.countrySections,
                      title: 'Компетенции в разрезе отраслей',
                    },
                    countrySubgroups: {
                      data: competenciesByCountries.countrySubgroups,
                      title: 'Компетенции в разрезе подгруп',
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </LayoutWithTopMenu>
  );
};

export default ExpertPage;
