import { codeToCountryList } from 'utils/constants/countries';
import {
  IExpertCountryClass,
  IExpertCountryGroup,
  IExpertCountrySection,
  IExpertCountrySubgroup,
} from 'utils/interfaces/expert.interface';

export const mapCountry = (
  data: (
    | IExpertCountryClass
    | IExpertCountryGroup
    | IExpertCountrySection
    | IExpertCountrySubgroup
  )[]
) => {
  return data.map((e) => ({
    ...e,
    country: codeToCountryList[e.country]?.name ?? e.country,
  }));
};

export const competenciesByCountriesInitial = {
  countryClasses: [],
  countryGroups: [],
  countrySections: [],
  countrySubgroups: [],
};

export const expertsInitial = {
  current: 1,
  pageSize: 10,
  results: [],
  total: 0,
  totalPages: 0,
};

export const expertInitial = {
  coauthors: [],
  companies: [],
  expert: null,
  patents: [],
  publications: [],
};
