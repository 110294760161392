import Keycloak from 'keycloak-js';

const keycloakInstance = new Keycloak({
  clientId: process.env.REACT_APP_SSO_CLIENT_ID || '',
  realm: process.env.REACT_APP_SSO_REALM || '',
  url: process.env.REACT_APP_SSO_URL || '',
});

const callLogin = (onAuthenticatedCallback: () => void) => {
  keycloakInstance
    .init({
      onLoad: 'login-required',
    })
    .then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert('non authenticated');
    })
    .catch((e) => {
      console.log(`keycloak init exception:`, e);
    });
};

const getUserFullName = () => keycloakInstance?.tokenParsed?.name ?? '';

const getUsername = () => keycloakInstance?.tokenParsed?.username ?? '';

const getUserEmail = () => keycloakInstance?.tokenParsed?.email ?? '';

const getUserRoles = () => {
  //console.log('keycloakInstance', keycloakInstance)
  return keycloakInstance?.tokenParsed?.realm_access?.roles ?? [];
};

const userHasRole = (role: string) => getUserRoles().includes(role);

const callLogout = keycloakInstance.logout;

const isLoggedIn = () => !!keycloakInstance.token;

const getToken = () => keycloakInstance.token;

const doLogin = keycloakInstance.login;

// @ts-ignore
const updateToken = (successCallback) =>
  keycloakInstance.updateToken(5).then(successCallback).catch(doLogin);

const isAdmin = () => isLoggedIn() && userHasRole('digitalip.admin');

const getAuthServerUrl = () => keycloakInstance.authServerUrl;

const getRealm = () => keycloakInstance.realm;

const securityService = {
  callLogin,
  callLogout,
  getToken,
  getUserEmail,
  getUserFullName,
  getUsername,
  getUserRoles,
  isAdmin,
  isLoggedIn,
  updateToken,
  userHasRole,
  getAuthServerUrl,
  getRealm,
};

export default securityService;
