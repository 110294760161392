import { SEARCH } from 'utils/constants/actionTypes';
import { PATENTS_RESOURCE } from 'utils/constants/appConfig';

const searchActions = {
  cloudTag: {
    set: (data = '') => ({
      payload: data,
      type: SEARCH.SET_CLOUD_TAG,
    }),
  },
  keywords: {
    set: (data = '') => ({
      payload: data,
      type: SEARCH.SET_KEYWORDS,
    }),
  },
  notifications: {
    finishLoad: () => ({
      payload: false,
      type: SEARCH.NOTIFICATIONS_LOADING,
    }),
    load: () => ({
      type: SEARCH.LOAD_NOTIFICATIONS,
    }),
    set: (data = []) => ({
      payload: data,
      type: SEARCH.SET_NOTIFICATIONS,
    }),
    startLoad: () => ({
      payload: true,
      type: SEARCH.NOTIFICATIONS_LOADING,
    }),
  },
  patentAssignees: {
    finishLoad: () => ({
      payload: false,
      type: SEARCH.PATENT_ASSIGNEES_LOADING,
    }),
    load: () => ({
      type: SEARCH.LOAD_PATENT_ASSIGNEES,
    }),
    set: (data = []) => ({
      payload: data,
      type: SEARCH.SET_PATENT_ASSIGNEES,
    }),
    startLoad: () => ({
      payload: true,
      type: SEARCH.PATENT_ASSIGNEES_LOADING,
    }),
  },
  patentAuthors: {
    finishLoad: () => ({
      payload: false,
      type: SEARCH.PATENT_AUTHORS_LOADING,
    }),
    load: () => ({
      type: SEARCH.LOAD_PATENT_AUTHORS,
    }),
    set: (data = []) => ({
      payload: data,
      type: SEARCH.SET_PATENT_AUTHORS,
    }),
    startLoad: () => ({
      payload: true,
      type: SEARCH.PATENT_AUTHORS_LOADING,
    }),
  },
  publicationAssignees: {
    finishLoad: () => ({
      payload: false,
      type: SEARCH.PUBLICATION_ASSIGNEES_LOADING,
    }),
    load: () => ({
      type: SEARCH.LOAD_PUBLICATION_ASSIGNEES,
    }),
    set: (data = []) => ({
      payload: data,
      type: SEARCH.SET_PUBLICATION_ASSIGNEES,
    }),
    startLoad: () => ({
      payload: true,
      type: SEARCH.PUBLICATION_ASSIGNEES_LOADING,
    }),
  },
  publicationAuthors: {
    finishLoad: () => ({
      payload: false,
      type: SEARCH.PUBLICATION_AUTHORS_LOADING,
    }),
    load: () => ({
      type: SEARCH.LOAD_PUBLICATION_AUTHORS,
    }),
    set: (data = []) => ({
      payload: data,
      type: SEARCH.SET_PUBLICATION_AUTHORS,
    }),
    startLoad: () => ({
      payload: true,
      type: SEARCH.PUBLICATION_AUTHORS_LOADING,
    }),
  },
  resource: {
    set: (data = PATENTS_RESOURCE) => ({
      payload: data,
      type: SEARCH.SET_RESOURCE,
    }),
  },
  resourceForSearchPanel: {
    set: (data = 0) => ({
      payload: data,
      type: SEARCH.SET_RESOURCE_FOR_SEARCH_PANEL,
    }),
  },
  sections: {
    finishLoad: () => ({
      payload: false,
      type: SEARCH.SECTIONS_LOADING,
    }),
    load: () => ({
      type: SEARCH.LOAD_SECTIONS,
    }),
    set: (data = []) => ({
      payload: data,
      type: SEARCH.SET_SECTIONS,
    }),
    startLoad: () => ({
      payload: true,
      type: SEARCH.SECTIONS_LOADING,
    }),
  },
};

export default searchActions;
