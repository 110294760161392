import { Form, Select } from 'antd';
import { FC } from 'react';

import { codeToCountryList } from 'utils/constants/countries';
import 'components/searchPanel/searchForm/inputs/index.css';

interface ICountriesSelect {
  initialValue?: string[];
  maxCount: number;
}

const CountriesSelect: FC<ICountriesSelect> = ({ initialValue, maxCount }) => (
  <Form.Item
    name='countries'
    rules={[
      {
        max: maxCount,
        type: 'array',
      },
    ]}
    className='search-form-field'
    initialValue={initialValue}
  >
    <Select
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      maxTagCount={1}
      mode='multiple'
      options={countries}
      placeholder='Страна'
      allowClear
    />
  </Form.Item>
);

const getCountries = () => {
  const result = [];

  for (const key in codeToCountryList) {
    result.push({ label: codeToCountryList[key].name, value: key });
  }

  return result.sort((current, next) => (current.label > next.label ? 1 : -1));
};

const countries = getCountries();

export default CountriesSelect;
