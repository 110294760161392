import { useLocalStorage, useMediaQuery, useUpdateEffect } from 'utils/hooks';

const DARK_THEME_KEY = 'darkTheme';
const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';

interface IUseDarkThemeOutput {
  disable: () => void;
  enable: () => void;
  isDarkTheme: boolean;
  toggle: () => void;
}

/**
 * Получение измененного значения в LocalStorage, а также запись нового
 * @param {Boolean} defaultValue Значение результата, если по ключу ничего не было найдено
 * @description Hook
 */
const useDarkTheme = (defaultValue?: boolean): IUseDarkThemeOutput => {
  const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY);

  const [isDarkTheme, setDarkTheme] = useLocalStorage<boolean>(
    DARK_THEME_KEY,
    defaultValue ?? isDarkOS ?? false
  );

  // Обновляет даркмод если есть системные предпочтения
  useUpdateEffect(() => {
    setDarkTheme(isDarkOS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkOS]);

  return {
    disable: () => setDarkTheme(false),
    enable: () => setDarkTheme(true),
    isDarkTheme,
    toggle: () => setDarkTheme((prev) => !prev),
  };
};

export default useDarkTheme;

// Использование

// export const Component = () => {
// 	const { isDarkTheme, toggle, enable, disable } = useDarkTheme()
//
// 	return (
// 		<div>
// 			<p>Current theme: {isDarkTheme ? 'dark' : 'light'}</p>
// 	  	<button onClick={toggle}>Toggle</button>
// 			<button onClick={enable}>Enable</button>
// 			<button onClick={disable}>Disable</button>
// 		</div>
// 	)
// }
