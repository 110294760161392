import { theme } from 'antd';

export const lightTheme = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: '#0041a0',
    colorTextHeading: '#111',
  },
};

export const darkTheme = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorTextHeading: '#f9f9f9',
  },
};
