import { Button, Form, Input, Radio, Switch } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { FC } from 'react';

import searchNotificationsApi from 'services/searchNotificationsApi';
import { useAppSelector } from 'store/selectors/useAppSelector';
import { useBoolean } from 'utils/hooks';
import { IAddNotification } from 'utils/interfaces/searchNotifications.interface';

interface Props {
  onSuccess: () => void;
}

const SearchNotificationsSettings: FC<Props> = ({ onSuccess }) => {
  const keywords = useAppSelector((s) => s.search.keywords);
  const [loading, updateLoading] = useBoolean(false);
  const [disabled, updateDisabled] = useBoolean(false);

  const onFinish = (values: IAddNotification) => {
    updateLoading.on();
    searchNotificationsApi
      .addNotification({
        ...values,
        isActive: values.isActive === 'checked',
      })
      .then((res) => {
        onSuccess();
      })
      .finally(() => {
        updateLoading.off();
      });
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<IAddNotification>) => {
    console.log('Failed:', errorInfo);
  };

  const onKeywordsChange = ({ target }: { target: HTMLInputElement }) => {
    updateDisabled[target.value.trim() ? 'off' : 'on']();
  };

  return (
    <Form
      name='basic'
      initialValues={{
        frequency: 1,
        isActive: 'checked',
      }}
      autoComplete='off'
      layout='vertical'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name='keyWords'
        rules={[
          {
            message: 'Введите ключевые слова',
            required: true,
          },
        ]}
        initialValue={keywords}
        label='Набор ключевых слов:'
      >
        <Input autoFocus={true} onChange={onKeywordsChange} />
      </Form.Item>

      <Form.Item name='frequency' label='Частота:'>
        <Radio.Group
          options={[
            { label: 'Еженедельно', value: 0 },
            { label: 'Ежемесячно', value: 1 },
          ]}
          buttonStyle='solid'
          optionType='button'
        />
      </Form.Item>

      <Form.Item name='isActive' label='Статус:' valuePropName='checked'>
        <Switch checkedChildren='Активно' unCheckedChildren='Неактивно' />
      </Form.Item>

      <Form.Item style={{ marginBottom: '5px', textAlign: 'right' }}>
        <Button
          disabled={disabled}
          htmlType='submit'
          loading={loading}
          type='primary'
        >
          Создать
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SearchNotificationsSettings;
