import { AnyAction, Reducer } from '@reduxjs/toolkit';

import { SEARCH } from 'utils/constants/actionTypes';
import { PATENTS_RESOURCE } from 'utils/constants/appConfig';
import { INotification } from 'utils/interfaces/notification.interface';
import { ISection } from 'utils/interfaces/section.interface';

const initialState = {
  assignees: [],
  assigneesLoading: false,
  authors: [],
  authorsLoading: false,
  cloudTag: '',
  keywords: '',
  notifications: [],
  notificationsLoading: false,
  resource: PATENTS_RESOURCE,
  resourceForSearchPanel: PATENTS_RESOURCE,
  sections: [],
  sectionsLoading: false,
};

interface IInitialState {
  assignees: string[];
  assigneesLoading: boolean;
  authors: string[];
  authorsLoading: boolean;
  cloudTag: string;
  keywords: string;
  notifications: INotification[];
  notificationsLoading: boolean;
  resource: number;
  resourceForSearchPanel: number;
  sections: ISection[];
  sectionsLoading: boolean;
}

const searchReducer: Reducer<IInitialState> = (
  state = initialState,
  { payload, type }: AnyAction
) => {
  switch (type) {
    case SEARCH.SET_SECTIONS:
      return { ...state, sections: payload };
    case SEARCH.SECTIONS_LOADING:
      return { ...state, sectionsLoading: payload };
    case SEARCH.SET_PATENT_ASSIGNEES:
      return { ...state, assignees: payload };
    case SEARCH.PATENT_ASSIGNEES_LOADING:
      return { ...state, assigneesLoading: payload };
    case SEARCH.SET_PUBLICATION_ASSIGNEES:
      return { ...state, assignees: payload };
    case SEARCH.PUBLICATION_ASSIGNEES_LOADING:
      return { ...state, assigneesLoading: payload };
    case SEARCH.SET_PATENT_AUTHORS:
      return { ...state, authors: payload };
    case SEARCH.PATENT_AUTHORS_LOADING:
      return { ...state, authorsLoading: payload };
    case SEARCH.SET_PUBLICATION_AUTHORS:
      return { ...state, authors: payload };
    case SEARCH.PUBLICATION_AUTHORS_LOADING:
      return { ...state, authorsLoading: payload };
    case SEARCH.SET_KEYWORDS:
      return { ...state, keywords: payload };
    case SEARCH.SET_CLOUD_TAG:
      return { ...state, cloudTag: payload };
    case SEARCH.SET_RESOURCE:
      return { ...state, resource: payload };
    case SEARCH.SET_RESOURCE_FOR_SEARCH_PANEL:
      return { ...state, resourceForSearchPanel: payload };
    case SEARCH.SET_NOTIFICATIONS:
      return { ...state, notifications: payload };
    case SEARCH.NOTIFICATIONS_LOADING:
      return { ...state, notificationsLoading: payload };
    default:
      return state;
  }
};

export default searchReducer;
