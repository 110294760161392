import { all } from 'redux-saga/effects';

import { byUserSaga } from 'store/byUserStore';
import analyticsSaga from 'store/sagas/analyticsSaga';
import appSaga from 'store/sagas/appSaga';
import ipcSaga from 'store/sagas/ipcSaga';
import popTrendsSaga from 'store/sagas/popTrendsSaga';
import priorityDirectionsSaga from 'store/sagas/priorityDirectionsSaga';
import searchSagas from 'store/sagas/searchSagas';
import trendsAnalysisSaga from 'store/sagas/trendsAnalysisSaga';
export default function* rootSaga() {
  yield all([
    appSaga(),
    analyticsSaga(),
    ipcSaga(),
    popTrendsSaga(),
    priorityDirectionsSaga(),
    searchSagas(),
    byUserSaga(),
    trendsAnalysisSaga(),
  ]);
}
