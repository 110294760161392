import { Table, TablePaginationConfig, Typography } from 'antd';
import { FC } from 'react';

import { getDeclension } from 'utils/helpers/commonHelper';

const { Text } = Typography;

interface ISimpleTable {
  config: any;
  loading?: boolean;
  onChange?: (pagination: TablePaginationConfig) => void;
  onRowDoubleClick?(row: any): void;
  onSelect?(row: any): void;
  showTotal?: boolean;
}

const SimpleTable: FC<ISimpleTable> = ({
  config,
  loading,
  onChange,
  onRowDoubleClick,
  onSelect,
  showTotal = true,
}) => {
  return (
    <div>
      {Boolean(showTotal) && config.data?.total > 0 && (
        <div style={{ paddingBottom: '16px' }}>
          <Text type='secondary'>
            {config.data?.total}{' '}
            {getDeclension(config.data?.total, [
              'совпадение',
              'совпадения',
              'совпадений',
            ])}
          </Text>
        </div>
      )}
      <Table
        className={onRowDoubleClick || onSelect ? 'clickable-table-row' : ''}
        columns={config.columns}
        dataSource={config.data?.results || config.data}
        loading={loading}
        rowKey={config.key}
        scroll={{ y: config.height }}
        showHeader={config.showHeader}
        size='small'
        onChange={(pagination) =>
          onChange &&
          onChange({
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          })
        }
        onRow={(record) => ({
          onClick: () => {
            onSelect && onSelect(record);
          },
          onDoubleClick: () => onRowDoubleClick && onRowDoubleClick(record),
        })}
        pagination={
          config.pagination
            ? {
                ...config.pagination,
                hideOnSinglePage: true,
                position: ['bottomCenter'],
                total: config.data?.total || config.data.length,
              }
            : false
        }
      />
    </div>
  );
};

export default SimpleTable;
