/**
 * Базовый URL API
 */
export const API_URL =
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_API_URL
    : `	api`;

/**
 * Используемая версия API по умолчанию
 */
export const API_DEFAULT_VERSION = 'v1';

/**
 * Заголовок HTTP-запроса с запретом на кэширование
 */
export const HTTP_CONFIG_NO_CACHE = {
  'Cache-Control': 'no-cache, no-store',
  Pragma: 'no-cache',
};

/**
 * Конфиг для запросов, которые могут кэшироваться
 */
export const API_CONFIG = {
  baseURL: API_URL,
};

/**
 * Конфиг для запросов, которые не могут кэшироваться
 */
export const API_CONFIG_WITH_NO_CACHE = {
  baseURL: API_URL,
  headers: HTTP_CONFIG_NO_CACHE,
};
