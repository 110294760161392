import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import ipcApi from 'services/ipcApi';
import patentsApi from 'services/patentsApi';
import publicationsApi from 'services/publicationsApi';
import searchNotificationsApi from 'services/searchNotificationsApi';
import appActions from 'store/actions/appActions';
import searchActions from 'store/actions/searchActions';
import { SEARCH } from 'utils/constants/actionTypes';
import { IErrorResponse } from 'utils/interfaces/errorResponse.interface';

/**
 * Загрузка секций
 * @description Worker
 */
function* loadSections() {
  try {
    yield put(searchActions.sections.startLoad());

    const { data } = yield call(ipcApi.getIpcSections);

    yield put(searchActions.sections.set(data));
  } catch (e: unknown) {
    yield put(
      appActions.errorNotificationByApiExc(e as AxiosError<IErrorResponse>)
    );
  } finally {
    yield put(searchActions.sections.finishLoad());
  }
}

/**
 * Загрузка патентообладателей
 * @description Worker
 */
function* loadPatentAssignees() {
  try {
    yield put(searchActions.patentAssignees.startLoad());

    const { data } = yield call(patentsApi.getAllPatentsAssignees);

    yield put(searchActions.patentAssignees.set(data));
  } catch (e: unknown) {
    yield put(
      appActions.errorNotificationByApiExc(e as AxiosError<IErrorResponse>)
    );
  } finally {
    yield put(searchActions.patentAssignees.finishLoad());
  }
}

/**
 * Загрузка обладателей авторских прав публикаций
 * @description Worker
 */
function* loadPublicationAssignees() {
  try {
    yield put(searchActions.publicationAssignees.startLoad());

    const { data } = yield call(publicationsApi.getAllPublicationsAssignees);

    yield put(searchActions.publicationAssignees.set(data));
  } catch (e: unknown) {
    yield put(
      appActions.errorNotificationByApiExc(e as AxiosError<IErrorResponse>)
    );
  } finally {
    yield put(searchActions.publicationAssignees.finishLoad());
  }
}

function* loadPatentAuthors() {
  try {
    yield put(searchActions.patentAuthors.startLoad());

    const { data } = yield call(patentsApi.getAllPatentsAuthors);

    yield put(searchActions.patentAuthors.set(data));
  } catch (e: unknown) {
    yield put(
      appActions.errorNotificationByApiExc(e as AxiosError<IErrorResponse>)
    );
  } finally {
    yield put(searchActions.patentAuthors.finishLoad());
  }
}

function* loadPublicationAuthors() {
  try {
    yield put(searchActions.publicationAuthors.startLoad());

    const { data } = yield call(publicationsApi.getAllPublicationsAuthors);

    yield put(searchActions.publicationAuthors.set(data));
  } catch (e: unknown) {
    yield put(
      appActions.errorNotificationByApiExc(e as AxiosError<IErrorResponse>)
    );
  } finally {
    yield put(searchActions.publicationAuthors.finishLoad());
  }
}

/**
 * Загрузка уведомлений о поиске
 * @description Worker
 */
function* loadNotifications() {
  try {
    yield put(searchActions.notifications.startLoad());

    const { data } = yield call(searchNotificationsApi.getAllNotifications);

    yield put(searchActions.notifications.set(data));
  } catch (e: unknown) {
    yield put(
      appActions.errorNotificationByApiExc(e as AxiosError<IErrorResponse>)
    );
  } finally {
    yield put(searchActions.notifications.finishLoad());
  }
}

/**
 * Мониторинг действий связанных с поиском
 * @description Watcher
 */
function* searchSagas() {
  yield takeLatest(SEARCH.LOAD_SECTIONS, loadSections);
  yield takeLatest(SEARCH.LOAD_PATENT_ASSIGNEES, loadPatentAssignees);
  yield takeLatest(SEARCH.LOAD_PUBLICATION_ASSIGNEES, loadPublicationAssignees);
  yield takeLatest(SEARCH.LOAD_PATENT_AUTHORS, loadPatentAuthors);
  yield takeLatest(SEARCH.LOAD_PUBLICATION_AUTHORS, loadPublicationAuthors);
  yield takeLatest(SEARCH.LOAD_NOTIFICATIONS, loadNotifications);
}

export default searchSagas;
