import { RefObject, useEffect, useRef } from 'react';

import { useIsomorphicLayoutEffect } from 'utils/hooks';

// MediaQueryList Event based useEventListener interface
function useEventListener<K extends keyof MediaQueryListEventMap>(
  eventType: K,
  listener: (event: MediaQueryListEventMap[K]) => void,
  target: RefObject<MediaQueryList>,
  options?: AddEventListenerOptions | boolean
): void;

// Window Event based useEventListener interface
function useEventListener<K extends keyof WindowEventMap>(
  eventType: K,
  listener: (event: WindowEventMap[K]) => void,
  target?: undefined,
  options?: AddEventListenerOptions | boolean
): void;

// Element Event based useEventListener interface
function useEventListener<
  K extends keyof HTMLElementEventMap,
  T extends HTMLElement = HTMLDivElement
>(
  eventType: K,
  listener: (event: HTMLElementEventMap[K]) => void,
  target: RefObject<T>,
  options?: AddEventListenerOptions | boolean
): void;

// Document Event based useEventListener interface
function useEventListener<K extends keyof DocumentEventMap>(
  eventType: K,
  listener: (event: DocumentEventMap[K]) => void,
  target: RefObject<Document>,
  options?: AddEventListenerOptions | boolean
): void;

/**
 * Прослушивание события с поддержкой Window, Element, Document и custom events
 * @param eventType - Тип события
 * @param listener - Обработчик прослушиваемого события
 * @param target - Прослушиваемый элемент
 * @param options - Опциональные параметры для организации прослушивания
 * @description Hook
 */
function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  KM extends keyof MediaQueryListEventMap,
  T extends HTMLElement | MediaQueryList | void = void
>(
  eventType: KH | KM | KW,
  listener: (
    event:
      | Event
      | HTMLElementEventMap[KH]
      | MediaQueryListEventMap[KM]
      | WindowEventMap[KW]
  ) => void,
  target?: RefObject<T>,
  options?: AddEventListenerOptions | boolean
) {
  const savedListener = useRef(listener);

  useIsomorphicLayoutEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  useEffect(() => {
    const targetElement: T | Window = target?.current ?? window;

    if (
      !(
        targetElement &&
        'addEventListener' in targetElement &&
        targetElement.addEventListener
      )
    )
      return;

    const eventListener: typeof listener = (event) =>
      savedListener.current(event);

    targetElement.addEventListener(eventType, eventListener, options);

    return () => {
      targetElement.removeEventListener(eventType, eventListener, options);
    };
  }, [eventType, target, options]);
}

export default useEventListener;

// Использование

// export const Component = () => {
// 	// Define button ref
// 	const buttonRef = useRef<HTMLButtonElement>(null)
// 	const documentRef = useRef<Document>(document)
//
// 	const onScroll = (event: Event) => {
// 		console.log('window scrolled!', event)
// 	}
//
// 	const onClick = (event: Event) => {
// 		console.log('button clicked!', event)
// 	}
//
// 	const onVisibilityChange = (event: Event) => {
// 		console.log('doc visibility changed!', {
// 			isVisible: !document.hidden,
// 			event,
// 		})
// 	}
//
// 	// example with window based event
// 	useEventListener('scroll', onScroll)
//
// 	// example with document based event
// 	useEventListener('visibilitychange', onVisibilityChange, documentRef)
//
// 	// example with element based event
// 	useEventListener('click', onClick, buttonRef)
//
// 	return (
// 		<div style={{ minHeight: '200vh' }}>
// 			<button ref={buttonRef}>Click me</button>
// 		</div>
// 	)
// }
