import { call, put, takeLatest } from 'redux-saga/effects';

import priorityDirectionsApi from 'services/priorityDirectionsApi';
import appActions from 'store/actions/appActions';
import priorityDirectionsActions from 'store/actions/priorityDirectionsActions';
import { PRIORITY_DIRECTIONS } from 'utils/constants/actionTypes';

/**
 * Загрузка приоритетных направлений
 * @description Worker
 */
function* loadPriorityDirection() {
  try {
    yield put(priorityDirectionsActions.startLoad());

    const response = yield call(priorityDirectionsApi.getPriorityDirections);

    yield put(priorityDirectionsActions.set(response.data));
  } catch (e) {
    yield put(appActions.errorNotificationByApiExc(e));
  } finally {
    yield put(priorityDirectionsActions.finishLoad());
  }
}

/**
 * Мониторинг действий связанных с приоритетов направлений
 * @description Watcher
 */
function* priorityDirectionSaga() {
  yield takeLatest(PRIORITY_DIRECTIONS.LOAD_ALL, loadPriorityDirection);
}

export default priorityDirectionSaga;
