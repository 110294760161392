import { Badge, Popover } from 'antd';
import { FC } from 'react';

import { IExpertContact } from 'utils/interfaces/expert.interface';

interface IProps {
  expertContact: IExpertContact[];
}

const ExpertsPopover: FC<IProps> = ({ expertContact }) => {
  return (
    <Popover
      title={expertContact[0]?.name}
      content={
        <div>
          <div>Должность: {expertContact[0]?.profile}</div>
          <div>Место работы: {expertContact[0]?.direction}</div>
          <a>Почта: {expertContact[0]?.email}</a>
        </div>
      }
    >
      <Badge
        status='success'
        text={
          <a>Существует возможность связаться с экспертом внутри компании</a>
        }
      />
    </Popover>
  );
};

export default ExpertsPopover;
