import { AutoComplete, Form, theme } from 'antd';
import { FC, useEffect, useState } from 'react';
import { createRegexRenderer, RichTextarea } from 'rich-textarea';

import 'components/searchPanel/searchForm/inputs/index.css';
import {
  HIGHLIGHT_OPERATORS,
  KEYWORDS_PATTERN,
  MULTIPLE_OPERATORS_IN_A_ROW_RESTRICTION,
} from 'utils/constants/keywordsOperators';
import { useDarkTheme } from 'utils/hooks';

const rules = [
  {
    message:
      'Ключевые слова должны быть в формате word1 AND word2 OR word3 NOT word4',
    pattern: KEYWORDS_PATTERN,
  },
  {
    message: 'Логические операторы должны быть разделеные словами',
    pattern: MULTIPLE_OPERATORS_IN_A_ROW_RESTRICTION,
  },
  { message: 'Максимально допустимое количество символов: 255', max: 255 },
];

const renderer = createRegexRenderer([
  [HIGHLIGHT_OPERATORS, { color: 'rgba(22,104,220)' }],
]);

interface Props {
  keywords?: string;
}

const KeywordsSelect: FC<Props> = ({ keywords }) => {
  const { token } = theme.useToken();
  const { isDarkTheme } = useDarkTheme();
  const [text, setText] = useState(keywords || '');

  useEffect(() => {
    setText(keywords || '');
  }, [keywords]);

  return (
    <Form.Item name='keywords' className='search-form-field' rules={rules}>
      <AutoComplete
        dropdownMatchSelectWidth={false}
        onClear={() => setText('')}
        popupClassName='search-form-field__dropdown'
        allowClear
        autoFocus
      >
        <div
          className='textareaContainer'
          style={{ backgroundColor: token.colorBgContainer }}
        >
          <RichTextarea
            onChange={(e) => setText(e.target.value)}
            placeholder='Начните поиск...'
            value={text}
            autoHeight
            className={
              isDarkTheme
                ? 'textareaKeywordsSelectDark'
                : 'textareaKeywordsSelectLight'
            }
            style={{
              resize: 'none',
              padding: '7px 24px 7px 11px',
              fontSize: '16px',
              lineHeight: '1.57',
              width: '100%',
              borderRadius: '8px',
              maxHeight: '215px',
              outline: '0px',
              transition: 'all 0.3s,height 0s',
              color: token.colorText,
              caretColor: token.colorText,
            }}
          >
            {renderer}
          </RichTextarea>
        </div>
      </AutoComplete>
    </Form.Item>
  );
};

export default KeywordsSelect;
