import { Layout, Menu } from 'antd';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import * as MenuIcons from 'components/icons';
import { SimpleLink } from 'components/links';
import security from 'services/security';
import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { useBoolean, useCurrentPageInfo } from 'utils/hooks';
import 'components/menu/leftMenu/index.scss';

const links = [
  { ...PAGES_INFO.searchPage, icon: <MenuIcons.SearchMenuIcon /> },
  { ...PAGES_INFO.analyticsPage, icon: <MenuIcons.AnalyticMenuIcon /> },
  { ...PAGES_INFO.landscapesPage, icon: <MenuIcons.LandscapeMenuIcon /> },
  { ...PAGES_INFO.populationTrendsPage, icon: <MenuIcons.RiseMenuIcon /> },
  { ...PAGES_INFO.expertsPage, icon: <MenuIcons.ExpertsMenuIcon /> },
  {
    ...PAGES_INFO.chemicalCompositionPage,
    icon: <MenuIcons.ExperimentMenuIcon />,
  },
  //{ ...PAGES_INFO.selectedPatentsPage, icon: <StarOutlined /> },
  //{ ...PAGES_INFO.managementPage, icon: <SettingMenuIcon /> },
];
let hasAdminLink = false;

const LeftMenu = () => {
  const location = useLocation();
  const pageInfo = useCurrentPageInfo();
  const [collapsed, updateCollapsed] = useBoolean(true);
  const menuItems = useMemo(() => {
    if (!hasAdminLink && security.isAdmin()) {
      hasAdminLink = true;
      links.push({
        ...PAGES_INFO.adminPage,
        icon: <MenuIcons.AdminMenuIcon />,
      });
    }

    return links.map((link) => ({
      icon: link?.icon,
      key: link.path,
      label: <SimpleLink {...link} search={location.search} />,
    }));
  }, [location.search]);

  return (
    <Layout.Sider
      className='non-selectable fixed-menu-sider'
      collapsed={collapsed}
      onCollapse={updateCollapsed.toggle}
      collapsible
    >
      <div
        className={
          collapsed ? 'fixed-menu fixed-menu--collapsed' : 'fixed-menu'
        }
      >
        <Menu
          className='align-icon'
          defaultSelectedKeys={[pageInfo.path]}
          items={menuItems}
          mode='inline'
          theme='dark'
        />
      </div>
    </Layout.Sider>
  );
};

export default LeftMenu;
