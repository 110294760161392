import { NotificationTypes } from 'utils/constants/appConfig';

/**
 * Генерирует уведомление
 * @description Используется для формирования параметров уведомления
 * @param    {String} message Текст уведомления
 * @param    {String} type  Тип уведомления
 * @param    {String} title  Заголовок уведомления (по умолчанию используется информация из типа уведомления)
 * @returns  {Object} Переметры уведомления
 */
export const generateNotification = (
  message: null | string,
  type: null | string = null,
  title: null | string = null
) => {
  const n = {
    ...getNotification(type),
    description: message,
    placement: 'bottomRight',
  };

  if (title) n.message = title;

  return n;
};

/**
 * Возващает параметры уведомления по его типу
 * @param    {String} type  Тип уведомления
 * @returns  {*} Переметры уведомления
 */
export const getNotification = (type: null | string) => {
  switch (type) {
    case NotificationTypes.success.type:
    case NotificationTypes.info.type:
    case NotificationTypes.warning.type:
    case NotificationTypes.error.type:
      // @ts-ignore
      return NotificationTypes[type];
    default:
      return NotificationTypes.info;
  }
};

/**
 * Возвращает параметры уведомления типа success
 * @param    {String} message Текст уведомления
 * @param    {String} title  Заголовок уведомления (по умолчанию используется информация из типа уведомления)
 * @returns  {Object} Переметры уведомления
 */
export const getSuccess = (
  message: null | string,
  title: null | string = null
) => generateNotification(message, NotificationTypes.success.type, title);

/**
 * Возвращает параметры уведомления типа info
 * @param    {String} message Текст уведомления
 * @param    {String} title  Заголовок уведомления (по умолчанию используется информация из типа уведомления)
 * @returns  {Object} Переметры уведомления
 */
export const getInfo = (message: null | string, title: null | string = null) =>
  generateNotification(message, NotificationTypes.info.type, title);

/**
 * Возвращает параметры уведомления типа warning
 * @param    {String} message Текст уведомления
 * @param    {String} title  Заголовок уведомления (по умолчанию используется информация из типа уведомления)
 * @returns  {Object} Переметры уведомления
 */
export const getWarning = (
  message: null | string,
  title: null | string = null
) => generateNotification(message, NotificationTypes.warning.type, title);

/**
 * Возвращает параметры уведомления типа error
 * @param    {String} message Текст уведомления
 * @param    {String} title  Заголовок уведомления (по умолчанию используется информация из типа уведомления)
 * @returns  {Object} Переметры уведомления
 */
export const getError = (message: null | string, title: null | string = null) =>
  generateNotification(message, NotificationTypes.error.type, title);
