import { Col, Row, Space, theme } from 'antd';
import { FC } from 'react';

import FavoriteExpertsButton from 'components/expertsPageComponents/favoriteExpertsButton';
import styles from 'components/layouts/layoutWithTopMenu/index.module.scss';
import { SimpleLink } from 'components/links';
import { TopMenu } from 'components/menu';
import { SearchNotificationsButton } from 'components/searchPanel/searchNotifications';
import { ThemeSwitch } from 'components/switches';
import { COMPANY_NAME } from 'utils/constants/appConfig';
import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { useDarkTheme } from 'utils/hooks';

interface Props {
  isExpertsPage?: boolean;
  isMainPage: boolean;
}

const LayoutHeader: FC<Props> = ({ isExpertsPage, isMainPage }) => {
  const { isDarkTheme } = useDarkTheme();

  return (
    <div
      className={styles.header}
      style={{
        backgroundColor: isDarkTheme
          ? 'rgba(0,0,0,0.65)'
          : 'rgba(245,245,245,0.65)',
      }}
    >
      <Row
        align='middle'
        className={styles.row}
        justify='space-between'
        wrap={false}
      >
        <Col className={styles.logo} flex='none'>
          {COMPANY_NAME}
        </Col>
        <Col span={12}>
          <TopMenu />
        </Col>
        <Space className={styles.buttons}>
          <ThemeSwitch />
          {isExpertsPage && <FavoriteExpertsButton />}
          {!isMainPage && <SearchNotificationsButton />}
        </Space>
      </Row>
    </div>
  );
};

export default LayoutHeader;
