import { message } from 'antd';

import { mapCountry } from 'components/expertsPageComponents';
import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { baseApi, tagTypes } from 'utils/helpers/api';
import { getQueryByParams } from 'utils/helpers/commonHelper';
import {
  IComparisonOfCompanies,
  IComparisonOfCompaniesCompetence,
  IComparisonOfCompaniesTop,
  IComparisonOfCompaniesTopAndCompetenceData,
} from 'utils/interfaces/comparisonOfCompanies.interface';
import {
  IExpert,
  IExpertCoAuthor,
  IExpertCompany,
  IExpertCompetenciesByCountries,
  IExpertCountryClass,
  IExpertCountryGroup,
  IExpertCountrySection,
  IExpertCountrySubgroup,
  IExpertData,
  IExpertFavorite,
  IExpertPatent,
  IExpertPublication,
} from 'utils/interfaces/expert.interface';
import { IPaginationResponse } from 'utils/interfaces/pagination.interface';
import { ISearchParams } from 'utils/interfaces/search.interface';

const SERVICE_NAME = 'authors';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

// Проработать providesTags и invalidateTags
export const authorsRTKQApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Получить авторов, отфильтрованных по поисковым параметрам
     */
    getFilteredAuthors: build.query<
      IPaginationResponse<IExpert>,
      { searchParams: ISearchParams }
    >({
      providesTags: [tagTypes.authors],
      query: ({ searchParams }) =>
        `${getUrlPart()}${getQueryByParams(searchParams)}`,
      onQueryStarted({ searchParams }, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(({ data }) => {
            if (!data?.total) {
              message.info('К сожалению, поиск не дал результатов!');
            }
          })
          .catch((e) => {});
      },
    }),

    /**
     * Получить количества работ авторов по разделам/группам/подгруппам/классам
     */
    getExpertsAnalytics: build.query<
      IExpertCompetenciesByCountries,
      { searchParams: ISearchParams }
    >({
      providesTags: [tagTypes.analytics],
      queryFn: ({ searchParams }, _queryApi, _extraOptions, fetchWithBQ) => {
        return Promise.all([
          fetchWithBQ(
            `${getUrlPart()}/charts/country-sections${getQueryByParams(
              searchParams
            )}`
          ),
          fetchWithBQ(
            `${getUrlPart()}/charts/country-classes${getQueryByParams(
              searchParams
            )}`
          ),
          fetchWithBQ(
            `${getUrlPart()}/charts/country-groups${getQueryByParams(
              searchParams
            )}`
          ),
          fetchWithBQ(
            `${getUrlPart()}/charts/country-subgroups${getQueryByParams(
              searchParams
            )}`
          ),
        ])
          .then((arr) => {
            return {
              data: {
                countryClasses: mapCountry(
                  arr[1].data as IExpertCountryClass[]
                ),
                countryGroups: mapCountry(arr[2].data as IExpertCountryGroup[]),
                countrySections: mapCountry(
                  arr[0].data as IExpertCountrySection[]
                ),
                countrySubgroups: mapCountry(
                  arr[3].data as IExpertCountrySubgroup[]
                ),
              },
            };
          })
          .catch((e) => ({ error: e }));
      },
    }),

    /**
     * Получить основную информацию выбранного автора
     */
    getExpertAnalytics: build.query<IExpertData, string>({
      providesTags: [tagTypes.expertData],
      queryFn: (name, _queryApi, _extraOptions, fetchWithBQ) => {
        return Promise.all([
          fetchWithBQ(`${getUrlPart()}/${name}/coauthor`),
          fetchWithBQ(`${getUrlPart()}/${name}/companies`),
          fetchWithBQ(`${getUrlPart()}/${name}`),
          fetchWithBQ(`${getUrlPart()}/${name}/patents`),
          fetchWithBQ(`${getUrlPart()}/${name}/publications`),
        ])
          .then((arr) => {
            return {
              data: {
                coauthors: arr[0].data as IExpertCoAuthor[],
                companies: arr[1].data as IExpertCompany[],
                expert: arr[2].data as IExpert,
                patents: arr[3].data as IExpertPatent[],
                publications: arr[4].data as IExpertPublication[],
              },
            };
          })
          .catch((e) => ({ error: e }));
      },
    }),

    /**
     * Получить основную информацию для сравнения компаний
     */
    getAuthorsCompaniesComparison: build.query<
      Record<'c1' | 'c2', IComparisonOfCompaniesTopAndCompetenceData>,
      IComparisonOfCompanies
    >({
      providesTags: [tagTypes.companiesComparison],
      queryFn: ({ c1, c2 }, _queryApi, _extraOptions, fetchWithBQ) => {
        return Promise.allSettled([
          fetchWithBQ(
            `${getUrlPart()}/competences${getQueryByParams({ company: c1 })}`
          ),
          fetchWithBQ(
            `${getUrlPart()}/competences${getQueryByParams({ company: c2 })}`
          ),
          fetchWithBQ(
            `${getUrlPart()}/top${getQueryByParams({ company: c1 })}`
          ),
          fetchWithBQ(
            `${getUrlPart()}/top${getQueryByParams({ company: c2 })}`
          ),
        ])
          .then((arr) => {
            const competencies1 =
              arr[0].status === 'rejected'
                ? { results: [] }
                : arr[0].value.data;
            const competencies2 =
              arr[1].status === 'rejected'
                ? { results: [] }
                : arr[1].value.data;
            const top1 = arr[2].status === 'rejected' ? [] : arr[2].value.data;
            const top2 = arr[3].status === 'rejected' ? [] : arr[3].value.data;

            const getComparisonInfo = (
              competences: IPaginationResponse<IComparisonOfCompaniesCompetence>,
              top: IComparisonOfCompaniesTop[]
            ) => {
              return {
                competences,
                top,
                unique: top
                  .map((e) => e.competences)
                  .flat()
                  .reduce((acc, val) => acc + val.cnt, 0),
              };
            };

            return {
              data: {
                c1: getComparisonInfo(
                  competencies1 as IPaginationResponse<IComparisonOfCompaniesCompetence>,
                  top1 as IComparisonOfCompaniesTop[]
                ),
                c2: getComparisonInfo(
                  competencies2 as IPaginationResponse<IComparisonOfCompaniesCompetence>,
                  top2 as IComparisonOfCompaniesTop[]
                ),
              },
            };
          })
          .catch((e) => ({ error: e }));
      },
    }),

    /**
     * Добавить эксперта в избранное
     */
    addFavoriteExpert: build.mutation({
      invalidatesTags: [tagTypes.favoriteExperts],
      query: (body) => ({
        body,
        method: 'POST',
        url: getUrlPart(),
      }),
    }),

    /**
     * Удалить эксперта из избранного
     */
    deleteFavoriteExpert: build.mutation({
      invalidatesTags: [tagTypes.favoriteExperts],
      query: (id) => ({
        method: 'DELETE',
        url: `/${getUrlPart()}/${id}`,
      }),
    }),

    /**
     * Получить избранных экспертов
     */
    getFavoriteExperts: build.query<IExpertFavorite[], void>({
      providesTags: [tagTypes.favoriteExperts],
      query: () => `/${getUrlPart()}/favorite`,
    }),
  }),
});

export const {
  useGetFilteredAuthorsQuery,
  useGetExpertsAnalyticsQuery,
  useGetExpertAnalyticsQuery,
  useLazyGetAuthorsCompaniesComparisonQuery,
  useAddFavoriteExpertMutation,
  useDeleteFavoriteExpertMutation,
  useGetFavoriteExpertsQuery,
} = authorsRTKQApi;
