import { AnyAction, Reducer } from '@reduxjs/toolkit';

import { IPC } from 'utils/constants/actionTypes';
import { IIpcClass } from 'utils/interfaces/ipcClass.interface';

const initialState = {
  classes: [],
  classesLoading: false,
};

interface IInitialState {
  classes: IIpcClass[];
  classesLoading: boolean;
}

const ipcReducer: Reducer<IInitialState> = (
  state = initialState,
  { payload, type }: AnyAction
) => {
  switch (type) {
    case IPC.CLASSES_SET_DATA:
      return { ...state, classes: payload };
    case IPC.CLASSES_LOADING:
      return { ...state, classesLoading: payload };
    default:
      return state;
  }
};

export default ipcReducer;
