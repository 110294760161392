import dayjs from 'dayjs';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ByUserDTO,
  ByUserParams,
  searchQueriesApi,
} from 'services/searchQueriesApi';
import { RootState } from 'store';

// Types
enum ByUserTypesEnum {
  FAILURE = 'by-user/FAILURE',
  LOAD = 'by-user/LOAD',
  REQUEST = 'by-user/REQUEST',
  RESET = 'by-user/RESET',
  SUCCESS = 'by-user/SUCCESS',
}

// Actions
export const loadByUser = ({ PageSize = 10, ...params }: ByUserParams) =>
  ({ payload: { PageSize, ...params }, type: ByUserTypesEnum.LOAD } as const);

const requestByUser = () => ({ type: ByUserTypesEnum.REQUEST } as const);
const successByUser = (payload: ByUserDTO) =>
  ({
    payload,
    type: ByUserTypesEnum.SUCCESS,
  } as const);
const resetByUser = () => ({ type: ByUserTypesEnum.RESET } as const);
const failureByUser = (payload: Error) =>
  ({ payload, type: ByUserTypesEnum.FAILURE } as const);

// Reducer
type ActionTypes =
  | ReturnType<typeof failureByUser>
  | ReturnType<typeof requestByUser>
  | ReturnType<typeof resetByUser>
  | ReturnType<typeof successByUser>;

type ByUserReducerType = {
  data: ByUserDTO;
  error: Error | null;
  isLoading: boolean;
};

const initialState: ByUserReducerType = {
  data: {
    current: 0,
    pageSize: 0,
    results: [],
    total: 0,
    totalPages: 0,
  },
  error: null,
  isLoading: false,
};

export default function reducer(
  state = initialState,
  action: ActionTypes
): ByUserReducerType {
  switch (action.type) {
    case ByUserTypesEnum.REQUEST:
      return { ...state, error: null, isLoading: true };

    case ByUserTypesEnum.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          results: [...state.data.results, ...action.payload.results],
        },
        isLoading: false,
      };

    case ByUserTypesEnum.FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case ByUserTypesEnum.RESET:
      return { ...initialState };

    default:
      return state;
  }
}

// Sagas
type Params = { payload: ByUserParams; type: string };
export function* fetchByUser({ payload }: Params) {
  try {
    yield put(requestByUser());
    const { data } = yield call(searchQueriesApi.byUser, payload);

    yield put(successByUser(data));
  } catch (e) {
    yield put(failureByUser(e as Error));
  }
}

export function* byUserSaga() {
  yield takeLatest(ByUserTypesEnum.LOAD, fetchByUser);
}

// Selector
export const byUserSelector = (state: RootState) => state.byUser;

export const byUserCardDrawerSelector = (state: RootState) => ({
  ...state.byUser,
  data: {
    ...state.byUser.data,
    results: state.byUser.data.results.map((m) => ({
      children: m.keywords,
      label: dayjs(m.date).format('DD.MM.YYYY HH:mm:ss'),
      original: m,
    })),
  },
});
