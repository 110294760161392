export const APP = {
  ERROR_NOTIFICATION: 'APP/ERROR_NOTIFICATION', // Создание уведомления об ошибке
  INFO_NOTIFICATION: 'APP/INFO_NOTIFICATION', // Создание информационного уведомления
  SET_NOTIFICATION: 'APP/SET_NOTIFICATION', // Вставка сообщения в хранилище
  SUCCESS_NOTIFICATION: 'APP/SUCCESS_NOTIFICATION', // Создание уведомления об успешном выполнении
  WARNING_NOTIFICATION: 'APP/WARNING_NOTIFICATION', // Создание уведомления с предупреждением
};

export const ANALYTICS = {
  AUTHORS_BY_YEAR_LOADING: 'ANALYTICS/AUTHORS_BY_YEAR_LOADING', // Статус загрузки графика по авторам по годам
  AUTHORS_BY_YEAR_SET_DATA: 'ANALYTICS/AUTHORS_BY_YEAR_SET_DATA', // Сохранение загруженного графика по авторам по годам
  AUTHORS_LOADING: 'ANALYTICS/AUTHORS_LOADING', // Статус загрузки графика по авторам
  AUTHORS_SET_DATA: 'ANALYTICS/AUTHORS_SET_DATA', // Сохранение загруженного графика по авторам
  COMPANIES_BY_YEAR_LOADING: 'ANALYTICS/COMPANIES_BY_YEAR_LOADING', // Статус загрузки графика по компаний по годам
  COMPANIES_BY_YEAR_SET_DATA: 'ANALYTICS/COMPANIES_BY_YEAR_SET_DATA', // Сохранение загруженного графика по компаний по годам
  COMPANIES_LOADING: 'ANALYTICS/COMPANIES_LOADING', // Статус загрузки графика по правообладателям
  COMPANIES_SET_DATA: 'ANALYTICS/COMPANIES_SET_DATA', // Сохранение загруженного графика по правообладателям
  COUNTRIES_LOADING: 'ANALYTICS/COUNTRIES_LOADING', // Статус загрузки графика по странам
  COUNTRIES_SET_DATA: 'ANALYTICS/COUNTRIES_SET_DATA', // Сохранение загруженного графика по странам
  DATES_LOADING: 'ANALYTICS/DATES_LOADING', // Статус загрузки графика патентов по датам
  DATES_SET_DATA: 'ANALYTICS/DATES_SET_DATA', // Сохранение загруженного графика патентов по датам
  DIRECTIONS_LOADING: 'ANALYTICS/DIRECTIONS_LOADING', // Статус загрузки графика по направлениям
  DIRECTIONS_SET_DATA: 'ANALYTICS/DIRECTIONS_SET_DATA', // Сохранение загруженного графика по направлениям
  LOAD_ALL: 'ANALYTICS/LOAD_ALL', // Загрузка всех данных для Аналитики
  LOAD_PATENTS_OR_PUBLICATIONS: 'ANALYTICS/LOAD_PATENTS_OR_PUBLICATIONS', // Загрузка патентов или публикаций
  PATENTS_LOADING: 'ANALYTICS/PATENTS_LOADING', // Статус загрузки патентов
  PATENTS_SET_DATA: 'ANALYTICS/PATENTS_SET_DATA', // Сохранение загруженных патентов
  PUBLICATIONS_LOADING: 'ANALYTICS/PUBLICATIONS_LOADING', // Статус загрузки патентов
  PUBLICATIONS_SET_DATA: 'ANALYTICS/PUBLICATIONS_SET_DATA', // Сохранение загруженных патентов
  RESET_ALL: 'ANALYTICS/RESET_ALL', // Сброс всех данных Аналитики
};

export const IPC = {
  CLASSES_LOADING: 'IPC/CLASSES_LOADING', // Статус загрузки классов
  CLASSES_SET_DATA: 'IPC/CLASSES_SET_DATA', // Сохранение загруженных классов
  LOAD_CLASSES: 'IPC/LOAD_CLASSES', // Загрузка класснов индексов МПК
};

export const PRIORITY_DIRECTIONS = {
  LOAD_ALL: 'PRIORITY_DIRECTIONS/LOAD_ALL', // Загрузка приоритетных направлений
  LOADING: 'PRIORITY_DIRECTIONS/LOADING', // Статус загрузки
  SET_DATA: 'PRIORITY_DIRECTIONS/SET_DATA', // Сохранение данных
};

export const POP_TRENDS = {
  COMP_BY_YEAR_LOADING: 'POP_TRENDS/COMP_BY_YEAR_LOADING', // Статус загрузки данных для граф. кол. патентов по компаниям и годам
  COMP_BY_YEAR_SET_DATA: 'POP_TRENDS/COMP_BY_YEAR_SET_DATA', // Сохранение загруженных данных для граф. кол. патентов по компаниям и годам
  IPC_CLOUD_TAGS_LOADING: 'POP_TRENDS/IPC_CLOUD_TAGS_LOADING', // Статус загрузки данных для граф. кол. патентов по подгруппам МПК
  IPC_CLOUD_TAGS_SET_DATA: 'POP_TRENDS/IPC_CLOUD_TAGS_SET_DATA', // Сохранение загруженных данных для граф. кол. патентов по подгруппам МПК
  LOAD_ALL: 'POP_TRENDS/LOAD_ALL', // Загрузка всех данных для Трендов популярности
  RESET_ALL: 'POP_TRENDS/RESET_ALL', // Сброс всех данных Трендов популярности
};

export const SEARCH = {
  LOAD_NOTIFICATIONS: 'SEARCH/SAGAS/LOAD_NOTIFICATIONS',
  LOAD_PATENT_ASSIGNEES: 'SEARCH/SAGAS/LOAD_PATENT_ASSIGNEES',
  LOAD_PATENT_AUTHORS: 'SEARCH/SAGAS/LOAD_PATENT_AUTHORS',
  LOAD_PUBLICATION_ASSIGNEES: 'SEARCH/SAGAS/LOAD_PUBLICATION_ASSIGNEES',
  LOAD_PUBLICATION_AUTHORS: 'SEARCH/SAGAS/LOAD_PUBLICATION_AUTHORS',
  LOAD_SECTIONS: 'SEARCH/SAGAS/LOAD_SECTIONS',
  NOTIFICATIONS_LOADING: 'SEARCH/NOTIFICATIONS_LOADING',
  PATENT_ASSIGNEES_LOADING: 'SEARCH/PATENT_ASSIGNEES_LOADING',
  PATENT_AUTHORS_LOADING: 'SEARCH/PATENT_AUTHORS_LOADING',
  PUBLICATION_ASSIGNEES_LOADING: 'SEARCH/PUBLICATION_ASSIGNEES_LOADING',
  PUBLICATION_AUTHORS_LOADING: 'SEARCH/PUBLICATION_AUTHORS_LOADING',
  SECTIONS_LOADING: 'SEARCH/SECTIONS_LOADING',
  SET_CLOUD_TAG: 'SEARCH/SET_CLOUD_TAG', // Выбранное слово в облаке тэгов
  SET_KEYWORDS: 'SEARCH/SET_KEYWORDS', // Ключевые слова в строке поиска
  SET_NOTIFICATIONS: 'SEARCH/SET_NOTIFICATIONS',
  SET_PATENT_ASSIGNEES: 'SEARCH/SET_PATENT_ASSIGNEES',
  SET_PATENT_AUTHORS: 'SEARCH/SET_PATENT_AUTHORS',
  SET_PUBLICATION_ASSIGNEES: 'SEARCH/SET_PUBLICATION_ASSIGNEES',
  SET_PUBLICATION_AUTHORS: 'SEARCH/SET_PUBLICATION_AUTHORS',
  SET_RESOURCE: 'SEARCH/SET_RESOURCE',
  SET_RESOURCE_FOR_SEARCH_PANEL: 'SEARCH/SET_RESOURCE_FOR_SEARCH_PANEL',
  SET_SECTIONS: 'SEARCH/SET_SECTIONS',
};

export const ADVANCED_SEARCH = {
  AUTHORS_SET_DATA: 'ADVANCED_SEARCH/AUTHORS_SET_DATA',
  COUNTRIES_SET_DATA: 'ADVANCED_SEARCH/COUNTRIES_SET_DATA',
  ITEM_LOADING: 'ADVANCED_SEARCH/ITEM_LOADING',
  KEYWORDS_SET_DATA: 'ADVANCED_SEARCH/KEYWORDS_SET_DATA',
  LOAD: 'ADVANCED_SEARCH/LOAD',
  LOAD_ALL: 'ADVANCED_SEARCH/LOAD_ALL',
  LOADING: 'ADVANCED_SEARCH/LOADING',
  PATENTS_SET_DATA: 'ADVANCED_SEARCH/PATENTS_SET_DATA',
  PROFILES_SET_DATA: 'ADVANCED_SEARCH/PROFILES_SET_DATA',
  PUBLICATIONS_SET_DATA: 'ADVANCED_SEARCH/PUBLICATIONS_SET_DATA',
  RECORDS_SET_DATA: 'ADVANCED_SEARCH/RECORDS_SET_DATA',
  RESET_ALL: 'ADVANCED_SEARCH/RESET_ALL',
  SET_DATA: 'ADVANCED_SEARCH/SET_DATA',
};

export const PROFILE = {
  CHANGE_RANDOM_COFFEE_PARTICIPANT: 'PROFILE/CHANGE_RANDOM_COFFEE_PARTICIPANT',
  LOAD: 'PROFILE/LOAD',
  LOADING: 'PROFILE/LOADING',
  SET_DATA: 'PROFILE/SET_DATA',
  SET_DATA_PROFILE_ID: 'PROFILE/SET_DATA_PROFILE_ID',
  SET_MODAL_SCREEN: 'PROFILE/SET_MODAL_SCREEN',
  SET_MODAL_VISIBILITY: 'PROFILE/SET_MODAL_VISIBILITY',
  SET_USER_COMPETENCES: 'PROFILE/SET_USER_COMPETENCES',
  SET_FORM_DATA: 'PROFILE/SET_FORM_DATA',
};

export const COMMUNITIES = {
  LOAD_ADMINS: 'COMMUNITIES/LOAD_ADMINS',
  LOAD_All: 'COMMUNITIES/LOAD_All',
  LOAD_COMMUNITY: 'COMMUNITIES/LOAD_COMMUNITY',
  LOAD_MEMBERS: 'COMMUNITIES/LOAD_MEMBERS',
  LOAD_MY: 'COMMUNITIES/LOAD_MY',
  LOAD_OTHER: 'COMMUNITIES/LOAD_OTHER',
  LOAD_RECORD_TYPES: 'COMMUNITIES/LOAD_RECORD_TYPES',
  LOAD_RECORDS: 'COMMUNITIES/LOAD_RECORDS',
  LOAD_RECORDS_ALL: 'COMMUNITIES/LOAD_RECORDS_ALL',
  LOAD_ROLES: 'COMMUNITIES/LOAD_ROLES',
  LOADING: 'COMMUNITIES/LOADING',
  LOADING_MY: 'COMMUNITIES/LOADING_MY',
  LOADING_OTHER: 'COMMUNITIES/LOADING_OTHER',
  SET_ADMINS_DATA: 'COMMUNITIES/SET_ADMINS_DATA',
  SET_COMMUNITY_DATA: 'COMMUNITIES/SET_COMMUNITY_DATA',
  SET_INFINITE_RECORDS: 'COMMUNITIES/SET_INFINITE_RECORDS',
  SET_IS_COMMUNITY_ADMIN: 'COMMUNITIES/SET_IS_COMMUNITY_ADMIN',
  SET_MEMBERS_DATA: 'COMMUNITIES/SET_MEMBERS_DATA',
  SET_MY_DATA: 'COMMUNITIES/SET_MY_DATA',
  SET_INFINITE_MY_DATA: 'COMMUNITIES/SET_INFINITE_MY_DATA',
  SET_OTHER_DATA: 'COMMUNITIES/SET_OTHER_DATA',
  SET_INFINITE_OTHER_DATA: 'COMMUNITIES/SET_INFINITE_OTHER_DATA',
  SET_RECORD_TYPES_DATA: 'COMMUNITIES/SET_RECORD_TYPES_DATA',
  SET_RECORDS_DATA: 'COMMUNITIES/SET_RECORDS_DATA',
  SET_ROLES_DATA: 'COMMUNITIES/SET_ROLES_DATA',
};

export const TRENDS_ANALYSIS = {
  ALL_EXTERNAL_NEWS_LOADING: 'TRENDS_ANALYSIS/ALL_EXTERNAL_NEWS_LOADING',
  ALL_EXTERNAL_NEWS_SET_DATA: 'TRENDS_ANALYSIS/ALL_EXTERNAL_NEWS_SET_DATA',

  COMPANIES_LOADING: 'TRENDS_ANALYSIS/COMPANIES_LOADING',
  COMPANIES_SET_DATA: 'TRENDS_ANALYSIS/COMPANIES_SET_DATA',

  COUNTRIES_LOADING: 'TRENDS_ANALYSIS/COUNTRIES_LOADING',
  COUNTRIES_SET_DATA: 'TRENDS_ANALYSIS/COUNTRIES_SET_DATA',

  EXTERNAL_NEWS_LOADING: 'TRENDS_ANALYSIS/EXTERNAL_NEWS_LOADING',
  EXTERNAL_NEWS_SET_DATA: 'TRENDS_ANALYSIS/EXTERNAL_NEWS_SET_DATA',

  EXTERNAL_NEWS_BY_TOPIC_LOADING:
    'TRENDS_ANALYSIS/EXTERNAL_NEWS_BY_TOPIC_LOADING',
  EXTERNAL_NEWS_BY_TOPIC_SET_DATA:
    'TRENDS_ANALYSIS/EXTERNAL_NEWS_BY_TOPIC_SET_DATA',

  EXTERNAL_NEWS_TOPICS_LOADING: 'TRENDS_ANALYSIS/EXTERNAL_NEWS_TOPICS_LOADING',
  EXTERNAL_NEWS_TOPICS_SET_DATA:
    'TRENDS_ANALYSIS/EXTERNAL_NEWS_TOPICS_SET_DATA',

  LOAD: 'TRENDS_ANALYSIS/LOAD',
  LOAD_ALL: 'TRENDS_ANALYSIS/LOAD_ALL',

  LOADING_ALL: 'TRENDS_ANALYSIS/LOADING_ALL',
  PATENTS_DIRECTIONS_LOADING: 'TRENDS_ANALYSIS/PATENTS_DIRECTIONS_LOADING',

  PATENTS_DIRECTIONS_SET_DATA: 'TRENDS_ANALYSIS/PATENTS_DIRECTIONS_SET_DATA',
  PATENTS_LOADING: 'TRENDS_ANALYSIS/PATENTS_LOADING',

  PATENTS_SET_DATA: 'TRENDS_ANALYSIS/PATENTS_SET_DATA',
  PUBLICATIONS_DIRECTIONS_LOADING:
    'TRENDS_ANALYSIS/PUBLICATIONS_DIRECTIONS_LOADING',

  PUBLICATIONS_DIRECTIONS_SET_DATA:
    'TRENDS_ANALYSIS/PUBLICATIONS_DIRECTIONS_SET_DATA',
  PUBLICATIONS_LOADING: 'TRENDS_ANALYSIS/PUBLICATIONS_LOADING',
  PUBLICATIONS_SET_DATA: 'TRENDS_ANALYSIS/PUBLICATIONS_SET_DATA',
  RESET_ALL: 'TRENDS_ANALYSIS/RESET_ALL',
};
