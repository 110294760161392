import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Space } from 'antd';

/**
 * Возвращает параметры столбца таблицы
 * @param {String} title - Наименование столбца таблицы
 * @param {String} dataIndex - Ключ к данным столбца
 * @param {String} width - Ширина столбца
 * @param {String} type - Тип данных столбца (text - текст; num - числовое)
 */
export const getColumn = (
  title,
  dataIndex,
  width = '',
  type = 'text',
  hasSort = true,
  hasFilter = true
) => {
  let align, sorter, placeholder, filter;

  switch (type) {
    case 'text':
      align = 'left'; // выравниваем по левой стороне
      if (hasSort) {
        sorter = (a, b) => {
          if (a[dataIndex] < b[dataIndex]) return -1;
          if (a[dataIndex] > b[dataIndex]) return 1;
          return 0;
        };
      }
      placeholder = 'Текст поиска'; // меняем placeholder
      if (hasFilter) {
        filter = (value, record) =>
          record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : '';
      }
      break;
    case 'num':
      align = 'right'; // выравниваем по правой стороне
      if (hasSort) {
        sorter = (a, b) => a[dataIndex] - b[dataIndex];
      }
      placeholder = 'Введите число'; // меняем placeholder
      if (hasFilter) {
        filter = (value, record) => record[dataIndex] === Number(value);
      }
      break;
    case 'dt':
      align = 'right'; // выравниваем по правой стороне

      if (hasSort) {
        sorter = (a, b) => {
          if (a[dataIndex] < b[dataIndex]) return -1;
          if (a[dataIndex] > b[dataIndex]) return 1;
          return 0;
        };
      }
      break;
    default:
      return;
  }

  return {
    align,
    dataIndex,
    ellipsis: true,
    filterDropdown: hasFilter
      ? ({ clearFilters, confirm, selectedKeys, setSelectedKeys }) => (
          <div style={{ padding: 8 }}>
            {type === 'dt' ? (
              <DatePicker
                autoFocus={true}
                bordered={false}
                format='DD.MM.YYYY'
                onChange={(e) => setSelectedKeys(e ? [e] : [])}
                onPressEnter={() => confirm()}
                placeholder={placeholder}
                style={{ display: 'block', marginBottom: 8, width: 188 }}
                value={selectedKeys[0]}
              />
            ) : (
              <Input
                autoFocus={true}
                bordered={false}
                onPressEnter={() => confirm()}
                placeholder={placeholder}
                style={{ display: 'block', marginBottom: 8, width: 188 }}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
              />
            )}
            <Space>
              <Button
                onClick={() => confirm()}
                size='small'
                style={{ width: 90 }}
              >
                Поиск
              </Button>
              <Button
                onClick={() => clearFilters()}
                size='small'
                style={{ width: 90 }}
              >
                Сброс
              </Button>
            </Space>
          </div>
        )
      : null,
    filterIcon: hasFilter
      ? (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        )
      : null,
    onFilter: filter,
    sorter,
    title,
    width,
  };
};
