import { StarOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { FC } from 'react';

import ListOfFavoriteExperts from 'components/expertsPageComponents/listOfFavoriteExperts';
import 'components/expertsPageComponents/styles.scss';

const FavoriteExpertsButton: FC = () => {
  return (
    <Popover
      content={<ListOfFavoriteExperts />}
      destroyTooltipOnHide={true}
      overlayStyle={{ maxWidth: '640px' }}
      placement='bottomRight'
      title={<h2>Избранные эксперты</h2>}
      trigger='click'
    >
      <Button className='favorite-button' icon={<StarOutlined />} />
    </Popover>
  );
};

export default FavoriteExpertsButton;
