import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface ISimpleLink {
  children?: ReactNode;
  path: string;
  search?: string;
  title?: string;
}
const SimpleLink: FC<ISimpleLink> = ({ children, path, search, title }) => (
  <Link to={{ pathname: path, search }}>{children || title}</Link>
);

export default SimpleLink;
