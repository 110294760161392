import { API_DEFAULT_VERSION } from 'utils/constants/apiConfig';
import { API, API_NO_CACHE } from 'utils/helpers/api';
import { getQueryByParams } from 'utils/helpers/commonHelper';

import type { IComparisonOfCompanies } from 'utils/interfaces/comparisonOfCompanies.interface';
import type { ISearchParams } from 'utils/interfaces/search.interface';

const SERVICE_NAME = 'publications';
const getUrlPart = (version = null) =>
  `${version || API_DEFAULT_VERSION}/${SERVICE_NAME}`;

/**
 * Получение компаний, являющихся правообладателями публикаций
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getAllPublicationsAssignees = async (cancelToken: any = null) =>
  await API.get(`${getUrlPart()}/all-assignees`, {
    cancelToken,
  });

/**
 * Получение авторов публикаций
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getAllPublicationsAuthors = async (cancelToken: any = null) =>
  await API.get(`${getUrlPart()}/all-authors`, {
    cancelToken,
  });

/**
 * Получение классов, подклассов и групп индексов МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcClasses = async (filters: ISearchParams, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/classes${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение отфильтрованных публикаций
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getFiltered = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(`${getUrlPart()}${getQueryByParams(filters)}`, {
    cancelToken,
  });

/**
 * Получение отфильтрованных публикаций
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getFilteredPublicationsPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/pop-trends${getQueryByParams(filters)}`,
    {
      cancelToken,
    }
  );

/**
 * Получение деталей публикации по его Id
 * @param {Number} id - идентификатор публикации
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getById = async (id: number, cancelToken: any = null) =>
  await API.get(`${getUrlPart()}/${id}`, { cancelToken });

/**
 * Получение облака тегов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getCloudTags = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/cloud-tags${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по дате
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPublicationsByDt = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/dates${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по дате
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPublicationsByDtPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/dates/pop-trends${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по классу индекса МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByClasses = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/classes${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по классу индекса МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPublicationsByClassesPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/classes/pop-trends${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по правообладателю
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByAssignees = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/assignees${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по автору
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const GetChartPublicationsByAuthors = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/authors${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по стране
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByCountries = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/countries${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика публикаций по стране по году
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartByCountryYears = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/landscape${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества публикаций по разделам
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartBySections = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/publications-sections${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества публикаций по индексам МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcCloudTags = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/ipc-cloud-tags${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества публикаций по подгруппам индекса МПК
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getIpcCloudTagsPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/ipc-cloud-tags/pop-trends${getQueryByParams(
      filters
    )}`,
    { cancelToken }
  );

/**
 * Получение графика количества публикаций по компаниям в периодике годов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getTrendsCompaniesByYear = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/companies-by-year${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение графика количества публикаций по компаниям в периодике годов
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getTrendsCompaniesByYearPopTrends = async (
  filters: any,
  cancelToken: any = null
) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/companies-by-year/pop-trends${getQueryByParams(
      filters
    )}`,
    { cancelToken }
  );

/**
 * Получение график количества публикаций авторов по годам
 * @param {*} filters - фильтры поиска
 * @param {*} cancelToken - токен для отмены запроса
 * @description Используется для выполнения HTTP-запроса к API
 */
const getChartAuthorsByYear = async (filters: any, cancelToken: any = null) =>
  await API_NO_CACHE.get(
    `${getUrlPart()}/charts/authors-by-year${getQueryByParams(filters)}`,
    { cancelToken }
  );

/**
 * Получение количества публикаций определенной компании
 * @param {Object} filters - фильтры поиска
 * @description Используется для выполнения HTTP-запроса к API
 */
const getPublicationsAmount = (filters: any) =>
  API_NO_CACHE.get(`${getUrlPart()}${getQueryByParams(filters)}`);

const searchCompanies = async ({ c1, c2 }: IComparisonOfCompanies) => {
  const arr = await Promise.allSettled([
    getPublicationsAmount({ Assignees: c1, Current: 1, PageSize: 1 }),
    getPublicationsAmount({ Assignees: c2, Current: 1, PageSize: 1 }),
  ]);
  const publicationsAmount1 =
    arr[0].status === 'rejected' ? 0 : arr[0].value.data.total;
  const publicationsAmount2 =
    arr[1].status === 'rejected' ? 0 : arr[1].value.data.total;

  return {
    c1: { publicationsAmount: publicationsAmount1 },
    c2: { publicationsAmount: publicationsAmount2 },
  };
};

/**
 * Загрузка публикаций
 */
const upload = (formData: FormData) =>
  API.post<any>(getUrlPart(), formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export default {
  getAllPublicationsAssignees,
  getAllPublicationsAuthors,
  getById,
  getChartAuthorsByYear,
  getChartByCountryYears,
  getChartBySections,
  getChartByAssignees,
  GetChartPublicationsByAuthors,
  getChartByClasses,
  GetChartPublicationsByClassesPopTrends,
  getChartByCountries,
  GetChartPublicationsByDt,
  GetChartPublicationsByDtPopTrends,
  getCloudTags,
  getFiltered,
  getFilteredPublicationsPopTrends,
  getIpcClasses,
  getIpcCloudTags,
  getIpcCloudTagsPopTrends,
  getTrendsCompaniesByYear,
  getTrendsCompaniesByYearPopTrends,
  searchCompanies,
  upload,
};
