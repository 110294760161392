import { Form, Radio, RadioChangeEvent } from 'antd';
import { useState } from 'react';

import searchActions from 'store/actions/searchActions';
import { useAppDispatch } from 'store/selectors/useAppSelector';

const ResourceSearchSelection = () => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const onChange = (e: RadioChangeEvent) => {
    setValue(+e.target.value);
    dispatch(searchActions.resourceForSearchPanel.set(e.target.value));
  };

  return (
    <Form.Item name='resource'>
      <Radio.Group
        className='search-form-field__radio-group'
        onChange={onChange}
        optionType='button'
        value={value}
      >
        <Radio style={{ width: '135px' }} value={0}>
          Патенты
        </Radio>
        <Radio style={{ width: '135px' }} value={1}>
          Публикации
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default ResourceSearchSelection;
