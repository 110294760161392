import { combineReducers } from '@reduxjs/toolkit';

import { authorsRTKQApi } from 'services/authorsRTKQApi';
import { chemicalCompositionApi } from 'services/chemicalApi';
import byUserReducer from 'store/byUserStore';
import analyticsReducer from 'store/reducers/analyticsReducer';
import appReducer from 'store/reducers/appReducer';
import chemicalReducer from 'store/reducers/chemicalSlice';
import ipcReducer from 'store/reducers/ipcReducer';
import popTrendsReducer from 'store/reducers/popTrendsReducer';
import priorityDirectionsReducer from 'store/reducers/priorityDirectionsReducer';
import searchReducer from 'store/reducers/searchReducer';
import trendsAnalysisReducer from 'store/reducers/trendsAnalysisReducer';

const reducers = combineReducers({
  analytics: analyticsReducer,
  app: appReducer,
  byUser: byUserReducer,
  [authorsRTKQApi.reducerPath]: authorsRTKQApi.reducer,
  [chemicalCompositionApi.reducerPath]: chemicalCompositionApi.reducer,
  ipc: ipcReducer,
  popTrends: popTrendsReducer,
  priorityDirections: priorityDirectionsReducer,
  search: searchReducer,
  trendsAnalysis: trendsAnalysisReducer,
  chemical: chemicalReducer,
});

export default reducers;
