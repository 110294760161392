/**
 * Типы уведомлений
 * @readonly
 */
export const NotificationTypes = Object.freeze({
  error: {
    duration: 4.5,
    message: 'Ошибка',
    type: 'error',
  },
  info: {
    duration: 4.5,
    message: 'Информация',
    type: 'info',
  },
  success: {
    duration: 4.5,
    message: 'Выполнено',
    type: 'success',
  },
  warning: {
    duration: 4.5,
    message: 'Предупреждение',
    type: 'warning',
  },
});
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME || 'SciMark Pro';
export const ADMIN_EMAIL =
  process.env.REACT_APP_ADMIN_EMAIL || 'va.vedeneev@severstal.com';

export const PATENTS_RESOURCE = 0;
export const PUBLICATIONS_RESOURCE = 1;

export const TITLE_SEARCH = 0;
export const CONTENT_SEARCH = 1;
export const WHOLE_SEARCH = 2;
