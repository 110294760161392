import { RefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  {
    freezeOnceVisible = false,
    root = null,
    rootMargin = '0%',
    threshold = 0,
  }: Args
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const frozen = entry?.isIntersecting && freezeOnceVisible;
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;
    if (!hasIOSupport || frozen || !node) return;
    const observerParams = { root, rootMargin, threshold };
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);
    return () => observer.disconnect();
  }, [
    elementRef?.current,
    JSON.stringify(threshold),
    root,
    rootMargin,
    frozen,
  ]);

  return entry;
};

export default useIntersectionObserver;

// Использование

// const Section = (props: { title: string }) => {
//   const ref = useRef<HTMLDivElement | null>(null);
//   const entry = useIntersectionObserver(ref, {});
//   const isVisible = !!entry?.isIntersecting;
//
//   console.log(`Render Section ${props.title}`, { isVisible });
//
//   return (
//     <div
//       ref={ref}
//       style={{
//         minHeight: '100vh',
//         display: 'flex',
//         border: '1px dashed #000',
//         fontSize: '2rem',
//       }}
//     >
//       <div style={{ margin: 'auto' }}>{props.title}</div>
//     </div>
//   );
// };
//
// export default function Component() {
//   return (
//     <>
//       {Array.from({ length: 5 }).map((_, index) => (
//         <Section key={index + 1} title={`${index + 1}`} />
//       ))}
//     </>
//   );
// }
