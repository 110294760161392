import { Heatmap } from '@ant-design/plots';
import { Col, Row, theme } from 'antd';
import { FC } from 'react';

import BaseChartWrapper from 'components/charts/baseChartWrapper';
import { ellipsisText } from 'utils/helpers/commonHelper';
import {
  IExpertCountryClass,
  IExpertCountryGroup,
  IExpertCountrySection,
  IExpertCountrySubgroup,
} from 'utils/interfaces/expert.interface';

interface IProps {
  dataSource: Record<
    string,
    {
      data:
        | IExpertCountryClass[]
        | IExpertCountryGroup[]
        | IExpertCountrySection[]
        | IExpertCountrySubgroup[];
      title: string;
    }
  >;
  loading: boolean;
}

const ExpertsAnalytics: FC<IProps> = ({ dataSource, loading }) => {
  const { token } = theme.useToken();

  const heatmapConfig = {
    color: ['#dddddd', '#9ec8e0', '#5fa4cd', '#2e7ab6', '#114d90'],
    colorField: 'value',
    data: [],
    label: {
      style: {
        fill: token.colorText,
        shadowBlur: 2,
        shadowColor: 'rgba(0, 0, 0, .45)',
      },
    },
    legend: { animate: true },
    shape: 'square',
    sizeField: 'value',
    xAxis: {
      label: {
        formatter: (text: string) => ellipsisText(text, 10),
      },
    },
    xField: 'name',
    yAxis: {
      label: {
        autoEllipsis: true,
      },
    },
    yField: 'country',
  };

  return (
    <Row gutter={[0, 24]}>
      {Object.keys(dataSource).map((dataItem) => (
        <Col key={dataSource[dataItem].title} span={24}>
          <BaseChartWrapper
            dataLength={dataSource[dataItem].data.length}
            loading={loading}
            onViewChange={null}
            title={dataSource[dataItem].title}
            expandable
          >
            <Heatmap {...heatmapConfig} data={dataSource[dataItem].data} />
          </BaseChartWrapper>
        </Col>
      ))}
    </Row>
  );
};

export default ExpertsAnalytics;
