import { HIGHLIGHT_OPERATORS } from 'utils/constants/keywordsOperators';

export const getWildcardWordsRegexUnion = (words: string[]) => {
  const filteredWords = words
    .filter((word) => word.endsWith('*'))
    .map((word) => word.slice(0, -1));

  if (filteredWords.length > 0) {
    return String.raw`((${filteredWords.join('|')})[-А-яЁёA-z0-9]*)`;
  } else {
    return '';
  }
};

export const getSimpleWordsRegexUnion = (words: string[]) => {
  const filteredWords = words.filter(
    (word) => !word.match(HIGHLIGHT_OPERATORS) && !word.match(/\w+\*/)
  );

  if (filteredWords.length > 0) {
    return String.raw`(${filteredWords.join('|')})`;
  } else {
    return '';
  }
};

export const getRegexForHighlightKeywords = (words: string[]) => {
  const getResultWordsUnionRegex = (words: string[]) => {
    if (
      getSimpleWordsRegexUnion(words).length > 0 &&
      getWildcardWordsRegexUnion(words).length === 0
    ) {
      return String.raw`${getSimpleWordsRegexUnion(words)}`;
    } else if (
      getSimpleWordsRegexUnion(words).length > 0 &&
      getWildcardWordsRegexUnion(words).length > 0
    ) {
      return String.raw`${getSimpleWordsRegexUnion(
        words
      )}|${getWildcardWordsRegexUnion(words)}`;
    } else if (
      getSimpleWordsRegexUnion(words).length === 0 &&
      getWildcardWordsRegexUnion(words).length > 0
    ) {
      return String.raw`${getWildcardWordsRegexUnion(words)}`;
    } else return String.raw``;
  };

  return new RegExp(
    String.raw`(?!AND|OR|NOT|[1-9]W|\*)(${getResultWordsUnionRegex(words)})`,
    'g'
  );
};
