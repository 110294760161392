import {
  AppstoreOutlined,
  ControlOutlined,
  ExperimentOutlined,
  FundOutlined,
  GlobalOutlined,
  SearchOutlined,
  SplitCellsOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Menu, theme } from 'antd';
import { ReactNode, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import styles from 'components/layouts/layoutWithTopMenu/index.module.scss';
import { SimpleLink } from 'components/links';
import security from 'services/security';
import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { useCurrentPageInfo, useDarkTheme } from 'utils/hooks';

const searchAndAnalysisLinks = [
  { ...PAGES_INFO.searchPage, icon: <SearchOutlined /> },
  { ...PAGES_INFO.analyticsPage, icon: <AppstoreOutlined /> },
  { ...PAGES_INFO.landscapesPage, icon: <GlobalOutlined /> },
  { ...PAGES_INFO.trendsAnalysisPage, icon: <FundOutlined /> },
];

const expertiseLinks = [
  { ...PAGES_INFO.expertsPage, icon: <TeamOutlined /> },
  { ...PAGES_INFO.comparisonOfCompaniesPage, icon: <SplitCellsOutlined /> },
];

const simpleLinks = [
  { ...PAGES_INFO.chemicalCompositionPage, icon: <ExperimentOutlined /> },
];

let hasAdminLink = false;

const TopMenu = () => {
  const location = useLocation();
  const { isDarkTheme } = useDarkTheme();
  const pageInfo = useCurrentPageInfo();

  const getMenuItems = useCallback(
    (
      links: {
        icon: ReactNode;
        path: string;
        title: string;
        view: boolean;
      }[]
    ) => {
      return links.map((link) => ({
        icon: link.icon,
        key: link.path,
        label: <SimpleLink {...link} search={location.search} />,
      }));
    },
    [location.search]
  );

  const menuItems = useMemo(() => {
    const searchAndAnalysisMenuItem = {
      children: getMenuItems(searchAndAnalysisLinks),
      key: 'Search and Analysis',
      label: 'Поиск и аналитика',
    };

    const expertiseMenuItem = {
      children: getMenuItems(expertiseLinks),
      key: 'Expertise',
      label: 'Экспертиза',
    };

    if (!hasAdminLink && security.isAdmin()) {
      hasAdminLink = true;
      simpleLinks.push({
        ...PAGES_INFO.adminPage,
        icon: <ControlOutlined />,
      });
    }

    return [
      searchAndAnalysisMenuItem,
      expertiseMenuItem,
      ...getMenuItems(simpleLinks),
    ];
  }, [location.search]);

  return (
    <Menu
      defaultSelectedKeys={[pageInfo.path]}
      items={menuItems}
      mode='horizontal'
      className={`${styles.topMenu} ${
        isDarkTheme ? styles.lightTextTopMenu : styles.darkTextTopMenu
      }`}
    />
  );
};

export default TopMenu;
