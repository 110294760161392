import { FC, ReactNode } from 'react';

interface ILink {
  children?: ReactNode;
  onClick?: () => undefined | void;
  path: string;
  target: string;
  title?: string;
}

const Link: FC<ILink> = ({ children, onClick, path, target, title }) => {
  const onClickHandler = () => onClick && onClick();

  return children ? (
    <a
      href={path}
      onClick={onClickHandler}
      rel='noopener noreferrer'
      target={target}
      title={title}
    >
      {children}
    </a>
  ) : (
    <a
      href={path}
      onClick={onClickHandler}
      rel='noopener noreferrer'
      target={target}
      title={title}
    />
  );
};

export default Link;
