import { useEffect } from 'react';

import useCurrentPageInfo from 'utils/hooks/useCurrentPageInfo';

/**
 * Обновление заголовка страницы
 * @param {String} prefix Префикс заголовка
 * @param {String} postfix Постфик заголовка
 * @returns Информация о странице
 * @description Hook
 */
const useUpdatePageTitle = (prefix: string, postfix: string) => {
  const pageInfo = useCurrentPageInfo();

  useEffect(() => {
    document.title = `${prefix}${pageInfo?.props?.description ?? ''} ${
      pageInfo.title
    }${postfix}`;
  }, [pageInfo.title]);

  return pageInfo;
};

export default useUpdatePageTitle;
