import { notification } from 'antd';
import { useEffect } from 'react';

import appActions from 'store/actions/appActions';
import { useAppDispatch, useAppSelector } from 'store/selectors/useAppSelector';
import { objectIsNotEmpty } from 'utils/helpers/commonHelper';

/**
 * Выводит появляющиеся уведомления
 * @description Hook
 */
const useNotification = () => {
  const dispatch = useAppDispatch();
  const n = useAppSelector((s) => s.app.notification);

  useEffect(() => {
    if (objectIsNotEmpty(n)) {
      // destroy удаляет дублирующиеся соообщения при множественных запросах.
      // Нужно перейти на useNotification, так как статичные методы не получают
      // динамическую светлую/темную тему. Такая же ситуация с message.info
      notification.destroy(n.message);
      notification.open({ ...n, key: n.message });
      dispatch(appActions.setNotification());
    }
    // eslint-disable-next-line
  }, [n]);
};

export default useNotification;
