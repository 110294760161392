/**
 * Информация о страницах
 */
export const PAGES_INFO = {
  adminPage: {
    path: '/admin',
    title: 'Панель Администратора',
    view: true,
  },
  advancedSearchPage: {
    path: '/advanced-search',
    title: 'Расширенный поиск',
    view: false,
  },
  analyticsPage: {
    path: '/analytics',
    title: 'Аналитика',
    view: true,
  },
  chemicalCompositionPage: {
    path: '/chemical-composition',
    title: 'Химический состав',
    view: true,
  },
  communitiesNewsPage: {
    path: '/communities/news',
    title: 'Новости сообществ',
    view: false,
  },
  communitiesPage: {
    path: '/communities',
    title: 'Сообщества',
    view: false,
  },
  communityPage: {
    path: '/communities/:id',
    title: 'Сообщество',
    view: false,
  },
  comparisonOfCompaniesPage: {
    path: '/comparison-of-companies',
    title: 'Сравнение компаний',
    view: true,
  },
  expertPage: {
    path: '/experts/:name',
    title: 'Эксперт',
    view: true,
  },
  expertsPage: {
    path: '/experts',
    title: 'Эксперты',
    view: true,
  },
  forbiddenPage: {
    path: `/forbidden`,
    props: {
      codeStatus: 403,
      message: 'У вас нет прав доступа к этой странице',
    },
    title: `Нет доступа`,
    view: false,
  },
  internalClientErrPage: {
    path: `/error`,
    props: {
      codeStatus: 500,
      message: 'Ошибка в работе приложения',
    },
    title: 'Ошибка в работе приложения',
    view: false,
  },
  internalServerErrPage: {
    path: `/internal-server-error`,
    props: {
      codeStatus: 500,
      message: 'На стороне сервера что-то пошло не так',
    },
    title: `Ошибка сервера`,
    view: false,
  },
  landscapesPage: {
    path: '/landscapes',
    title: 'Ландшафты',
    view: true,
  },
  mainPage: {
    path: '/home',
    title: 'Главная страница',
    view: false,
  },
  managementPage: {
    path: '/management',
    title: 'Управление',
    view: false,
  },
  notFoundPage: {
    path: `/not-found`,
    props: {
      codeStatus: 404,
      message: 'Страница удалена или не существует',
    },
    title: `Страница не найдена`,
    view: false,
  },
  populationTrendsPage: {
    path: '/pop-trends',
    title: 'Тренды популярности',
    view: true,
  },
  searchPage: {
    path: '/search',
    title: 'Поиск',
    view: true,
  },
  selectedPatentsPage: {
    path: '/selected-patents',
    title: 'Избранные патенты',
    view: true,
  },
  trendsAnalysisPage: {
    path: '/trend-analysis',
    title: 'Анализ трендов',
    view: true,
  },
};
