import { Col, Layout, Row, Space } from 'antd';
import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';

import { BreadcrumbTitle } from 'components/breadcrumbs';
import FavoriteExpertsButton from 'components/expertsPageComponents/favoriteExpertsButton';
import { HelpButton } from 'components/floatButtons/helpFloatButton';
import styles from 'components/layouts/basicLayout/styles.module.scss';
import { LeftMenu } from 'components/menu';
import { SearchNotificationsButton } from 'components/searchPanel/searchNotifications';
import { COMPANY_NAME } from 'utils/constants/appConfig';
import { PAGES_INFO } from 'utils/constants/pagesConfig';
import { useNotification, useUpdatePageTitle } from 'utils/hooks';

const { Content, Header } = Layout;

interface IBasicLayout {
  children?: ReactNode;
  className?: string;
  contentCenter?: boolean;
  hasDefaultBreadcrumb?: boolean;
  hasMenu?: boolean;
  isExpertsPage?: boolean;
  routesBreadcrumb?: string[];
}

const BasicLayout: FC<IBasicLayout> = ({
  children,
  className,
  contentCenter,
  hasDefaultBreadcrumb = true,
  hasMenu = true,
  isExpertsPage = false,
  routesBreadcrumb = [],
}) => {
  useNotification();
  const pageInfo = useUpdatePageTitle('', ' - ' + COMPANY_NAME);
  const location = useLocation();
  const mainPages = [
    PAGES_INFO.mainPage.path,
    PAGES_INFO.advancedSearchPage.path,
    '/old',
    PAGES_INFO.communitiesPage.path,
    PAGES_INFO.communityPage.path,
  ];
  const isMainPage = mainPages.some((page) =>
    matchPath(page, location.pathname)
  );

  const BreadcrumbsBlock = useMemo(() => {
    if (!routesBreadcrumb?.length && !hasDefaultBreadcrumb) return null;

    const breadcrumbData = !pageInfo.view
      ? routesBreadcrumb
      : [pageInfo.title].concat(routesBreadcrumb);

    return (
      <Header className={isMainPage ? styles.mainHeader : styles.header}>
        <BreadcrumbTitle data={breadcrumbData} />
      </Header>
    );
  }, [pageInfo, routesBreadcrumb, hasDefaultBreadcrumb]);

  return (
    <Layout className={`${className} ${styles.mainLayout}`}>
      {hasMenu && <LeftMenu />}
      <Row
        className={styles.contentWrapper}
        justify={contentCenter ? 'center' : 'start'}
      >
        <Col className={isMainPage ? styles.mainmenu : styles.content}>
          <Layout style={{ margin: 'auto' }}>
            <Row>
              <Col span={20}>{BreadcrumbsBlock}</Col>
              <Col span={4}>
                <Row justify='end'>
                  <Space>
                    {isExpertsPage && <FavoriteExpertsButton />}
                    {!isMainPage && <SearchNotificationsButton />}
                  </Space>
                </Row>
              </Col>
            </Row>
            <Content>{children}</Content>
          </Layout>
        </Col>
      </Row>

      {!isMainPage && <HelpButton />}
    </Layout>
  );
};

export default BasicLayout;
