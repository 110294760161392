import { Form, Select, Tag } from 'antd';
import { FC } from 'react';

import 'components/searchPanel/searchForm/inputs/index.css';

const IndexesSelect: FC<{ maxCount: number }> = ({ maxCount }) => (
  <Form.Item
    name='ipc'
    className='search-form-field'
    rules={[
      {
        max: maxCount,
        type: 'array',
      },
    ]}
  >
    <Select
      maxTagCount={1}
      mode='tags'
      placeholder='Индексы МПК'
      tagRender={tagRender}
      allowClear
    />
  </Form.Item>
);

const tagRender = ({ closable, label, onClose }: any) => {
  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      closable={closable}
      color='#63a3a9'
      onClose={onClose}
      onMouseDown={onPreventMouseDown}
      style={{ marginRight: 10 }}
    >
      {label}
    </Tag>
  );
};

export default IndexesSelect;
