import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

import appActions from 'store/actions/appActions';
import { useAppDispatch } from 'store/selectors/useAppSelector';
import { PAGES_INFO } from 'utils/constants/pagesConfig';

/**
 * Логирование ошибок
 * @description Hook
 * @param {Boolean} hasRedirect Наличие редиректа на страницу с ошибкой
 */
const useLogError = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logError = (e: any, title = null, hasRedirect = true) => {
    if (e.__CANCEL__) return;

    if (hasRedirect) {
      networkErrorRedirect(e);
    }
    dispatch(appActions.errorNotificationByApiExc(e, title));
    console.error(e);
  };

  const networkErrorRedirect = (e: AxiosError) => {
    switch (e?.response?.status) {
      case 403:
        navigate(PAGES_INFO.forbiddenPage.path);
        break;
      default:
        break;
    }
  };

  return logError;
};

export default useLogError;
