import { POP_TRENDS } from 'utils/constants/actionTypes';

const initialState = {
  compByYear: [],
  compByYearLoading: false,
  ipcCloudTags: [],
  ipcCloudTagsLoading: false,
};

const popTrandsReducer = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case POP_TRENDS.COMP_BY_YEAR_SET_DATA:
      return { ...state, compByYear: payload };
    case POP_TRENDS.COMP_BY_YEAR_LOADING:
      return { ...state, compByYearLoading: payload };
    case POP_TRENDS.IPC_CLOUD_TAGS_SET_DATA:
      return { ...state, ipcCloudTags: payload };
    case POP_TRENDS.IPC_CLOUD_TAGS_LOADING:
      return { ...state, ipcCloudTagsLoading: payload };
    default:
      return state;
  }
};

export default popTrandsReducer;
