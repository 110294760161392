import { Empty, Space } from 'antd';
import { FC } from 'react';

import CompanyActivity from 'components/expertsPageComponents/comparison/companyActivity';
import CompetenciesChart from 'components/expertsPageComponents/comparison/competenciesChart';
import CompetenciesInfo from 'components/expertsPageComponents/comparison/competenciesInfo';
import { IComparisonOfCompaniesCompanyData } from 'utils/interfaces/comparisonOfCompanies.interface';

interface ICompanyComparisonInfo {
  company: IComparisonOfCompaniesCompanyData;
  otherCompany: IComparisonOfCompaniesCompanyData;
}

const CompanyComparisonInfo: FC<ICompanyComparisonInfo> = ({
  company,
  otherCompany,
}) => {
  if (!company.competences.total) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Space direction='vertical' size='large'>
      <CompanyActivity
        competence={company.competences.results[0].competence}
        patentsAmount={company.patentsAmount}
        publicationsAmount={company.publicationsAmount}
      />
      <CompetenciesInfo company={company} otherCompany={otherCompany} />
      <CompetenciesChart data={company.competences} />
    </Space>
  );
};

export default CompanyComparisonInfo;
