import { useState } from 'react';

import useEventListener from 'utils/hooks/useEventListener';
import useIsomorphicLayoutEffect from 'utils/hooks/useIsomorphicLayoutEffect';

interface WindowSize {
  height: number;
  width: number;
}

/**
 * Получение текущих размеров пользовательского окна
 * @returns Объект с размерами экрана { width: ?, height: ? }
 * @description Hook
 */
const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    height: 0,
    width: 0,
  });

  const handleResize = () =>
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });

  useIsomorphicLayoutEffect(() => {
    handleResize();
  }, []);

  useEventListener('resize', handleResize);

  return windowSize;
};

export default useWindowSize;
