import { Typography } from 'antd';

const ChartTitle = ({ className, name, style }) => {
  return (
    <Typography.Text
      className={`non-selectable ${className}`}
      style={style}
      type='secondary'
    >
      {name}
    </Typography.Text>
  );
};

export default ChartTitle;
