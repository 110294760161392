import { AutoComplete, Form } from 'antd';
import { Rule } from 'antd/es/form';
import { FC } from 'react';

import 'components/searchPanel/searchForm/inputs/index.css';

interface INonCaseSensitiveAutocomplete {
  allowClear?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  name: string;
  options?: { value: string }[];
  placeholder?: string;
  rules?: Rule[];
}

const NonCaseSensitiveAutocomplete: FC<INonCaseSensitiveAutocomplete> = ({
  allowClear = false,
  autoFocus = false,
  loading = false,
  name,
  options = [],
  placeholder = '',
  rules = [],
}) => {
  return (
    <Form.Item name={name} rules={rules}>
      <AutoComplete
        allowClear={allowClear}
        autoFocus={autoFocus}
        disabled={loading}
        options={options}
        placeholder={placeholder}
        filterOption={(inputValue, option) =>
          option!.value
            ?.toString()
            .toUpperCase()
            .indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  );
};

export default NonCaseSensitiveAutocomplete;
