import { Badge, Popconfirm } from 'antd';

const ExpertPopconfirm = () => {
  return (
    <Popconfirm
      cancelText='Нет'
      title='Вы уверены, что хотите перейти на сайт Экосистемы для заполнения формы?'
      okText={
        <a
          href='https://intranet.severstal.com/bs/ecosystem/Lists/RequestsEecosystem/NewForm.aspx'
          rel='noopener noreferrer'
          target='_blank'
        >
          Да, я уверен(а)
        </a>
      }
    >
      <Badge
        status='processing'
        style={{ marginLeft: '13px', marginTop: '5px' }}
        text={<a>Связаться с экспертом через Экосистему</a>}
      />
    </Popconfirm>
  );
};

export default ExpertPopconfirm;
