import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { FC, useEffect, useRef } from 'react';

import {
  useAddFavoriteExpertMutation,
  useDeleteFavoriteExpertMutation,
  useGetFavoriteExpertsQuery,
} from 'services/authorsRTKQApi';
import { useBoolean, useLogError } from 'utils/hooks';
import { IExpert, IExpertFavorite } from 'utils/interfaces/expert.interface';

interface IProps {
  expert: IExpert;
}

const AddExpertToFavoriteButton: FC<IProps> = ({ expert }) => {
  const logError = useLogError();
  const [isFavorite, updateIsFavorite] = useBoolean(false);
  const expertName = expert.name;
  const {
    data: favoriteExperts = [],
    isFetching,
    isLoading: isDataLoading,
  } = useGetFavoriteExpertsQuery();
  const [addExpert, { isLoading: isAddLoading }] =
    useAddFavoriteExpertMutation();
  const [deleteExpert, { isLoading: isDeleteLoading }] =
    useDeleteFavoriteExpertMutation();
  const isLoading =
    isDataLoading || isFetching || isAddLoading || isDeleteLoading;
  const favoriteExpert = useRef<IExpertFavorite>();

  useEffect(() => {
    favoriteExpert.current = favoriteExperts.find(
      (fe) => fe.name === expertName
    );

    updateIsFavorite[favoriteExpert.current ? 'on' : 'off']();
  }, [favoriteExperts]);

  const addToFavorites = async () => {
    try {
      await addExpert({ name: expertName }).unwrap();
    } catch (e) {
      logError(e);
    }
  };

  const deleteFromFavorites = async () => {
    if (favoriteExpert.current?.id) {
      try {
        await deleteExpert(favoriteExpert.current.id);
      } catch (e) {
        logError(e);
      }
    }
  };

  return (
    <Space>
      <Button
        icon={isFavorite ? <StarFilled /> : <StarOutlined />}
        loading={isLoading}
        type={isFavorite ? 'primary' : 'text'}
        onClick={() => {
          isFavorite ? deleteFromFavorites() : addToFavorites();
        }}
      />
    </Space>
  );
};

export default AddExpertToFavoriteButton;
