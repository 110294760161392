import {
  ArrowsAltOutlined,
  BarChartOutlined,
  ShrinkOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Button, Col, Empty, Radio, Row, Space, Tooltip } from 'antd';
import { memo, useState } from 'react';

import ChartTitle from 'components/charts/chartTitle';
import Loading from 'components/loading';
import { useBoolean, useDarkTheme } from 'utils/hooks';

const BaseChartWrapper = ({
  children,
  dataLength,
  expandable = false,
  loading = false,
  onViewChange,
  title,
}) => (
  <ChartContainer
    expandable={expandable}
    onViewChange={onViewChange}
    title={title}
  >
    {loading ? <LoadBox /> : dataLength ? children : <EmptyBox />}
  </ChartContainer>
);

export default BaseChartWrapper;

const EmptyBox = () => (
  <Row
    align='middle'
    justify='center'
    style={{ height: '100%', minHeight: '350px' }}
  >
    <Col>
      <Empty className='non-selectable' image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Col>
  </Row>
);

const LoadBox = () => (
  <div className='content-item-container'>
    <Loading height='318px' />
  </div>
);

const ChartContainer = ({ children, expandable, onViewChange, title }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { isDarkTheme } = useDarkTheme();

  return (
    <div
      className={`
      content-item-container 
      ${isFullScreen ? 'full-screen-mode' : ''} 
      ${isDarkTheme ? 'dark-charts' : 'light-charts'}
      `}
    >
      <ContentHeader
        onFullScreen={expandable && setIsFullScreen}
        onViewChange={onViewChange}
        title={title}
      />
      {children}
    </div>
  );
};

const ContentHeader = memo(({ onFullScreen, onViewChange, title }) => {
  const [fullScreen, updateFullScreen] = useBoolean(false);
  const { isDarkTheme } = useDarkTheme();

  const onFullScreenChange = () => {
    updateFullScreen.toggle();
    onFullScreen(!fullScreen);
  };

  return (
    <Row justify='space-between' style={{ height: '32px' }}>
      <Col>
        <ChartTitle name={title} style={{ color: isDarkTheme ? '' : '#000' }} />
      </Col>
      <Col>
        <Space align='start' direction='horizontal' size={16}>
          {onViewChange && (
            <Radio.Group
              defaultValue={0}
              onChange={(e) => onViewChange(e.target.value)}
              optionType='button'
              options={OPTIONS}
            />
          )}
          {onFullScreen && (
            <Tooltip
              placement='bottomRight'
              title={fullScreen ? 'Уменьшить' : 'Увеличить'}
            >
              <Button onClick={onFullScreenChange}>
                {fullScreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
              </Button>
            </Tooltip>
          )}
        </Space>
      </Col>
    </Row>
  );
});

const OPTIONS = [
  {
    label: (
      <Tooltip placement='bottomRight' title='График'>
        <BarChartOutlined />
      </Tooltip>
    ),
    value: 0,
  },
  {
    label: (
      <Tooltip placement='bottomRight' title='Таблица'>
        <TableOutlined />
      </Tooltip>
    ),
    value: 1,
  },
];
