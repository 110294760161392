import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useEventCallback, useEventListener } from 'utils/hooks';

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * Получение измененного значения в LocalStorage, а также запись нового
 * @param key Ключ значения в LocalStorage
 * @param initialValue Значение результата, если по ключу ничего не было найдено
 * @description Hook
 */
const useLocalStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  const storageEventKey = 'storage';
  const localStorageEventKey = 'local-storage';

  const parseJSON = <T>(value: null | string): T | undefined => {
    try {
      return value === 'undefined' ? undefined : JSON.parse(value ?? '');
    } catch (error) {
      console.warn('Возникла ошибка при парсинге JSON', { value }, error);
      return undefined;
    }
  };

  const readValue = useCallback(() => {
    if (typeof window === 'undefined') return initialValue;

    try {
      const item = window.localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(
        `Возникла ошибка при чтении значения по ключу "${key}":`,
        error
      );
    }
    return initialValue;
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = useEventCallback((value) => {
    // Предотвращает ошибку "window is undefined"
    if (typeof window == 'undefined')
      console.warn(
        `Не удается установить значение в LocalStorage по ключу "${key}", т.к. среда не является клиентской`
      );

    try {
      const newValue = value instanceof Function ? value(storedValue) : value;

      window.localStorage.setItem(key, JSON.stringify(newValue));

      setStoredValue(newValue);

      window.dispatchEvent(new Event(localStorageEventKey));
    } catch (error) {
      console.warn(`Возникла ошибка при установке ключа "${key}":`, error);
    }
  });

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line
  }, []);

  const handleStorageChange = useCallback(
    (event: CustomEvent | StorageEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue]
  );

  useEventListener(storageEventKey, handleStorageChange);
  useEventListener(localStorageEventKey, handleStorageChange);

  return [storedValue, setValue];
};

export default useLocalStorage;

// Использование

// export const Component = () => {
// 	const [isDarkTheme, setDarkTheme] = useLocalStorage('darkTheme', true)
//
// 	const toggleTheme = () => {
// 		setDarkTheme((prevValue: boolean) => !prevValue)
// 	}
//
// 	return (
// 		<button onClick={toggleTheme}>
// 			{`The current theme is ${isDarkTheme ? `dark` : `light`}`}
// 		</button>
// 	)
// }
