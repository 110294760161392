import { AnyAction, Reducer } from '@reduxjs/toolkit';

import { ANALYTICS } from 'utils/constants/actionTypes';
import { IPaginationResponse } from 'utils/interfaces/pagination.interface';
import { IPatent } from 'utils/interfaces/patent.interface';
import { IPublication } from 'utils/interfaces/publication.interface';

const initialState = {
  authors: [],
  authorsByYear: [],
  authorsByYearLoading: false,
  authorsLoading: false,
  companies: [],
  companiesByYear: [],
  companiesByYearLoading: false,
  companiesLoading: false,
  countries: {},
  countriesLoading: false,
  dates: [],
  datesLoading: false,
  directions: {},
  directionsLoading: false,
  patents: { results: [] },
  patentsLoading: false,
  publications: { results: [] },
  publicationsLoading: false,
};

interface IInitialState {
  authors: any[];
  authorsByYear: any[];
  authorsByYearLoading: boolean;
  authorsLoading: boolean;
  companies: any[];
  companiesByYear: any[];
  companiesByYearLoading: boolean;
  companiesLoading: boolean;
  countries: any;
  countriesLoading: boolean;
  dates: { cnt: number; value: string }[];
  datesLoading: boolean;
  directions: Record<string, { cnt: number; value: string }[]>;
  directionsLoading: boolean;
  patents: IPaginationResponse<IPatent>;
  patentsLoading: boolean;
  publications: IPaginationResponse<IPublication>;
  publicationsLoading: boolean;
}

const analyticsReducer: Reducer<IInitialState> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case ANALYTICS.PATENTS_SET_DATA:
      return { ...state, patents: action.payload };
    case ANALYTICS.PATENTS_LOADING:
      return { ...state, patentsLoading: action.payload };
    case ANALYTICS.PUBLICATIONS_SET_DATA:
      return { ...state, publications: action.payload };
    case ANALYTICS.PUBLICATIONS_LOADING:
      return { ...state, publicationsLoading: action.payload };
    case ANALYTICS.DATES_SET_DATA:
      return { ...state, dates: action.payload };
    case ANALYTICS.DATES_LOADING:
      return { ...state, datesLoading: action.payload };
    case ANALYTICS.COMPANIES_SET_DATA:
      return { ...state, companies: action.payload };
    case ANALYTICS.COMPANIES_LOADING:
      return { ...state, companiesLoading: action.payload };
    case ANALYTICS.AUTHORS_SET_DATA:
      return { ...state, authors: action.payload };
    case ANALYTICS.AUTHORS_LOADING:
      return { ...state, authorsLoading: action.payload };
    case ANALYTICS.DIRECTIONS_SET_DATA:
      return { ...state, directions: action.payload };
    case ANALYTICS.DIRECTIONS_LOADING:
      return { ...state, directionsLoading: action.payload };
    case ANALYTICS.COUNTRIES_SET_DATA:
      return { ...state, countries: action.payload };
    case ANALYTICS.COUNTRIES_LOADING:
      return { ...state, countriesLoading: action.payload };
    case ANALYTICS.COMPANIES_BY_YEAR_SET_DATA:
      return { ...state, companiesByYear: action.payload };
    case ANALYTICS.COMPANIES_BY_YEAR_LOADING:
      return { ...state, companiesByYearLoading: action.payload };
    case ANALYTICS.AUTHORS_BY_YEAR_SET_DATA:
      return { ...state, authorsByYear: action.payload };
    case ANALYTICS.AUTHORS_BY_YEAR_LOADING:
      return { ...state, authorsByYearLoading: action.payload };
    default:
      return state;
  }
};

export default analyticsReducer;
