import { Line, LineConfig } from '@ant-design/plots';
import { Card } from 'antd';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

import { IComparisonOfCompaniesCompetence } from 'utils/interfaces/comparisonOfCompanies.interface';
import { IPaginationResponse } from 'utils/interfaces/pagination.interface';

interface ICompetenciesChart {
  data: IPaginationResponse<IComparisonOfCompaniesCompetence>;
}

const CompetenciesChart: FC<ICompetenciesChart> = ({ data }) => {
  const chartData = useMemo(() => {
    return data.results
      .slice()
      .sort((a, b) => +new Date(a.date) - +new Date(b.date))
      .map((v) => ({ ...v, date: dayjs(v.date).format('DD.MM.YYYY') }));
  }, [data]);

  const config: LineConfig = {
    data: chartData,
    padding: 'auto',
    slider: { end: 0.5, start: 0.1, textStyle: { fill: '#7b7b7b' } },
    tooltip: {
      fields: ['competence', 'count'],
      showMarkers: false,
    },
    xAxis: { tickCount: 5 },
    xField: 'date',
    yField: 'count',
  };

  return (
    <Card size='small' title='График компетенций по направлениям'>
      <Line {...config} />
    </Card>
  );
};

export default CompetenciesChart;
